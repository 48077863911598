import React, { Component } from 'react';
import { Col, Row, Button, Label } from 'reactstrap'
import PlayStore from '../../assets/store.svg'
import Twitter from '../../assets/twitter.svg'
import LinkedIn from '../../assets/linkedin.svg'
import France from '../../assets/france_flag.svg'
import England from '../../assets/england_flag.svg'
import "./footer.css"

const Footer = () => {


    return (
        <div className='footer_container'>
            <div className='footer_content'>
                <Col>
                    <div className='col_content'>
                        <span className='title'>Découvrir</span>
                        <span className='body__span'>Qui sommes-nous ?</span>
                        <span className='body__span'>Moteur IA</span>
                        <span className='body__span'>Partenaires</span>
                        <span className='body__span'>Références Client</span>
                        <span className='body__span'>Pricing</span>
                    </div>
                </Col>
                <Col>
                    <div className='col_content'>
                        <span className='title'>Se Connecter</span>
                        <span className='body__span'>Espace Client</span>
                        <span className='body__span'>Espace Testeur</span>
                        <br />
                        <span className='title'>Comprendre</span>
                        <span className='body__span'>Tutoriels</span>
                        <span className='body__span'>Blog & Glossaire</span>
                    </div>
                </Col>
                <Col>
                    <div className='col_content'>
                        <Button className='btn_footer2'>Devenir Client</Button>
                        <Button className='btn_footer'>Devenir Testeur</Button>
                        <span className='contact'>Nous contacter</span>
                    </div>
                </Col>
            </div>
            <hr className="line3" />
            <div className='bottom_line'>
                <Col md='3'>
                    <span className='footer_font'>Copyright © 2021
                        Tout droits réservés</span>
                </Col>
                <Col md='6'>
                    <span className='footer_font'>
                        Mentions Légales - Conditions Générales d’Utilisation - Politique de Confidentialité - Gestion des Cookies
                        Insight Data - 10 rue Jean Macé 75011 Paris - contact@insightdata.fr
                    </span>
                </Col>
                <Col md='3'>
                    <img className='mr-4' src={PlayStore} alt="" />
                    <img className='mr-2' src={Twitter} alt="" />
                    <img className='mr-4' src={LinkedIn} alt="" />
                    <img className='mr-2' src={France} alt="" />
                    <img className='' src={England} alt="" />
                </Col>
            </div>
        </div>
    );
}
export default Footer;