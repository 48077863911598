import React from 'react';
import {
    Button, Label, Col, FormFeedback
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Input} from 'reactstrap';
//import './register.css'

const CommentaireForm = ({
   // tog_standard, 
    confirmText, userText,
    handleSubmit, currentUser, onchange, error, loading
}) => {


    return (
        <React.Fragment >
            <AvForm onValidSubmit={handleSubmit}>
                <Col md='12'>
                    <div className='modal__form'>

         
                            {/* <Label id="label__style__signup">commentaire</Label> */}
                            {/* <AvField name="lastname" value={currentUser.lastname}
                                placeholder="Nom de famille" type="textarea"
                                onChange={onchange}
                                id='lastName'
                                validate={{
                                    maxLength: { value: 50 },
                                    required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                    pattern: { value: "^[a-zA-Z]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                                }}
                            /> */}
                            <Input type="textarea" name="text" id="lastName" style={{height:"5em"}}/>
                            <br />
                            <div className="btn_modal_ctn">
                                <Button type="submit" className="btn__form__modal" disabled={loading}
                                >
                                    {loading && <i className="fa fa-refresh fa-spin mr-2" />}{confirmText}</Button>
                            </div>
                    </div>
                </Col>
            </AvForm>
        </React.Fragment >)
}
export default CommentaireForm

