import React from "react";
import { Pie } from "react-chartjs-2";
function PieChart({ labels = [], data = [] }) {
  const dataOptions = {
    labels,
    datasets: [
      {
        label: "Pourcentage par réponse",
        data,
        backgroundColor: ["#e5e5e5", "#17A25C", "#17A25C"],
      },
    ],
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Question fermée",
      },
    },
  };
  return <Pie data={dataOptions} options={options} />;
}

export default PieChart;
