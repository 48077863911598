import React from "react";
import Table from "../../common/table";
import go from "../../../assets/go.png";
import { Col, Row } from "reactstrap";
import ModalForm from "./ModalForm";
import ContractModal from "./ContractModal";
import { getCurrentUser, getJwt } from "../../../services/authServices";
import { getAuthorization } from "../../../services/userServices";
import http from "../../../services/httpServices";
import { apiUrl } from "../../../config.json";
import { Link } from "react-router-dom";
import axios from "axios";
import Avatar from "react-avatar";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());

const data = [
  {
    id: "1",
    name: "Scénarion Test Ordinateur",
    date: "11-05-2020",
    dateL: "12-05-2020",
    type: "Single /Non modéré",
    etat: "Non débuté",
    gain: "0 £",
  },
  {
    id: "2",
    name: "Scénarion Test Mobile",
    date: "11-05-2020",
    dateL: "12-05-2020",
    type: "Single /Non modéré",
    etat: "Non débuté",
    gain: "0 £",
  },
];
const DashboardPageTester = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);

  const [user, setUser] = React.useState({});
  const [scenarios, setScenarios] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log("USER", response);
      })
      .catch((error) => {
        console.log(error, "err");
      });

    axios
      .get(apiEndpoint + "/api/scenario", {
        headers: getAuthorization(),
      })
      .then((response) => {
        //  setScenarios(response && response.data);
        setScenarios((scenarios) => [
          ...scenarios,
          ...(response && response.data),
        ]);
        setTimeout(() => {
          console.log(response && response.data);
        }, 4000);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);

  function handleClose() {
    setVisible(!visible);
  }
  function handleClose2() {
    setVisible2(!visible2);
  }
  function openModalQuiz(id) {
    props.history.push(`/tester/questions/${id}`);
    console.log(id);
  }
  function handleNavigation(id) {
    <Link to={`/tester/questions/${id}`} />;
    console.log(id);
  }
  const renderTable = () => {
    const columns = [
      { title: "Numéro de scénario", field: "id" },
      { title: "Nom du scénario", field: "title" },
      { title: "Date de création", field: "createdAt" },
      { title: "Gain proposé", field: "gain" },
    ];
    const actions = [
      {
        icon: (event, rowData) => (
          <Link to={`/tester/questions/${rowData?.id}`}>
            <img style={{ width: "30px" }} src={go} alt="" />
          </Link>
        ),
        //  icon: (event, rowData) =>

        tooltip: "commencer le quiz",
        onClick: (event, rowData) => {
          openModalQuiz(rowData?.id);
        },

        //  onClick: (event, rowData) =><Link to={`/tester/questions/${rowData?.id}`}> .</Link>
      },
    ];

    // actions={[
    //   rowData => ({
    //     icon: () => <Link to={`/tester/questions/${rowData?.id}`}>Edit(Replace with icon code)</Link>,
    //     tooltip: 'Edit ',
    //     onClick: (rowData)
    //   })
    // ]};

    return (
      <Table
        title="LISTE DES SCENARIOS"
        columns={columns}
        data={scenarios}
        actions={actions}
      />
    );
  };

  return (
    <div style={{displa:'flex', flexDirection:'column', justifyContent:'space-between', paddingTop: -150}}>
      <ModalForm visible={visible} handleClose={handleClose} />
      <ContractModal visible={visible2} handleClose={handleClose2}/>
      <Col
        lg="12"
        md="12"
        className="d-flex flex-wrap justify-content-between align-items-center"
      >
        <div className="ml-5">
          <span style={{fontWeight:'bold',fontSize:25}}>
            Bienvenue {user?.data?.name}
          </span>
        </div>

        <Col className="d-flex justify-content-end no-margin">
          <div style={{marginRight:10}}>
            {/* <img className="profile-avatar" src={maleAvatar} alt="" /> */}
            <Avatar
              size="110"
              name={user?.data?.name + "  " + user?.data?.lastname}
              color="#50C878"
              textSizeRatio={3}
              round={true}
            />
          </div>
        </Col>
      </Col>

      <div
        style={{
          height: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            height: 150,
            width: 400,
            backgroundColor: "#F5F5F5",
            borderRadius: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              height: "100%",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "30%",
                }}
              >
                <span style={{ fontSize: 14, fontWeight: "bold" }}>
                  Scénarios
                </span>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  À tester
                </span>
              </div>
              <span
                style={{ fontSize: 25, fontWeight: "bold", color: "green" }}
              >
                4
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: "bold" }}>
                Scénarios
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#DAA520",
                }}
              >
                Terminé
              </span>
            </div>
            <span
              style={{ fontSize: 25, fontWeight: "bold", color: "#DAA520" }}
            >
              0
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: "bold" }}>
                Scénarios
              </span>
              <span style={{ fontSize: 14, fontWeight: "bold", color: "red" }}>
                Fermés
              </span>
            </div>
            <span style={{ fontSize: 25, fontWeight: "bold", color: "red" }}>
              0
            </span>
          </div>
        </div>
        <div
          style={{
            height: 150,
            width: 400,
            backgroundColor: "#F5F5F5",
            borderRadius: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              height: "100%",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "30%",
                }}
              >
                <span style={{ fontSize: 14, fontWeight: "bold" }}>
                  Accumulés depuis
                </span>
                <span style={{ fontSize: 14, fontWeight: "bold" }}>
                  votre inscription
                </span>
              </div>
              <span style={{ fontSize: 25, fontWeight: "bold", color: "blue" }}>
                0 £
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: "bold" }}>
                A créditer
              </span>
            </div>
            <span
              style={{ fontSize: 25, fontWeight: "bold", color: "#73BE73" }}
            >
              0 £
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: "bold" }}>
                Déjà versé
              </span>
            </div>

            <span style={{ fontSize: 25, fontWeight: "bold", color: "green" }}>
              0 £
            </span>
          </div>
        </div>
        <div
          style={{
            height: 150,
            width:160,
            backgroundColor: "#F5F5F5",
            borderRadius: 10,
            marginLeft: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: "bold" }}>Profil</span>
              <span
                style={{ fontSize: 14, fontWeight: "bold", color: "green" }}
              >
                Complété à
              </span>
            </div>
            <span style={{ fontSize: 25, fontWeight: "bold", color: "green" }}>
              75%
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          marginTop: 20,
          paddingBottom: 50,
          justifyContent: "center",
          paddingLeft: "5%",
        }}
      >
        {renderTable()}
      </div>
    </div>
  );
};
export default DashboardPageTester;
