import React from 'react';
import Table from "../common/table"
import { connect } from 'react-redux';
import { onGetScenarios } from "../../actions/scenarioActions"
import ShowMore from "../../assets/insightdata_consulter.svg"
import moment from 'moment';
import { Col, Button } from "reactstrap"
import Modals from '../common/modal'
import "./scenario.css"
class ScenarioList extends React.Component {
    state = {
        data: [],
        // details: {},
        // show: false
    }

    componentDidMount() {
        if (!this.props.scenario.scenarios[0])
            this.props.onGetScenarios()
        else {
            let scenarios = []
            this.props.scenario.scenarios.map(scenario => {
                scenarios = [...scenarios, {
                    createdAt: scenario.createdAt,
                    product: scenario.product,
                    isModerate: scenario.isModerate,
                    title: scenario.title,
                    isUnique: scenario.isUnique,
                    state: scenario.state,
                    color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: scenarios })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scenario.scenarios !== nextProps.scenario.scenarios) {
            let scenarios = []
            nextProps.scenario.scenarios.map(scenario => {
                scenarios = [...scenarios, {
                    createdAt: scenario.createdAt,
                    product: scenario.product,
                    isModerate: scenario.isModerate,
                    title: scenario.title,
                    isUnique: scenario.isUnique,
                    state: scenario.state,
                    color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: scenarios })

        }
    }
    showDetails = (data) => {
        this.setState({ show: !this.state.show, details: !this.state.show && data })
    }
    // renderTesterDetails() {

    //     return (
    //         <Modals
    //             modalSize="modal-xl"
    //             show={this.state.show}
    //             toggleShow={this.showDetails}
    //             header='Détail Testeur'
    //         >
    //             <UserDetails
    //                 data={this.state.details}
    //                 role="ROLE_TESTER"
    //             />

    //         </Modals>
    //     )
    // }
    renderTable = () => {
        const columns = [
            { title: "Date", field: 'createdAt' },
            { title: "Nom", field: 'title' },
            { title: "Produit", field: 'product' },
            { title: "Unique", field: 'isUnique' },
            { title: "Modéré", field: 'isModerate' },
            { title: "Etat", field: 'state', render: rowData => <span style={{ color: `${rowData.color}` }}>{rowData.state === "to_contact" ? "A contacter" : "Ok"}</span> },
        ]
        const actions = [{
            icon: () => <img style={{ width: "30px" }} src={ShowMore} alt="" />,
            tooltip: 'Détails',
            onClick: (event, rowData) => this.showDetails(rowData),
        },

        ]

        return (
            <Table
                title='Liste des scénarios'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (<>
            <div className='favoris'>
                <span className='db__title'>Mes accès favoris</span>
                <div className='display__flex__row'>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/createscenario")
                    }}>Créer un scénario</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/testerpanel")
                    }}>Panel client</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/step-analyze")
                    }}>Analyse sémantique</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/testerpanel")
                    }}>Stat par testeur</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/usesmanage")
                    }}>Mon compte</Button>
                </div>
            </div>
            <div className='display__flex__row'>

                <Col md='10'>
                    {this.renderTable()}
                </Col>
                <Col md='2'>
                    <div className='scenario__info'>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario en creation</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario en test</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario à analyser</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario non modéré</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                    </div>
                </Col>
                {/* {this.renderTesterDetails()} */}
            </div>
        </>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    { onGetScenarios }
)(ScenarioList);
