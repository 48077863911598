import React from "react";
function OpenComponent({ question, data = [], labels, index }) {
  console.log(index,"iiiiiiiiii")
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <strong>Etape {index +1} :</strong>
        <div style={{ paddingLeft: 20 }}>
          <span className="top-component-fine-black-style">{question}</span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div>
          <strong> {labels && labels.length} réponses</strong>
        </div>
        <div>
          <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
            Question ouverte
          </span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        {labels.map((d) => {
          return (
            <div
              style={{
                height: 50,
                width: 500,
                backgroundColor: "#f0f0f0",
                borderRadius: 10,
                margin: 10,
                padding: 10,
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {d}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OpenComponent;
