import React from 'react';
import Table from "../../common/table"
import { connect } from 'react-redux';
import { onGetScenarios, onGetCount } from "../../../actions/scenarioActions"
import { deleteScenario } from '../../../services/scenarioServices'
import ShowMore from "../../../assets/insightdata_consulter.svg"
import Delete from "../../../assets/delete.svg"
import Edit from "../../../assets/edit2.svg"
import Eye from "../../../assets/eyeTDB.svg"
import Pause from "../../../assets/pause.svg"
import Pause2 from "../../../assets/pause2.svg"
import Panel from "../../../assets/panel.svg"
import Play from "../../../assets/playTDB.svg"
import moment from 'moment';
import { Col, Button } from "reactstrap"
import Modals from '../../common/modal'
import "../dashboard.css"
import "../../scenario/scenario.css"
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
class DashboardClient extends React.Component {
    state = {
        data: [],
        count: {},
        date: moment().format('LLL')
        // details: {},
        // show: false

    }

    componentDidMount() {
        if (!this.props.scenario.scenarios[0]) {
            this.props.onGetScenarios()
            this.props.onGetCount()
        }
        else {
            let scenarios = []
            this.props.onGetCount()
            this.props.scenario.scenarios.map(scenario => {
                scenarios = [...scenarios, {
                    id: scenario.id,
                    createdAt: scenario.createdAt,
                    product: scenario.product,
                    isModerate: scenario.isModerate,
                    title: scenario.title,
                    isUnique: scenario.isUnique,
                    state: scenario.state,
                    color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: scenarios, count: this.props.scenario.count })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scenario.scenarios !== nextProps.scenario.scenarios) {
            this.props.onGetCount()
            let scenarios = []
            nextProps.scenario.scenarios.map(scenario => {
                scenarios = [...scenarios, {
                    id: scenario.id,
                    createdAt: scenario.createdAt,
                    product: scenario.product,
                    isModerate: scenario.isModerate,
                    title: scenario.title,
                    isUnique: scenario.isUnique,
                    state: scenario.state,
                    color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: scenarios, count: this.props.scenario.count })

        }
    }
    dropScenario = (id) => {
        deleteScenario(id).then(res => {
            this.props.onGetScenarios()
            this.props.onGetCount()
            toast.success('Le scénario a été supprimé')
        })
        this.setState({  count: this.props.scenario.count })
    }
    showDetails = (data) => {
        this.setState({ show: !this.state.show, details: !this.state.show && data })
    }
    // renderTesterDetails() {

    //     return (
    //         <Modals
    //             modalSize="modal-xl"
    //             show={this.state.show}
    //             toggleShow={this.showDetails}
    //             header='Détail Testeur'
    //         >
    //             <UserDetails
    //                 data={this.state.details}
    //                 role="ROLE_TESTER"
    //             />

    //         </Modals>
    //     )
    // }
    renderTable = () => {
        const columns = [
            { title: "Date", field: 'createdAt' },
            { title: "Nom de scénario", field: 'title' },
            { title: "Produit", field: 'product' },
            { title: "Unique", field: 'isUnique' },
            { title: "Modéré", field: 'isModerate' },
            { title: "Avancement", field: '' },
            { title: "Etat", field: 'state', render: rowData => <span style={{ color: `${rowData.color}` }}>{rowData.state === "to_contact" ? "A contacter" : "Ok"}</span> },
        ]
        const actions = [
            {
                icon: () => <img style={{ marginLeft: '1.5em', marginRight: '.25em' }} src={Eye} alt="" />,
                tooltip: 'Détails',
                onClick: (event, rowData) => this.showDetails(rowData),
            },

            {
                icon: () => <img style={{ marginRight: '.25em' }} src={Edit} alt="" />,
                tooltip: 'Modifier',
                onClick: (event, rowData) => this.showDetails(rowData),
            },

            {
                icon: () => <img style={{ marginRight: '.25em' }} src={Delete} alt="" />,
                tooltip: 'Supprimer',
                onClick: (event, rowData) => this.dropScenario(rowData.id),
            },
            {
                icon: () => <img style={{ margidnLeft: '2em' }} src={Panel} alt="" />,
                tooltip: 'Panel',
                onClick: (event, rowData) => console.log(rowData),
            },

        ]

        return (
            <Table
                title='Liste des scénarios'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (<>
            <div className='favoris'>
                <span className='db__title'>Mes accès favoris</span>
                <div className='display__flex__row'>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/createscenario")
                    }}>Créer un scénario</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/testerpanel")
                    }}>Panel client</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/step-analyze")
                    }}>Analyse sémantique</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/testerpanel")
                    }}>Stat par testeur</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/usesmanage")
                    }}>Mon compte</Button>
                </div>
            </div>
            <div className='display__flex__row'>

                <Col md='10'>

                    {this.renderTable()}
                </Col>
                <Col md='2'>
                    <div className='scenario__info'>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Licence</span>
                            <br />
                            <br />
                            <span className='square1'>Gold</span>
                        </Col>

                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Comptes actifs</span>
                            <br />
                            <br />
                            <span className='square3'>{this.state.count.comptesactifs}</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Nombre de scénarios</span>
                            <br />
                            <br />
                            <span className='square3'>{this.state.count.scenarios}</span>
                        </Col>
                        <Col className="col__style" data-tip="Le “montant facturé” correspond au coût total engagé ce mois-ci;<br> soit à la somme des tests utilisateurs que vous avez pu réalisé avec le panel Insight Data.
                        <br>
Par exemple, ce mois-ci vous avez utilisé 5 testeurs Inisght Data à 20€, alors,<br> vous aurez un montant facturé de 100€."
                            data-background-color="#F3FBF7 " 
                            data-text-color='#000' 
                            data-multiline={true}
                            // data-type='info'
                           
                        >
                            <span style={{ fontWeight: 'bold' }}  >Montant facturé</span>
                            <br />
                            <br />
                            <span className='square2'>0</span>
                        </Col>
                    </div>
                </Col>
                {/* {this.renderTesterDetails()} */}
                <ReactTooltip />
            </div>
        </>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    { onGetScenarios, onGetCount }
)(DashboardClient);