import React, { useState, useEffect } from "react";
import SubTopComponent from "./rawComponents/SubTopComponent";
import FirstResume from "./rawComponents/FirstResume";
import BarComponent from "./rawComponents/BarComponent";
import StepResume from "./rawComponents/StepResume";
import excel from "../../../assets/excel.png";
import axios from "axios";
import { getAuthorization } from "../../../services/userServices";
import { apiUrl } from "../../../config.json";
import TesterResume from "./rawComponents/TesterResume";
import arrowL from "../../../assets/arrowL.png";
import vl  from "../../../assets/vl.png";
import vr  from "../../../assets/vr.png";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const apiEndpoint = apiUrl;

const StepCount = ({ incrimentTester, stepCount, nbTesters }) => {
  return (
      <div style={{display:'flex', width:200, justifyContent:'space-around'}}>
        <div
          className="mr-2 cursor-pointer "
          onClick={() => incrimentTester(-1)}
          >
                  <img src={vl} style={{height:12, width:8}}/>

        </div>
        <span>{`${stepCount}`}</span>
        <span>{`sur`}</span>

        <span>{`${nbTesters}`}</span>

        <div
          className="ml-2 cursor-pointer"
          onClick={() => incrimentTester(1)}
          >
                  <img src={vr} style={{height:12, width:8}}/>

        </div>
      </div>
  );
};

function RawData() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [dropdownOpen1, setDropdownOpen1] = React.useState(false);
  const toggle1 = () => setDropdownOpen1((prevState) => !prevState);

  const [selected, setSelected] = React.useState("Scénario");
  const [selectedT, setSelectedT] = React.useState("Testeur");

  const [choice, setChoice] = React.useState("scenario");
  const [_id, setId] = React.useState(28);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [topState, setTopState] = useState("first");
  const [scenarios, setScenarios] = useState([]);
  const [headerPayload, setHeaderPayload] = React.useState([]);
  const [stepCount, setStepCount] = React.useState(1);
  const [stepId, setStepId] = React.useState(1);
  const [step, setStep] = React.useState(1);
  const [selectedTester, setSelectedTester] = React.useState({});
  const [testersByScenario, setTestersByScenario] = React.useState([]);
  const [testerID, setTesterID] = React.useState();
  const [ScenarioOfTester, setScenarioOfTester] = React.useState([]);
  const getScenarios = () => {
    axios
      .get(apiEndpoint + "/api/scenario", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setScenarios(() => [...(response && response.data)]);
        // setTimeout(() => {
          console.log(response && response.data);
        // }, 4000);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  };
  const getScenario = (id) => {
    axios
      .get(apiEndpoint + `/api/scenario/${id}/details`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        setHeaderPayload(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStep = (id) => {
    axios
      .get(apiEndpoint + `/api/stats/step/${id}`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        setStep(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const LoadTestersByScenario = (x) => {
    axios
      .get(apiEndpoint + `/api/scenario/${x}/testers`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        setTestersByScenario(response?.data);
        console.log({ tester: response?.data[0]?.id });
        setTesterID(response?.data[0]?.id);
        LoadScenarioByTester(response?.data[0]?.id, _id);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const LoadScenarioByTester = async (id, _id) => {
    if (id && _id)
      axios
        .get(apiEndpoint + `/api/stats/tester/${id}/${_id}`, {
          headers: getAuthorization(),
        })
        .then((response) => {
          console.log(response, "response");
          setScenarioOfTester(response?.data[0]?.answers);
        })
        .catch((error) => {
          console.log("err", error);
        });
  };
  const TestersIds = (testersByScenario) => {
    if (testersByScenario.length === 0) {
      return <div>zero</div>;
    }
    if (testersByScenario.length > 0) {
      for (let i = 0; i < testersByScenario.length; i++) {
        console.log(i);
        if (testersByScenario.length === !0) {
          return (
            <div>
              {testersByScenario.map((tt) => {
                return (
                  <div>
                    {tt.name} {i}
                  </div>
                );
              })}
            </div>
          );
        }
      }
    }
    return null;
  };

  React.useEffect(() => {
    getScenarios();
    getScenario(_id);
    getStep(stepId);
    LoadTestersByScenario(_id);

    // TestersIds(testersByScenario);
  }, [_id, stepId, choice]);

  const incrementStep = (value) => {
    if (stepCount + value > getStepsIds()?.length || stepCount + value <= 0) {
      return null;
    }
    setStepId(getStepsIds()[stepCount + value - 1]);
    setStepCount((prev) => prev + value);
    setChoice("step");
  };
  const getStepsIds = () => {
    const steps = scenarios?.find((sc) => sc?.id === _id)?.steps;
    const stepsIds = steps?.map((s) => s?.id);
    return stepsIds;
  };

  const getStepsCount = () => {
    const steps = scenarios?.find((sc) => sc?.id === _id)?.steps;
    const nbStep = scenarios?.find((sc) => sc?.id === _id)?.steps?.length;
    return (
      <div style={{display:'flex', width:200, justifyContent:'space-around'}}>
        <div
          className="mr-2 cursor-pointer "
          onClick={() => incrementStep(-1)}
        >
                  <img src={vl} style={{height:12, width:8}}/>

        </div>
        <span style={{fontWeight:400}}>{`${stepCount}`}</span>
        <span style={{fontWeight:400}}>{`sur`}</span>

        <span style={{fontWeight:400}}>{`${nbStep}`}</span>

        <div
          className="ml-2 cursor-pointer"
          onClick={() => incrementStep(1)}
        >
                  <img src={vr} style={{height:12, width:8}}/>

        </div>
      </div>
    );
  };
  const incrimentTester = (value) => {
    if (
      stepCount + value > testersByScenario?.length ||
      stepCount + value <= 0
    ) {
      return null;
    }
    console.log(testersByScenario[stepCount - 1]?.id);
    setTesterID(testersByScenario[stepCount - 1]?.id);

    setStepCount((prev) => prev + value);
    setChoice("tester");
  };
  React.useEffect(() => {
    LoadScenarioByTester(testerID, _id);
  }, [_id, testerID]);
  return (
    <div className="raw-container">
      <div className="s-top-component-container">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="success" caret>
            {selected}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Veuillez choisir un scénario </DropdownItem>
            {scenarios.map((sc) => {
              return (
                <DropdownItem
                  onClick={() => {
                    setSelected(sc.title);
                    setId(sc.id);
                    setStepCount(1);
                    setChoice("scenario");
                  }}
                >
                  {sc.title}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <div className="sub-sub-top-component-container">
          <span className="top-component-black-style">Etapes</span>
          <div style={{ paddingLeft: 10 }}></div>
          <span className="top-component-fine-black-style">
            {headerPayload[0]?.steps}
          </span>
        </div>

        <div className="sub-sub-top-component-container">
          <span className="top-component-black-style">Testeurs</span>
          <div style={{ paddingLeft: 10 }}></div>
          <span className="top-component-fine-black-style">
            {headerPayload[0]?.testers}
          </span>
        </div>

        <div className="sub-sub-top-component-container">
          <span className="top-component-black-style">Pannel</span>
          <div style={{ paddingLeft: 10 }}></div>
          <span className="top-component-fine-black-style">
            {headerPayload[0]?.type}
          </span>
        </div>
      </div>

      {/* <div className="top-component-container">
        <div
          className="sub-top-component-container"
          onClick={() => setTopState("first")}
        >
          <span className="top-component-green-style">2 Réponses</span>
          {topState}
        </div>

        <div
          className="sub-top-component-container"
          onClick={() => setTopState("resume")}
        >
          <span className="top-component-green-style">Résumé</span>
          <img src={excel} alt="" className="excel-img" />
        </div>

        <div
          className="sub-top-component-container"
          onClick={() => setTopState("step")}
        >
          <span className="top-component-black-style">Par étape</span>
        </div>

        <div
          className="sub-top-component-container"
          onClick={() => setTopState("tester")}
        >
          <span className="top-component-black-style">Par testeur</span>
        </div>
      </div> */}

      <div className="col-top-component-container">
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          <span className="top-component-green-style">2 Réponses</span>
          <img src={excel} alt="" className="excel-img" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <div
            className={
              choice === "scenario"
                ? "border-bottoms"
                : "border-bottoms-without"
            }
          >
            <span
              className="top-component-green-style cursor-pointer"
              onClick={() => {
                setChoice("scenario");
              }}
            >
              Données brutes
            </span>
          </div>
          <div
            className={
              choice === "step" ? "border-bottoms" : "border-bottoms-without"
            }
          >
            {" "}
            <span
              className="top-component-green-style cursor-pointer"
              onClick={() => {
                setChoice("step");
              }}
            >
              Par étape
            </span>
          </div>
          <div
            className={
              choice === "tester" ? "border-bottoms " : "border-bottoms-without"
            }
          >
            {" "}
            <span
              className="top-component-green-style cursor-pointer"
              onClick={() => {
                setChoice("tester");
                LoadTestersByScenario(_id);
              }}
            >
              Par testeur
            </span>
          </div>
        </div>
        {["step"].includes(choice) && (
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              paddingTop: 10,
             // borderTop: "0.1px solid gray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "90%",
                height: 30,
                borderRadius: 5,
                border: "1px solid green",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                paddingLeft: 20,
                paddingTop: 2,
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Etape {step?.number} :{" "}
              </span>{" "}
              {step?.question}
            </div>
            <div
              style={{
                width: "90%",
                height: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                paddingLeft: 20,
                paddingTop: 10,
              }}
            >
              <span style={{ fontWeight: "bold" }}> {getStepsCount()} </span>
            </div>
          </div>
        )}

        {choice === "tester" && (
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              paddingTop: 10,
              borderTop: "0.1px solid gray",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              paddingLeft: 50,
            }}
          >
            <StepCount
              stepCount={stepCount}
              nbTesters={testersByScenario?.length}
              incrimentTester={incrimentTester}
            />
          </div>
        )}
      </div>
      {choice === "scenario" ? (
        <React.Fragment>
          <FirstResume _id={_id} />
        </React.Fragment>
      ) : choice === "step" ? (
        <StepResume step={step} id={_id} />
      ) : (
        choice === "tester" && (
          <>
            <TesterResume ScenarioOfTester={ScenarioOfTester} senario={_id} />{" "}
            <TestersIds />
          </>
        )
      )}
    </div>
  );
}

export default RawData;
