import React from "react";
import {
  Form,
  Input,
  Label,
  Col,
  Row,
  FormGroup,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function SubTopComponent(props) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("Scénario");
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="s-top-component-container">
      {/* <div className="sub-sub-top-component-container-sc">
        <span className="top-component-white-style">Scénario </span>
        <div style={{ paddingLeft: 10 }}>
          <span className="top-component-white-style"> > </span>
        </div>
      </div> */}

      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="success" caret>
          {selected} >
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Veuillez choisir </DropdownItem>
          {props?.scenarios.map((sc) => {
            return (
              <DropdownItem onClick={() => setSelected(sc.title)}>
                {sc.title}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
      <div className="sub-sub-top-component-container">
        <span className="top-component-black-style">Etapes</span>
        <div style={{ paddingLeft: 10 }}></div>
        <span className="top-component-fine-black-style">4</span>
      </div>

      <div className="sub-sub-top-component-container">
        <span className="top-component-black-style">Testeurs</span>
        <div style={{ paddingLeft: 10 }}></div>
        <span className="top-component-fine-black-style">2</span>
      </div>

      <div className="sub-sub-top-component-container">
        <span className="top-component-black-style">Pannel</span>
        <div style={{ paddingLeft: 10 }}></div>
        <span className="top-component-fine-black-style">Client</span>
      </div>
    </div>
  );
}

export default SubTopComponent;
