import React from 'react';
import {
    Button, Label, Col, FormFeedback
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './register.css'
import { Link } from "react-router-dom";
import flags from 'react-phone-number-input/flags';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
const ClientRegisterForm = ({
    tog_standard, confirmText, usecase, userText, nbemployees, //page, onChangePage,
    handleSubmit, currentUser, onchange, error, loading, onChangePhone
}) => {


    return (
        <React.Fragment >

            <AvForm onValidSubmit={handleSubmit}>
                <Col md='12'>
                    <div className='modal__form'>

                        <Label id="label__style__signup">{userText.useCase}</Label>
                        <AvField type="select" name="useCase" id='useCase' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre cas d'utilisation</option>
                            {usecase.map(res => <option key={res} value={res}>{res}</option>)}
                        </AvField>
                        {currentUser.useCase !== "Testeur" ? <>
                            <Label id="label__style__signup">{userText.lastname}</Label>
                            <AvField name="lastname" value={currentUser.lastname}
                                placeholder="Nom de famille" type="text"
                                onChange={onchange}
                                id='lastName'
                                validate={{
                                    maxLength: { value: 50 },
                                    required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                    pattern: { value: "^[a-zA-Z ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                                }}
                            />
                            <Label id="label__style__signup">{userText.name}</Label>
                            <AvField name="name" value={currentUser.name}
                                placeholder="Prénom" type="text"
                                onChange={onchange}
                                id='firstName'
                                validate={{
                                    maxLength: { value: 50 },
                                    required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                    pattern: { value: "[a-zA-Z ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                                }}
                            />
                            <Label id="label__style__signup">{userText.email}</Label>
                            <AvField name="email" value={currentUser.email}
                                placeholder="Email" type="text"
                                onChange={onchange}
                                id='email'
                                invalid={error && error.email ? true : false}
                                validate={{

                                    required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                    pattern: { value: '[a-z0-9._%+-]+@(?!gmail|outlook|live|free|hotmail|ironfle|yahoo|laposte)[a-z0-9.-]+.[a-z]{2,3}$', errorMessage: "Doit être un e-mail professionnel du type valide.example@votredomaine.com" }
                                }}
                            />
                            {error && <FormFeedback  >{error.email} </FormFeedback>}
                            <Label id="label__style__signup">{userText.phone}</Label>
                            {/* <AvField name="phone" label="" value={currentUser.phone}
                                placeholder="Téléphone" type="text"
                                onChange={onchange}
                                id='phone'
                                validate={{

                                    required: { value: true, errorMessage: "Ce champ est obligatoire" },

                                }}
                            /> */}
                            <PhoneInput
                                // country="FR"
                                id='age'
                                flags={flags}
                                value={currentUser.phone}
                                //error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                onChange={onChangePhone} />
                            {currentUser.phone ? (isValidPhoneNumber(currentUser.phone) ? undefined : <FormFeedback  >Numéro de téléphone invalide </FormFeedback>) : null}
                            {(currentUser.company !== "" && currentUser.phone === "") && <FormFeedback  >Ce champ est obligatoire </FormFeedback>}
                            <Label id="label__style__signup">{userText.company}</Label>
                            <AvField name="company" value={currentUser.company}
                                placeholder="Société" type="text"
                                onChange={onchange}
                                id='company'
                                validate={{

                                    required: { value: true, errorMessage: "Ce champ est obligatoire" },

                                }}
                            />
                            {/* <div className="btn_modal_ctn">
                                <Button type="submit" className="btn__form__modal" onClick={onChangePage}
                                >
                                    Valider</Button>

                            </div> */}
                            {/* {page === '2' && <>  */}
                            <Label id="label__style__signup">{userText.profession}</Label>
                                <AvField name="profession" value={currentUser.profession}
                                    placeholder="Fonction" type="text"
                                    onChange={onchange}
                                    id='profession'
                                    validate={{

                                        required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                        pattern: { value: "^[A-zÀ-ú ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                                    }}
                                />
                                <Label id="label__style__signup">{userText.sector}</Label>
                                <AvField name="sector" label="" value={currentUser.sector}
                                    placeholder="Secteur" type="text"
                                    onChange={onchange}
                                    id='sector'
                                    validate={{

                                        required: { value: true, errorMessage: "Ce champ est obligatoire" },

                                    }}
                                />
                                <Label id="label__style__signup">{userText.nbEmployees}</Label>
                                <AvField type="select" name="nbEmployees" id='nbEmployees' onChange={onchange}
                                    validate={{
                                        required: { value: true, errorMessage: "Ce champ est obligatoire" },

                                    }}
                                >
                                    <option value="" disabled defaultValue>Saisir le nombre d'emplyés</option>
                                    {nbemployees.map(res => <option key={res} value={res}>{res}</option>)}
                                </AvField>


                                <div className="btn_modal_ctn">
                                    <Button type="submit" className="btn__form__modal" disabled={loading}
                                    >
                                        {loading && <i className="fa fa-refresh fa-spin mr-2" />}{confirmText}</Button>

                                </div>
                                {/* </>} */}
                        </> :
                            <div className="redirect__signup">
                                <span>Pour en savoir plus sur la façon d'être payé pour tester des sites Web, des applications ou des prototypes,
                                    <Link className="forget__password" onClick={tog_standard} to={{ pathname: '/testersignup' }}>cliquez ici </Link>pour vous inscrire.

                                </span>
                            </div>
                        }
                    </div>
                </Col>
            </AvForm>

        </React.Fragment >)
}
export default ClientRegisterForm

