import React, { Component } from 'react';
import { connect } from 'react-redux';
import Piechart from "../common/charts/pie"
import Linechart from "../common/charts/line"
class AllData extends Component {
    state = {}
    render() {
        const data = [
            { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
            { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
          ];
          const COLORS = [
            "#7DB3FF",
            "#49457B",
            "#FF7C78",
            "#FED3D0",
            "#6F76D9",
            "#9ADFB4",
            "#2E7987"
          ];
            const data1 = [
              {
                name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
              },
              {
                name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
              },
              {
                name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
              },
              {
                name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
              },
              {
                name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
              },
              {
                name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
              },
              {
                name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
              },
            ];
        return (<div className="scenario__ctn" >
            <span className="title__style">All Data</span>
             <Piechart
                    data={data}
                    colors={COLORS}
                />
                <Linechart
                    data={data1}
                />
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {  }
)(AllData);