import React from 'react';
import {
    Button, Label, Col, Row
} from 'reactstrap';


const UserDetails = ({
    data, activate,validate, desactivate, generatePW, role, visualiser, desactivateTester
}) => {


    return (
        <React.Fragment style={{ direction: 'flex', flexDirection: 'row' }}>
            <div className='modal__form'>
                <Row>
                    <Col sm='12' md='6'>
                        <span style={{ fontWeight: "600" }}> Données personelles</span>
                       {role==="ROLE_CLIENT"&& <div>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Date:</Label> {data.createdAt}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Nom:</Label>  {data.lastname}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Prémon:</Label> {data.name}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Société:</Label> {data.company}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Téléphone:</Label> {data.phone}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Mail:</Label> {data.email}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Cas d'utilisation:</Label> {data.useCase}</li>
                        </div>}
                       {role==="ROLE_TESTER"&& <div>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Date:</Label> {data.createdAt}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Nom:</Label>  {data.lastname}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Prémon:</Label> {data.name}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Téléphone:</Label> {data.phone}</li>
                            <li><Label style={{ fontWeight: "500", color: "#00a359" }}>Mail:</Label> {data.email}</li>
                        </div>}
                    </Col>
                    <Col sm='12' md='6'>
                        <span style={{ fontWeight: "600" }}>Etat</span>
                        <div>
                            <span style={{ fontWeight: "500", color: 'red' }}>{data.state === "to_contact" ? "A contacter" : "Ok"}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm='12' md='6'>
                        <span style={{ fontWeight: "600" }}> Commentaire</span>
                    </Col>
                    {role==="ROLE_CLIENT"&&<Col sm='12' md='6'>
                        <span style={{ fontWeight: "600" }}> Durée demo restante</span>
                        <div>
                            <span style={{ fontWeight: "500", color: 'red' }}></span>
                        </div>
                    </Col>}
                </Row>

                {role==="ROLE_CLIENT"&&<div className='modal__btns' >
                    <Button className="signup__button" > Accorder Acces</Button>
                    <Button className="signup__button " style={{ margin: "0 9em" }} onClick={generatePW} disabled={data.state === "to_contact"}>Générer Mot de Passe </Button>
                    <Button className="signup__button" onClick={desactivate} disabled={data.state === "to_contact"}> Desactiver</Button>
                </div>}
                {role==="ROLE_TESTER"&&<div className='modal__btns' >
                    <Button className="signup__button "  onClick={()=>validate()}>Valider testeur </Button>
                    <Button className="signup__button " style={{ margin: "0 8em" }} onClick={visualiser}>Visualiser le test d’admission </Button>
                    <Button className="signup__button" onClick={desactivateTester} disabled={data.state === "to_contact"}> Desactiver</Button>
                </div>}
            </div>
        </React.Fragment >)
}
export default UserDetails

