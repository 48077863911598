import React from 'react';
import Table from "../common/table"
import { connect } from 'react-redux';
import { onGetPanels } from "../../actions/scenarioActions"
import ShowMore from "../../assets/insightdata_consulter.svg"
import moment from 'moment';
import { Col, Button } from "reactstrap"
import Modals from '../common/modal'
import "./scenario.css"
class PanelList extends React.Component {
    state = {
        data: [],
        // details: {},
        // show: false
    }

    componentDidMount() {
        if (!this.props.scenario.panels[0])
            this.props.onGetPanels()
        else {
            let panels = []
            this.props.scenario.panels.map(panel => {
                panels = [...panels, {
                    name: panel.name,
                    scenarioName: panel.scenarioName,
                    testersNb: panel.testersNb,
                    type:panel.type,
                    state: panel.state,
                }]
            })
            this.setState({ data: panels })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scenario.panels !== nextProps.scenario.panels) {
            let panels = []
            nextProps.scenario.panels.map(panel => {
                panels = [...panels, {
                    name: panel.name,
                    scenarioName: panel.scenarioName,
                    testersNb: panel.testersNb,
                    type:panel.type,
                    state: panel.state,
                    
                }]
            })
            this.setState({ data: panels })

        }
    }
    showDetails = (data) => {
        this.setState({ show: !this.state.show, details: !this.state.show && data })
    }
    // renderTesterDetails() {

    //     return (
    //         <Modals
    //             modalSize="modal-xl"
    //             show={this.state.show}
    //             toggleShow={this.showDetails}
    //             header='Détail Testeur'
    //         >
    //             <UserDetails
    //                 data={this.state.details}
    //                 role="ROLE_TESTER"
    //             />

    //         </Modals>
    //     )
    // }
    renderTable = () => {
        const columns = [
            { title: "Nom du panel", field: 'name' },
            { title: "Nombre de testeurs", field: 'testersNb' },
            { title: "type de panel", field: 'type' },
            { title: "scénario associé", field: 'scenarioName' },
            
        ]
        const actions = [{
            icon: () => <img style={{ width: "30px" }} src={ShowMore} alt="" />,
            tooltip: 'Détails',
            onClick: (event, rowData) => this.showDetails(rowData),
        },

        ]

        return (
            <Table
                title='Liste des panels'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (<div >
            <div className='favoris'>
                <span className='db__title'>Mes accès favoris</span>
                <div className='display__flex__row'>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/createscenario")
                    }}>Créer un scénario</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/testerpanel")
                    }}>Panel client</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/step-analyze")
                    }}>Analyse sémantique</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/testerpanel")
                    }}>Stat par testeur</Button>
                    <Button className='shortcut' onClick={() => {
                        this.props.history.push("/client/usesmanage")
                    }}>Mon compte</Button>
                </div>
            </div>
            <div className='display__flex__row'>
            <Col md='12'>
            { this.renderTable()}
            </Col>
            {/* <Col md='2'>
                    <div className='scenario__info'>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario en creation</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario en test</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario à analyser</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Scénario non modéré</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                    </div>
                </Col> */}
            </div>
            {/* <div className='scenario__info'>
                <Col className="col__style">
                    <span>Senario en creation</span>
                </Col>
                <Col className="col__style"><span>Senario en test</span></Col>
                <Col className="col__style"><span>Senario à analyser</span></Col>
                <Col className="col__style"><span>Senario non modéré</span></Col>
            </div> */}
            {/* {this.renderTesterDetails()} */}
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    { onGetPanels }
)(PanelList);