import React from "react";
import { Redirect } from "react-router";
import { changeActiveTabDashboard } from "../../actions/authActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import "./navTab.css";
class NavTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.auth.tabDashboard,
            count: 0,
        }
    }

    toggle = tab => {

        if (this.state.activeTab !== tab) {
            this.props.changeActiveTabDashboard(tab)
            this.setState(
                {
                    activeTab: tab
                }
            )
        }
    }
    count = (i) => {
        this.setState({ count: this.state.count + i - this.state.count })
    }
    render() {
        let { activeTab } = this.state
        let dropDown = ["Scenario", "Statistiques", "Analyses"]
        return (
            <Container fluid>
                <Row noGutters >
                    <Col sm="12" md={{ size: 10, offset: 1 }}>
                        <div className="nav__bar__config">
                            <Nav tabs >
                                {this.props.Menu.map((route, i) => {
                                    return route.Name !== "" && <NavItem key={i} style={{ width: "20%" }}>
                                        {!(dropDown.includes(route.Name)) && <NavLink
                                            className={classnames({ active: activeTab === i })}
                                            onClick={() => { this.toggle(i); }}
                                        >
                                            <div className="item__header__icon">
                                                {route.icon}
                                            </div>
                                            <div className="item__header2">
                                                {route.Name}
                                            </div>

                                        </NavLink>}
                                        {route.Name === "Scenario" &&
                                            <UncontrolledDropdown direction="down" nav >
                                                <DropdownToggle nav caret>
                                                    <div className="item__header__icon">
                                                        {route.icon}
                                                    </div>
                                                    <div className="item__header2">
                                                        {route.Name}
                                                    </div>

                                                </DropdownToggle>
                                                <DropdownMenu style={{ margin: "auto" }} >

                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/scenario")
                                                            this.toggle(1)
                                                        }}
                                                    >
                                                        <div className="log__out">
                                                            Liste des scenarios
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/createscenario")
                                                            this.toggle(2)
                                                        }}
                                                    >
                                                        <div className="log__out">

                                                            Créer un scenario
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/testerpanel")
                                                            this.toggle(3)
                                                        }}
                                                    >
                                                        <div className="log__out">
                                                            Panel de testeurs
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/panellist")
                                                            this.toggle(4)
                                                        }}
                                                    >
                                                        <div className="log__out">
                                                            Liste de Panels
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        }
                                        {route.Name === "Statistiques" &&
                                            <UncontrolledDropdown direction="down" nav>
                                                <DropdownToggle nav caret>
                                                    <div className="item__header__icon">
                                                        {route.icon}
                                                    </div>
                                                    <div className="item__header2">{route.Name}</div>
                                                </DropdownToggle>
                                                <DropdownMenu style={{ margin: "auto" }}>
                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/statistics");
                                                            // this.toggle(5);
                                                        }}
                                                    >
                                                        <div className="log__out">Panel</div>
                                                    </DropdownItem>

                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/statistics");
                                                            // this.toggle(2);
                                                        }}
                                                    >
                                                        <div className="log__out">Réponses</div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        }
                                        {route.Name === "Analyses" &&
                                            <UncontrolledDropdown direction="down" nav >
                                                <DropdownToggle nav caret>
                                                    <div className="item__header__icon">
                                                        {route.icon}
                                                    </div>
                                                    <div className="item__header2">
                                                        {route.Name}
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu style={{ margin: "auto" }} >

                                                    <UncontrolledDropdown direction="right" nav >
                                                        <DropdownToggle nav caret>
                                                            <div className="drop__down">

                                                                Sémantique
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ margin: "auto" }} >
                                                            <DropdownItem
                                                                id="content__item__navbar"
                                                                onClick={() => {
                                                                    this.props.history.push("/client/step-analyze")
                                                                    //this.toggle(6)
                                                                }}
                                                            >
                                                                <div className="log__out">

                                                                    Par étape
                                                                </div>
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                id="content__item__navbar"
                                                                onClick={() => {
                                                                    this.props.history.push("/client/tester-analyze")
                                                                    //this.toggle(6)
                                                                }}
                                                            >
                                                                <div className="log__out">

                                                                    Par testeur
                                                                </div>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <UncontrolledDropdown direction="right" nav >
                                                        <DropdownToggle nav caret>
                                                            <div className="drop__down">

                                                            Reconnaissance faciale
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ margin: "auto" }} >
                                                            <DropdownItem
                                                                id="content__item__navbar"
                                                                onClick={() => {
                                                                    this.props.history.push("/client/facial-recognition")
                                                                    //this.toggle(6)
                                                                }}
                                                            >
                                                                <div className="log__out">

                                                                    Par étape
                                                                </div>
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                id="content__item__navbar"
                                                                onClick={() => {
                                                                    this.props.history.push("/client/tester-facial-recognition")
                                                                    //this.toggle(6)
                                                                }}
                                                            >
                                                                <div className="log__out">

                                                                    Par testeur
                                                                </div>
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                id="content__item__navbar"
                                                                onClick={() => {
                                                                    this.props.history.push("/client/tester-facial-recognition")
                                                                    //this.toggle(6)
                                                                }}
                                                            >
                                                                <div className="log__out">

                                                                    Par émotion
                                                                </div>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>

                                                    {/* <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/facial-recognition")
                                                            //this.toggle(6)tester-facial-recognition
                                                        }}
                                                    >
                                                        <div className="log__out">

                                                            Reconnaissance faciale
                                                        </div>
                                                    </DropdownItem> */}
                                                    <DropdownItem
                                                        id="content__item__navbar"
                                                        onClick={() => {
                                                            this.props.history.push("/client/analyze")
                                                            // this.toggle(7)
                                                        }}
                                                    >
                                                        <div className="log__out">

                                                            Deep
                                                        </div>
                                                    </DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        }
                                    </NavItem>
                                }
                                )}
                            </Nav>
                            <TabContent activeTab={activeTab}  >
                                {this.props.Menu.map((route, i) =>
                                    <TabPane tabId={i}>
                                        {activeTab === i && <Redirect onClick={() => this.count(i)}
                                            style={{ color: "black" }} to={`${this.props.path}${route.path}`} />}

                                    </TabPane>

                                )}
                            </TabContent>
                        </div>

                    </Col>
                </Row>
            </Container>

        );
    }
};
// count = (i) => {
//   this.setState({ count: this.state.count + i - this.state.count });
// };
// render() {
//   let { activeTab } = this.state;
//   return (
//     <Container fluid>
//       <Row noGutters>
//         <Col sm="12" md={{ size: 10, offset: 1 }}>
//           <div className="nav__bar__config">
//             <Nav tabs>
//               {this.props.Menu.map((route, i) => {
//                 return (
//                   route.Name !== "" && (
//                     <NavItem key={i} style={{ width: "20%" }}>
//                       {route.Name !== "Scenario" &&
//                         route.Name !== "Statistiques" && (
//                           <NavLink
//                             className={classnames({
//                               active: activeTab === i,
//                             })}
//                             onClick={() => {
//                               this.toggle(i);
//                             }}
//                           >
//                             <div className="item__header__icon">
//                               {route.icon}
//                             </div>
//                             <div className="item__header2">{route.Name}</div>
//                           </NavLink>
//                         )}

//                       {route.Name === "Scenario" ? (
//                         <UncontrolledDropdown direction="down" nav>
//                           <DropdownToggle nav caret>
//                             <div className="item__header__icon">
//                               {route.icon}
//                             </div>
//                             <div className="item__header2">{route.Name}</div>
//                           </DropdownToggle>
//                           <DropdownMenu style={{ margin: "auto" }}>
//                             <DropdownItem
//                               id="content__item__navbar"
//                               onClick={() => {
//                                 this.props.history.push("/client/scenario");
//                                 this.toggle(1);
//                               }}
//                             >
//                               <div className="log__out">
//                                 Liste des scenarios
//                               </div>
//                             </DropdownItem>
//                             <DropdownItem
//                               id="content__item__navbar"
//                               onClick={() => {
//                                 this.props.history.push(
//                                   "/client/createscenario"
//                                 );
//                                 this.toggle(1);
//                               }}
//                             >
//                               <div className="log__out">
//                                 Créer un scenario
//                               </div>
//                             </DropdownItem>
//                             <DropdownItem
//                               id="content__item__navbar"
//                               onClick={() => {
//                                 this.props.history.push(
//                                   "/client/testerpanel"
//                                 );
//                                 this.toggle(1);
//                               }}
//                             >
//                               <div className="log__out">
//                                 Panel de testeurs
//                               </div>
//                             </DropdownItem>
//                             <DropdownItem
//                               id="content__item__navbar"
//                               onClick={() => {
//                                 this.props.history.push("/client/panellist");
//                                 this.toggle(1);
//                               }}
//                             >
//                               <div className="log__out">Liste de Panels</div>
//                             </DropdownItem>
//                           </DropdownMenu>
//                         </UncontrolledDropdown>
//                       ) : route.Name === "Statistiques" ? (
//                         <UncontrolledDropdown direction="down" nav>
//                           <DropdownToggle nav caret>
//                             <div className="item__header__icon">
//                               {route.icon}
//                             </div>
//                             <div className="item__header2">{route.Name}</div>
//                           </DropdownToggle>
//                           <DropdownMenu style={{ margin: "auto" }}>
//                             <DropdownItem
//                               id="content__item__navbar"
//                               onClick={() => {
//                                 this.props.history.push("/client/statistics");
//                                 this.toggle(1);
//                               }}
//                             >
//                               <div className="log__out">Panel</div>
//                             </DropdownItem>

//                             <DropdownItem
//                               id="content__item__navbar"
//                               onClick={() => {
//                                 this.props.history.push("/client/statistics");
//                                 this.toggle(1);
//                               }}
//                             >
//                               <div className="log__out">Réponses</div>
//                             </DropdownItem>
//                           </DropdownMenu>
//                         </UncontrolledDropdown>
//                       ) : null}
//                     </NavItem>
//                   )
//                 );
//               })}
//             </Nav>
//             <TabContent activeTab={activeTab}>
//               {this.props.Menu.map((route, i) => (
//                 <TabPane tabId={i}>
//                   {activeTab === i && (
//                     <Redirect
//                       onClick={() => this.count(i)}
//                       style={{ color: "black" }}
//                       to={`${this.props.path}${route.path}`}
//                     />
//                   )}
//                 </TabPane>
//               ))}
//             </TabContent>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
//   }
// }
NavTab.propTypes = {
    auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { changeActiveTabDashboard })(NavTab);
