import React from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import Avatar from "react-avatar";

import { getCurrentUser, getJwt } from "../../../services/authServices";
import { getAuthorization } from "../../../services/userServices";
import http from "../../../services/httpServices";
import { apiUrl } from "../../../config.json";
import axios from "axios";
const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
const Profile = (props) => {
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);
  return (
    <React.Fragment>
      <Row
        className="bg-grey no-margin  p-2 top-bandeau   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  "
      >
        <Col lg="4" md="4" className="main mr-2 card-padding-top ">
        <Avatar
              size="110"
              name={user?.data?.name + "  " + user?.data?.lastname}
              color="#50C878"
              textSizeRatio={3}
              round={true}
            />          <div className="d-flex flex-column text-padding-top">
            <span className="font-medium-2 font-weight-600">
              {" "}
              {user?.data?.name + " " + user?.data?.lastname}
            </span>
            <span>{user?.data?.country}</span>
            <span>{user?.data?.email}</span>
          </div>
          <hr />
          <div></div>
          <Col
            md="12"
            lg="12"
            className="d-flex justify-content-center align-items-center padding-btn"
          >
            <Button className="color=#301934">TELECHARGER VOTRE PHOTO</Button>
          </Col>
        </Col>
        <Col lg="7" md="7" className="p-3 main">
          <Form>
            <Row>
              <Col>
                <div className="column-left">
                  <Label>Prénom</Label>
                  <Input placeholder={user?.data?.name} />
                </div>
              </Col>
              <Col>
                <div className="column-left">
                  <Label>Nom</Label>
                  <Input placeholder={user?.data?.lastname} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="column-left">
                  <Label>Adresse email</Label>
                  <Input placeholder={user?.data?.email} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="column-left">
                  <Label>Numéro du portable</Label>
                  <Input type="number" placeholder={user?.data?.phone} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="column-left">
                  <Label>Pays</Label>

                  <Input placeholder={user?.data?.country} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="column-left">
                  <Label>Ville</Label>
                  <Input as="select">
                    <option>Paris</option>
                    <option>Lyon</option>
                    <option>Toulouse</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Col
              md="12"
              lg="12"
              className="d-flex justify-content-end no-margin no-padding"
            >
              <Button className="btn-success"> Enregistrer</Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Profile;
