import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { menuLandingClient } from "../../../utils/routes";
import NavTab from "../../navTab/navTab";
import Welcome from "../../common/welcome";
import "../landingPage.css";

class LandingClient extends Component {
  state = {
    welcome: true,
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ welcome: false });
    }, 2000);
  }
  render() {
    const { lng } = this.props.auth;
    return (
      <>
        {this.state.welcome && <Welcome data={this.props.auth.user.name} />}
        {!this.state.welcome && (
          <div>
            {/* <NavTab
              history={this.props.history}
              Menu={menuLandingClient(lng)}
              path="/client"
            ></NavTab> */}
            <div style={{ minHeight: "550px" }}>
              <Switch>
                {menuLandingClient(lng).map((route) => (
                  <Route
                    key={route.id}
                    exact
                    path={`/client${route.path}`}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps)(LandingClient);
