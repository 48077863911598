import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import scenarioReducer from "./scenarioReducer";

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    scenario: scenarioReducer
});
