import React from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { onGetScenarios } from "../../actions/scenarioActions"
import { Col, Row, Button, Label, Input, FormFeedback } from "reactstrap"
import Web from "../../assets/web.png"
import Produit from "../../assets/produit.png"
import Application from "../../assets/application.png"
import Service from "../../assets/service.png"
import Moderate from "../../assets/modéré.png"
import Reverse from "../../assets/reverse.png"
import Test from "../../assets/test.png"
import Mic from "../../assets/mic.png"
import Camera from "../../assets/camera.png"
import ABtest from "../../assets/abtest.png"
import { saveScenario } from '../../services/scenarioServices'
import { toast } from 'react-toastify';
import ScenarioSteps from './scenarioSteps'
import "./scenario.css"
class CreateScenario extends React.Component {
    state = {
        data: [],
        count: 0,
        currentScenario: {
            title: "",
            product: "",
            isUnique: "",
            isModerate: "",
            langue:"français"
        },
        error: false
    }
    componentDidMount() {
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
    }
    onChange = e => {
        e.preventDefault();
        this.setState({ currentScenario: { ...this.state.currentScenario, [e.target.name]: e.target.value }, error: false })
    };
    next = () => {
        if (this.state.currentScenario.title === "")
            this.setState({ error: true })
        else
            this.setState({ count: this.state.count + 1 })
    }
    submit = () => {
        saveScenario(this.state.currentScenario).then(res => {
            this.props.onGetScenarios()
            console.log(res);
           // toast.success('Scénario créé avec succès')
            this.props.history.push({
                pathname: '/client/createsteps',
                state: {
                    data: [],
                    scenarioId: res.data.id
                }
            })
        })
    }
    hundlestep=()=>{
        this.setState({count:0})
    }
    render() {
        const { count, error } = this.state
        const langue = ['anglais', 'français']
        const { title } = this.state.currentScenario
        const stepsName = ['Nom du scénario', 'Langue', 'Type de support', 'Nature du test', 'Format du test']
        return (<div className="create__scenario__ctn" >
            <Col md='3'>
                <ScenarioSteps
                 stepsName={stepsName}
                  count={count} 
                  hundlestep={this.hundlestep}
                  />
            </Col>
            <Col md='9'>
                {count === 0 && <div className='scenario__contain'>
                    <span className='title__steps2'>Donner un nom à votre scénario :</span>
                    <div className='display__flex__col__center'>
                        <div className='display__flex__col'>
                            <Input value={title}
                                type="text"
                                onChange={this.onChange}
                                name="title"
                                id="email__login"
                                invalid={error}
                            />
                            {error && <FormFeedback className='error1'>Ce champs est obligatoire</FormFeedback>}
                        </div>
                        <Button className="next" onClick={() => this.next()}>Suivant</Button>
                    </div>
                </div>}
                {count === 1 && <div className='scenario__contain'>
                    <span className='title__steps2'>Choisir la langue du test :</span>
                    <div className='display__flex__col__center'>
                        <div className='display__flex__col'>
                            <Input type="select" name="langue" id="email__login" onChange={this.onChange}>
                                {langue.map((scen, idx) => {
                                    return <option key={idx} value={scen} selected>{scen}</option>
                                })
                                }

                            </Input>
                        </div>
                        <Button className='next' onClick={() => this.next()}>Suivant</Button>
                    </div>
                </div>}
                {count === 2 && <div className='scenario__contain'>
                    <span className='title__steps2'>Quel type de support souhaitez-vous tester ?</span>
                    <div className='display__flex__col__center'>
                        <div className='display__flex__row'>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Site web" } })}>
                                <div className={`image_r2${(this.state.currentScenario.product === "Site web") ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px" }} src={Web} alt="" />
                                    <span className='type_panel'>Site web</span>
                                </div>
                            </div>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Application" } })}>
                                <div className={`image_r2${(this.state.currentScenario.product === "Application") ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px" }} src={Application} alt="" />
                                    <span className='type_panel'>Application</span>
                                </div>
                            </div>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Produit" } })}>
                                <div className={`image_r2${(this.state.currentScenario.product === "Produit") ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px" }} src={Produit} alt="" />
                                    <span className='type_panel'>Produit</span>
                                </div>
                            </div>
                            <div className='container__img2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Service" } })}>
                                <div className={`image_r2${(this.state.currentScenario.product === "Service") ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px" }} src={Service} alt="" />
                                    <span className='type_panel'>Service</span>
                                </div>
                            </div>
                        </div>
                        <Button className='next' onClick={() => this.next()}>Suivant</Button>
                    </div>
                </div>}
                {count === 3 && <div className='scenario__contain'>
                    <span className='title__steps2'>Avec quelle modalité de test ?</span>
                    <div className='display__flex__col__center'>
                        <div className='display__flex__row'>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isUnique: true } })}>
                                <div className={`image_r2${(this.state.currentScenario.isUnique === true) ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px", margin: "2px 0 24px 0" }} src={Test} alt="" />
                                    <span className='type_panel'>Un test unique</span>
                                </div>
                            </div>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isUnique: false } })}>
                                <div className={`image_r2${(this.state.currentScenario.isUnique === false) ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px" }} src={ABtest} alt="" />
                                    <span className='type_panel'>2 versions</span>
                                    <span className='type_panel'>
                                        Logique A/B Testing</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Produit" } })}>
                            <div className={`image_r2${(this.state.currentScenario.product === "Produit") ? '_selected' : ''}`}>
                                <img style={{ width: "54px", height: "54px" }} src={Reverse} alt="" />
                                <span className='type_panel'>Reverse
                                    Personae</span>
                            </div>
                        </div> */}
                        <Button className='next' onClick={() => this.next()}>Suivant</Button>
                    </div>
                </div>}
                {count === 4 && <div className='scenario__contain'>
                    <span className='title__steps2'>Avec quel niveau d’interaction ? </span>
                    <div className='display__flex__col__center'>
                        <div className='display__flex__row'>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isModerate: false } })}>
                                <div className={`image_r2${(this.state.currentScenario.isModerate === false) ? '_selected' : ''}`} >
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "13px", marginTop: "21px"}}>
                                        <img style={{ width: "27px", height: "27px" }} src={Mic} alt="" />
                                        <img style={{ width: "27px", height: "27px" }} src={Camera} alt="" />
                                    </div>
                                    <span className='type_panel'>Non modéré
                                        Enregistrement </span>
                                </div>
                            </div>
                            <div className='container__img2 mr-2' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isModerate: true } })}>
                                <div className={`image_r2${(this.state.currentScenario.isModerate === true) ? '_selected' : ''}`}>
                                    <img style={{ width: "54px", height: "54px" }} src={Moderate} alt="" />
                                    <span className='type_panel2'>Modéré En direct</span>
                                </div>
                            </div>
                        </div>
                        <Button className='finish' onClick={() => this.submit()}>Créer le scenario</Button>
                    </div>
                </div>}
            </Col>
            {/* <span className="title__style">Creation de scénario</span>
            <Row className='row__create_sc'>
                <Col className='col__create__sc'>PRODUIT</Col>
                <Col className='col__create__sc'>
                    <div className='row__imgs' >
                        <div className='container__img' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Site web" } })}>
                            <div className={`image_r${(this.state.currentScenario.product === "Site web") ? '_selected' : ''}`}>
                                <img style={{ width: "99px", height: "100px" }} src={Desktop} alt="" />
                            </div>
                        </div>
                        {(this.state.currentScenario.product === "Site web") && <div className='check'>
                            <img style={{ width: "80px" }} src={CheckIcon} alt="" />
                        </div>}

                    </div>
                </Col>
                <Col className='col__create__sc'>
                    <div className='row__imgs' >
                        <div className='container__img' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, product: "Application" } })}>
                            <div className={`image_r${(this.state.currentScenario.product === "Application") ? '_selected' : ''}`}>
                                <img style={{ width: "99px", height: "100px" }} src={Mobile} alt="" />
                            </div>
                        </div>
                        {(this.state.currentScenario.product === "Application") && <div className='check'>
                            <img style={{ width: "80px" }} src={CheckIcon} alt="" />
                        </div>}
                    </div>
                </Col>
            </Row> */}
            {/* <hr className="line__sc" />
            <Row className='row__create_sc'>
                <Col className='col__create__sc'>NATURE DU TEST</Col>
                <Col className='col__create__sc'>
                    <div className='row__imgs' >
                        <div className='container__img' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isUnique: true } })}>
                            <div className={`image_r${(this.state.currentScenario.isUnique === true) ? '_selected' : ''}`}>
                                <img style={{ width: "99px", height: "100px" }} src={TestUnique} alt="" />
                            </div>
                        </div>
                        {(this.state.currentScenario.isUnique === true) && <div className='check'>
                            <img style={{ width: "80px" }} src={CheckIcon} alt="" />
                        </div>}
                    </div>
                </Col>
                <Col className='col__create__sc'>
                    <div className='row__imgs' >
                        <div className='container__img' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isUnique: false } })}>
                            <div className={`image_r${(this.state.currentScenario.isUnique === false) ? '_selected' : ''}`}>
                                <img style={{ width: "99px", height: "100px" }} src={ABTesting} alt="" />
                            </div>
                        </div>
                        {(this.state.currentScenario.isUnique === false) && <div className='check'>
                            <img style={{ width: "80px" }} src={CheckIcon} alt="" />
                        </div>}
                    </div>
                </Col>
            </Row>
            <hr className="line__sc" />
            <Row className='row__create_sc'>
                <Col className='col__create__sc'>MODERATION</Col>
                <Col className='col__create__sc'>
                    <div className='row__imgs' >
                        <div className='container__img' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isModerate: true } })}>
                            <div className={`image_r${(this.state.currentScenario.isModerate === true) ? '_selected' : ''}`}>
                                <img style={{ width: "99px", height: "100px" }} src={Moderate} alt="" />
                            </div>
                        </div>
                        {(this.state.currentScenario.isModerate === true) && <div className='check'>
                            <img style={{ width: "80px" }} src={CheckIcon} alt="" />
                        </div>}
                    </div>
                </Col>
                <Col className='col__create__sc'>
                    <div className='row__imgs' >
                        <div className='container__img' onClick={() => this.setState({ currentScenario: { ...this.state.currentScenario, isModerate: false } })}>
                            <div className={`image_r${(this.state.currentScenario.isModerate === false) ? '_selected' : ''}`}>
                                <img style={{ width: "99px", height: "100px" }} src={NonModerate} alt="" />
                            </div>
                        </div>
                        {(this.state.currentScenario.isModerate === false) && <div className='check'>
                            <img style={{ width: "80px" }} src={CheckIcon} alt="" />
                        </div>}
                    </div>
                </Col>
            </Row>
            <hr className="line__sc" />
            <Row className='row__title__sc'>
                <Col className='col__create__sc' md='2'>
                    <Label>Résumé du scénario à créer:</Label>
                </Col>
                <Col md='3'>
                    {(this.state.currentScenario.product !== "" && this.state.currentScenario.isUnique !== "" && this.state.currentScenario.isModerate !== "") && <Label
                        style={{ color: '#00a359' }}>
                        {this.state.currentScenario.product}/{this.state.currentScenario.isUnique === true ? "Unique" : "A-B Testing"}/{this.state.currentScenario.isModerate === true ? 'Modéré' : 'Non modéré'}
                    </Label>}
                </Col>
                <Col md='5'>
                </Col>
            </Row>
            <AvForm onValidSubmit={this.submit}>
                <Row className='row__title__sc'>
                    <Col className='col__create__sc' md='2'>
                        <Label>Nom du scénario:</Label>
                    </Col>
                    <Col md='3'>
                        <AvField
                            placeholder='Nom de scénario'
                            value={title}
                            type="text"
                            onChange={this.onChange}
                            name="title"
                            id="email__login"
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },
                            }}
                        />
                    </Col>
                    <Col md='5'>
                    </Col>
                </Row>
                <Row className='row__create_sc'>
                    <Button className='signup__button' > Créer le scenario</Button>
                </Row>
            </AvForm> */}
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    { onGetScenarios }
)(CreateScenario);