import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../../services/authServices";
import { getAuthorization } from "../../../../services/userServices";
import http from "../../../../services/httpServices";
import { apiUrl } from "../../../../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
function SitProf() {
  const [user, setUser] = React.useState({});
  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);
  return (
    <Col lg="7" md="7" className="p-3 main">
      <Form>
        <Row>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
                Quel est votre niveau d’étude ?{" "}
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Aucun diplôme</option>
                <option>BEP ou autre</option>
                <option>Brevet professionnele</option>
                <option>Bac+2</option>
                <option>Bac+3</option>
                <option>Bac+5 </option>
              </Input>
            </div>
          </Col>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>Secteur d'activité</Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Transport</option>
                <option>Loisir</option>
                <option>Santé</option>
              </Input>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
                Votre département au sein de votre société ?{" "}
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Recherche et développement</option>
                <option>Conception</option>
                <option>Communication</option>
                <option>Production et Fabrication</option>
                <option>Management </option>
                <option>Distribution</option>
                <option>Finance</option>
                <option>Achat et Approvisionnement</option>
                <option>Ressources Humaines</option>
                <option>Contrôle et qualité </option>

                <option>Logistique et Transport </option>
                <option>Vente</option>
                <option>Communication</option>
                <option>Informatique</option>
                <option>Infrastructure et Sécurité </option>
                <option>Service à la clientèle</option>
                <option>Direction et Stratégie</option>
                <option>Juridique</option>
                <option>Marketing</option>
                <option>Comptabilité </option>
              </Input>
            </div>
          </Col>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
                Quel est votre revenu net par mois
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Moins de 800€</option>
                <option>Entre 800 et 1500€</option>
                <option>Entre 1501 et 2000€</option>
                <option>Entre 2001€ et 3500€</option>
                <option>Entre 3501€ et 5000€</option>
                <option>Plus de 5000€</option>
              </Input>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
                Quel est l'intitulé de votre poste ?{" "}
              </Label>
              <Input placeholder={"L'intitulé du poste"} />
            </div>
          </Col>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
                Avez-vous une fonction managériale ?{" "}
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Indépendant</option>
                <option>Assistant de direction</option>
                <option>Pas de fonction managériale</option>
                <option>Management d’équipe</option>
                <option>Responsable fonctionnel</option>
                <option>Direction générale</option>
                <option>Non concerné</option>
                <option>Direction de pôle</option>
              </Input>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Col lg="6">
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
                Quelle est la taille de votre société ?
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Moins de 10 salariés</option>
                <option>Entre 10 et 49 salariés</option>
                <option>Entre 50 et 99 salariés</option>
                <option>Entre 100 et 249 salariés</option>
                <option>Entre 250 et 499 salariés</option>
                <option>Entre 500 et 999 salariés</option>
                <option>Entre 100 et 4999 salariés</option>
                <option> Plus de 5000 salariés</option>
              </Input>
            </div>
          </Col>
        </Row>

        <Col
          md="12"
          lg="12"
          className="d-flex justify-content-end no-margin no-padding p-3 m-3"
        >
          <Button className="btn-success"> ENREGISTRER</Button>
        </Col>
      </Form>
    </Col>
  );
}

export default SitProf;
