import React from "react";
import { Button, Row, Input, Label, Col } from "reactstrap";
import Draggable from "react-draggable";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import data from "./data.json";
import upArrow from "../../../assets/upArrow.png";
import downArrow from "../../../assets/downArrow.png";
import axios from "axios";
import { getAuthorization } from "../../../services/userServices";
import { apiUrl } from "../../../config.json";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ModalQuiz from "./ModalQuiz";
import RangeSlider from "react-bootstrap-range-slider";
import Recorder from "../../common/recorder";

const apiEndpoint = apiUrl;

const NumericScale = ({
  minRange,
  maxRange,
  setPicked,
  picked,
  handleChange,
}) => {
  var values = [];
  let i = 1;
  while (i < 6) {
    values.push(i++);
  }
  return values.map((v) => {
    return (
      <div className="valuesScaleContainer">
        <div
          onClick={() => {
            setPicked(v);
            handleChange({ key: "answer", value: v.toString() });
          }}
          style={
            picked !== v
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 5,
                  width: 30,
                  borderRadius: 15,
                  backgroundColor: "#F5F5F5",
                  marginLeft: 3,
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 5,
                  width: 30,
                  borderRadius: 15,
                  backgroundColor: "green",
                  marginLeft: 3,
                }
          }
        >
          <span
            style={
              picked !== v
                ? { fontSize: 20, fontWeight: "bold", color: "green" }
                : { fontSize: 20, fontWeight: "bold", color: "white" }
            }
          >
            {v}
          </span>
        </div>
      </div>
    );
  });
};

const QuestionItemSingleChoice = ({
  question,
  currentResponse,
  responses,
  id,
  handleChange,
  questionChoices,
  isChecked,
  setIsDeveloped,
  isDeveloped,
  number,
}) => {
  const values = Object.values(
    Object.keys(questionChoices)
      ?.filter((key) => key.startsWith("choice"))
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: questionChoices[key],
        };
      }, {})
  ).filter(Boolean);
  return (
    <Col className="mt-1">
      <div className="question-container">
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: 350,
            }}
          >
            <div style={{ display: "flex", flex: 1, width: "100%" }}>
              <span className="question-typo">{question}</span>
            </div>
            <div
              style={{
                display: "flex",
                right: 10,
                width: 10,
              }}
              onClick={() => setIsDeveloped(!isDeveloped)}
            >
              <img
                style={{
                  height: 30,
                  width: 30,
                  resizeMode: "contain",
                }}
                src={isDeveloped ? upArrow : downArrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {isDeveloped && (
        <div>
          {values?.map((response) => {
            return (
              <div className="d-flex align-items-center mt-1">
                <div style={{ display: "flex", width: 390 }}>
                  <Radio
                    checked={currentResponse?.answer === response}
                    onChange={() =>
                      handleChange({
                        key: "answer",
                        value: response,
                      })
                    }
                    style={{
                      color: "green",
                    }}
                    value="a"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <span className="ml-2">{response}</span>
                </div>
              </div>
            );
          })}
          <div className="column-left-comment ">
            <Label className="questions-typo ">Commentaire</Label>
            <Input
              placeholder={"Commentaire"}
              value={currentResponse?.comment}
              onChange={(e) =>
                handleChange({ key: "comment", value: e?.target?.value })
              }
            />
          </div>
        </div>
      )}
    </Col>
  );
};

const OpenQuestion = ({
  id,
  question,
  questionChoices,
  handleChange,
  isDeveloped,
  setIsDeveloped,
  isChecked,
  currentResponse,
  number,
}) => {
  return (
    <Col className="mt-1">
      <div className="question-container">
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: 350,
            }}
          >
            <div style={{ display: "flex", flex: 1, width: "100%" }}>
              <span className="question-typo">{question}</span>
            </div>
            <div
              style={{
                display: "flex",
                right: 10,
                width: 10,
              }}
              onClick={() => setIsDeveloped(!isDeveloped)}
            >
              <img
                style={{
                  height: 30,
                  width: 30,
                  resizeMode: "contain",
                }}
                src={isDeveloped ? upArrow : downArrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {isDeveloped && (
        <div>
          <div className="column-left-comment-open ">
            <Label className="questions-typo ">Réponse</Label>
            <Input
              placeholder={"Réponse"}
              name="answer"
              value={currentResponse?.answer}
              onBlur={(e) =>
                handleChange({ key: "answer", value: e?.target?.value })
              }
            />
            <Label className="questions-typo ">Commentaire</Label>
            <Input
              placeholder={"Commentaire"}
              name="comment"
              //value={currentResponse?.comment}
              onBlur={(e) =>
                handleChange({ key: "comment", value: e?.target?.value })
              }
            />
          </div>
        </div>
      )}
    </Col>
  );
};

const ScaleQuestion = ({
  id,
  question,
  number,
  questionChoices,
  handleChange,
  isDeveloped,
  setIsDeveloped,
  value,
  setValue,
  isChecked,
  currentResponse,
  setPicked,
  picked,
}) => {
  const values = Object.values(
    Object.keys(questionChoices)
      ?.filter((key) => key.startsWith("max") || key.startsWith("min"))
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: questionChoices[key],
        };
      }, {})
  ).filter(Boolean);
  return (
    <Col className="mt-1">
      <div className="question-container">
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: 350,
            }}
          >
            <div style={{ display: "flex", flex: 1, width: "100%" }}>
              <span className="question-typo">{question}</span>
            </div>
            <div
              style={{
                display: "flex",
                right: 10,
                width: 10,
              }}
              onClick={() => setIsDeveloped(!isDeveloped)}
            >
              <img
                style={{
                  height: 30,
                  width: 30,
                  resizeMode: "contain",
                }}
                src={isDeveloped ? upArrow : downArrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {isDeveloped && (
        <div>
          <div className="column-left-comment-open ">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 10, fontWeight: "bold" }}>Mauvais</span>
              <NumericScale
                setPicked={setPicked}
                picked={picked}
                handleChange={handleChange}
              />
              <span style={{ fontSize: 10, fontWeight: "bold" }}>
                Excellent
              </span>
            </div>

            <Label className="questions-typo ">Commentaire</Label>
            <Input
              placeholder={"Commentaire"}
              name="comment"
              //value={currentResponse?.comment}
              onBlur={(e) =>
                handleChange({ key: "comment", value: e?.target?.value })
              }
            />
          </div>
        </div>
      )}
    </Col>
  );
};

const Questions = ({ visible, handleClose, match, history }) => {
  const [responses, setResponses] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [currentResponse, setCurrentResponse] = React.useState({
    answer: "",
    comment: "",
  });
  const [dispoUrl, setDispoUrl] = React.useState(null);
  const [indice, setIndice] = React.useState(0);
  const [isDeveloped, setIsDeveloped] = React.useState(true);
  const [scenarios, setScenario] = React.useState([]);
  const [_id, setId] = React.useState();
  const [picked, setPicked] = React.useState("");
  const [stop, setStop] = React.useState(false);
  const [testId, setTestId] = React.useState("");

  async function getData() {
    axios
      .get(apiEndpoint + `/api/scenario/${match?.params?.id}`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        console.log("gggggggggggggggggggggggggggggggggggggggggggggg", response);
        setScenario(response?.data?.steps);
        setDispoUrl("response?.data?.steps[0]");
        setId(match?.params?.id);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }
  React.useEffect(async () => {
    getData();
  }, []);
  function handleChangeResponse({ key, value }) {
    setCurrentResponse({
      ...currentResponse,
      [key]: value,
    });
  }
  function isChecked({ questionNum, isMultipleResponse, response }) {
    const exist = responses?.find((res) => res?.questionNum == questionNum);
    if (!exist) return false;
    if (!isMultipleResponse) {
      return exist?.response === response;
    }
    return exist?.response?.some((r) => r === response);
  }
  const question = scenarios[indice];
  const handleNextQuestion = () => {
    let now = indice + 1;
    setIndice(now);
    if (scenarios[indice]?.url) {
      setDispoUrl(getUrl(scenarios[indice]?.url));
    }
    setResponses([...responses, currentResponse]);

    setCurrentResponse({});

    if (now >= scenarios?.length) {
      console.log({
        state: "finished",
        answers: [...responses, currentResponse],
      });
      axios
        .post(
          apiEndpoint + `/api/tests/scenario/${_id}`,
          { state: "finished", answers: [...responses, currentResponse] },
          {
            headers: getAuthorization(),
          }
        )
        .then((httpResponse) => {
          // api video*
          setTestId(httpResponse?.data?.testId);
          setStop(!stop);
          // history.push(`/tester/dashboard`);
          toast.success("Test ajouté avec succées.");
        })
        .catch((err) => {
          setStop(!stop);
          console.log(err, "errrrrr");
        });
    }
  };
  function getButtonText() {
    return indice + 1 < scenarios?.length ? "Suivant" : "Terminer";
  }
  const getUrl = (url = null) => {
    if (!url) return null;
    if (url && url?.indexOf("http") !== -1) {
      return url;
    }
    return "http://" + url;
  };
  return (
    <>
      <div style={{ backgroundColor: "white", borderRadius: 10 }}>
        {question && (
          <Draggable>
            <div className="quiz">
              <Row>
                <Col md="12" lg="12" className="questions">
                  <div style={{ width: 1000 }}>
                    {question?.type === "open" ? (
                      <OpenQuestion
                        {...question}
                        isChecked={isChecked}
                        handleChange={handleChangeResponse}
                        setIsDeveloped={setIsDeveloped}
                        isDeveloped={isDeveloped}
                        key={indice}
                      />
                    ) : question?.type === "close" ? (
                      <QuestionItemSingleChoice
                        {...question}
                        isChecked={isChecked}
                        handleChange={handleChangeResponse}
                        setIsDeveloped={setIsDeveloped}
                        isDeveloped={isDeveloped}
                        currentResponse={currentResponse}
                        key={indice}
                      />
                    ) : question?.type === "scale" ? (
                      <ScaleQuestion
                        {...question}
                        isChecked={isChecked}
                        value={value}
                        setValue={setValue}
                        handleChange={handleChangeResponse}
                        setIsDeveloped={setIsDeveloped}
                        isDeveloped={isDeveloped}
                        currentResponse={currentResponse}
                        key={indice}
                        setPicked={setPicked}
                        picked={picked}
                      />
                    ) : null}
                  </div>
                  <div style={{ display: "flex", width: "70%" }}>
                    <Col
                      style={{ width: 40, height: 50, alignItems: "flex-end" }}
                    >
                      <Button color="success" onClick={handleNextQuestion}>
                        {getButtonText()}
                      </Button>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
          </Draggable>
        )}
        <ModalQuiz />
        <Recorder stop={stop} id={testId} />
        <iframe
          src={getUrl(question?.url) || dispoUrl}
          className="iframe"
        ></iframe>
      </div>
    </>
  );
};
export default Questions;
