import {
    SUCCESS_SAVE_TESTER,
    FAILED_SAVE_TESTER,
    SUCCESS_SAVE_CLIENT,
    FAILED_SAVE_CLIENT,
    SUCCESS_GET_USER_CONNECTED,
    FAILED_GET_USER_CONNECTED,
    SUCCESS_GET_CLIENTS,
    FAILED_GET_CLIENTS,
    SUCCESS_GET_ADMINS,
    FAILED_GET_ADMINS,
    FAILED_SAVE_ADMIN,
    SUCCESS_GET_TESTERS,
    FAILED_GET_TESTERS,
    FAILED_GET_SUBCLIENTS,
    SUCCESS_GET_SUBCLIENTS,
    FAILED_SAVE_SUBCLIENT
} from "../typeActions/user";

const INITIAL_STATE = {
    user: {},
    users:[],
    admins:[],
    testers:[],
    subClients:[],
    error:"",
    errorTest: "",
    errorClient: "",
    errorAdmin: "",
    errorSubClient:""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUCCESS_SAVE_TESTER:
            return {
                ...state,
                user: action.user,
                errorTest: ""
            };
        case SUCCESS_SAVE_CLIENT:
            return {
                ...state,
                user: action.user, 
                errorClient: ""
            };
        case SUCCESS_GET_ADMINS:
            return {
                ...state,
                admins: action.admins, 
                errorAdmin: ""
            };
        case SUCCESS_GET_TESTERS:
            return {
                ...state,
                testers: action.testers, 
                errorAdmin: ""
            };
        case SUCCESS_GET_SUBCLIENTS:
            return {
                ...state,
                subClients: action.subClients, 
                errorAdmin: ""
            };
        case SUCCESS_GET_USER_CONNECTED:
            return {
                ...state,
                user: action.user
            };
        case SUCCESS_GET_CLIENTS:
            return {
                ...state,
                users: action.users
            };
        case FAILED_SAVE_TESTER:
            return {
                ...state,
                errorTest: action.payload
            };
        case FAILED_SAVE_ADMIN:
            return {
                ...state,
                errorAdmin: action.payload
            };
        case FAILED_SAVE_CLIENT:
            return {
                ...state,
                errorClient: action.payload
            };
        case FAILED_SAVE_SUBCLIENT:
            return {
                ...state,
                errorSubClient: action.payload
            };
        case FAILED_GET_CLIENTS:
            return {
                ...state,
                errorClient: action.payload
            };
        case FAILED_GET_USER_CONNECTED:
            return {
                ...state,
                error: action.payload
            };
        case FAILED_GET_SUBCLIENTS:
            return {
                ...state,
                error: action.payload
            };
        case FAILED_GET_ADMINS:
            return {
                ...state,
                error: action.payload
            };
        case FAILED_GET_TESTERS:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};
