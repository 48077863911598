import React from "react";
import {
  ModalHeader, Modal, ModalBody
} from 'reactstrap';
import '../register/client/register.css'

const Modals = (props) => {
  const { show, toggleShow, header, modalSize, style } = props
  return (
    <Modal
      style={{ minWidth: style ? style : "" }}
      className={modalSize ? modalSize : ""}
      isOpen={show}
      toggle={() => toggleShow()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader  toggle={() => toggleShow()}>{header}</ModalHeader>
      <ModalBody>
        {props.children}
      </ModalBody>
    </Modal>
  )
}
export default Modals;