import React from "react";

function ScaleComponentByTester(props) {
  const [max, setMax] = React.useState(null);
  const [selectedAnswer, setSelectedAnswer] = React.useState(null);
  React.useEffect(() => {
    setMax(props?.ids?.questionChoices?.maxScale);
    setSelectedAnswer(props?.answer);
  }, []);

  const CreateList = () => {
    var values = [];
    let i = 0;
    while (i <= max) {
      values.push(i++);
    }
    return values.map((v) => {
      return (
        <div
          style={
            v == props?.answer
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 5,
                  width: 30,
                  borderRadius: 15,
                  backgroundColor: "#17A25C",

                  marginLeft: 3,
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 5,
                  width: 30,
                  borderRadius: 15,
                  backgroundColor: "#F5F5F5",
                  marginLeft: 3,
                }
          }
        >
          <span
            style={
              v == props?.answer
                ? { fontSize: 20, fontWeight: "bold", color: "white" }
                : { fontSize: 20, fontWeight: "bold", color: "#17A25C" }
            }
          >
            {v}{" "}
          </span>
        </div>
      );
    });
  };

  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 10 }}>
          <span className="top-component-fine-black-style">
            {props?.ids?.question}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div>
          <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
            Question à échelle de notation
          </span>
        </div>
      </div>
      <div className="scale-tester-array">
        <span style={{ fontSize: 10, fontWeight: "bold" }}>Mauvais</span>
        <CreateList />
        <span style={{ fontSize: 10, fontWeight: "bold" }}>Excellent</span>
      </div>
    </div>
  );
}

export default ScaleComponentByTester;
