import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  onEmailChange,
  onPasswordChange,
  onLoginUser,
} from "../../actions/authActions";
import { getUserConnected } from "./../../actions/userActions";
import { Input, Button, FormFeedback } from "reactstrap";
import "./login.css";
import Toast from "../common/Toast";
import lng from "./login.json";
import { Link } from "react-router-dom";
//import { hideScreenLoader } from '../../utils/theme';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lngActive: this.props.auth.lng,
      loading: false,
      error: "",
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isAuthenticated !== nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    if (nextProps.auth.lng !== this.state.lngActive) {
      this.setState({ lngActive: nextProps.auth.lng });
    }
  }

  onchangeEmail = (e) => {
    e.preventDefault();
    this.props.onEmailChange(e.target.value);
  };

  onchangePassword = (e) => {
    e.preventDefault();
    this.props.onPasswordChange(e.target.value);
  };

  onSubmit = () => {
    const { username, password } = this.props.auth;
    this.setState({ loading: true });
    this.props.onLoginUser(username, password, this.state.rememberMe);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    //  hideScreenLoader(true);
    const { loading, lngActive } = this.state;
    const loginText = lng[lngActive].login;
    const email = lng[lngActive].email;
    const passwordText = lng[lngActive].password;
    const forgetText = lng[lngActive].forgetText;
    const createText = lng[lngActive].createText;
    const { username, password, error } = this.props.auth;
    return (
      <div className="ctn__login">
        <div className="form__login">
          <span id="header__login">{lng[lngActive].header}</span>
          <span id="header__login">Insight Data</span>
          <hr className="line" />
          <div className="form__login">
            <Input
              style={{ marginBottom: "1.2em" }}
              placeholder={email}
              value={username}
              type="email"
              onChange={this.onchangeEmail}
              name="username"
              id="email__login"
              invalid={error ? true : false}
            />
            {error && (
              <FormFeedback id="feed">{lng[lngActive].error_msg}</FormFeedback>
            )}
            <Input
              style={{ marginBottom: "1.2em" }}
              placeholder={passwordText}
              type="password"
              onChange={this.onchangePassword}
              name="password"
              id="password__login"
              value={password}
              invalid={error ? true : false}
            />
            {error && (
              <FormFeedback id="feed">{lng[lngActive].error_msg}</FormFeedback>
            )}

            <Button
              className="login__button"
              onClick={() => {
                this.onSubmit();
              }}
              disabled={
                !(this.props.auth.username && this.props.auth.password) ||
                loading
              }
            >
              {loading && <i className="fa fa-refresh fa-spin mr-2" />}
              {loginText}
            </Button>

            <a className="forget__password" href="">
              {forgetText}
            </a>
          </div>
          {/* <div className="footer__login">
            <Link className="forget__password" to="/Stripe">
              {createText}
            </Link>
            <span id="text__footer">{lng[lngActive].footer1} <a href="">{lng[lngActive].footer2}</a> <span>{lng[lngActive].footer3}</span> </span>
          </div> */}
        </div>
        <Toast />
      </div>
    );
  }
}

LoginForm.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

export default connect(mapStateToProps, {
  onEmailChange,
  onPasswordChange,
  onLoginUser,
  getUserConnected,
})(LoginForm);
