import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../../services/authServices";
import { getAuthorization } from "../../../../services/userServices";
import http from "../../../../services/httpServices";
import { apiUrl } from "../../../../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
function ActL() {
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);
  return (
    <Col lg="7" md="7" className="p-3 main">
      <Form>
        <Row>
        <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
              Quels sont vos centres d'intérêts ? </Label>
              <Input type="select" name="select" id="exampleSelect">
              <option>Art et culture</option>
                <option>Communication & Média</option>
                <option>Écologie et développement durable</option>
                <option>Nouvelles technologies </option>
                <option>Musique</option>
              </Input>
            </div>
          </Col>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>Quelles activités pratiquez-vous ? </Label>
              <Input type="select" name="select" id="exampleSelect">
              <option>Activité manuelle</option>
                <option>Concert, Théâtre </option>
                <option>Photo ou vidéo</option>
                <option>Activités nautiques </option>
                <option>Jardinage, Bricolage</option>
              </Input>
            </div>
          </Col>
        </Row>
        <Col
          md="12"
          lg="12"
          className="d-flex justify-content-end no-margin no-padding p-3 m-3"
        >
          <Button className="btn-success"> ENREGISTRER</Button>
        </Col>
      </Form>
    </Col>
  );
}

export default ActL;
