import React from "react";
import { Label } from "reactstrap";
import MaterialTable from "material-table";
const Table = (props) => {
    const { data, title, columns, actions } = props
    return (
        <MaterialTable
            style={{
                backgroundColor: "white", border: "none ", boxShadow: "none", borderCollapse: "separate", borderSpacing: "0 15px"
            }}
            title={<Label style={{ fontSize: "1.1em", color: "#00a359", fontWeight: "500", fontSize:'20px', fontFamily:'sans-serif' }}>{title}</Label>}
            columns={columns}
            data={
                data
            }
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'lignes',
                    labelRowsPerPage: 'lignes par page:',
                    firstAriaLabel: 'Première page',
                    firstTooltip: 'Première page',
                    previousAriaLabel: 'Page précédente',
                    previousTooltip: 'Page précédente',
                    nextAriaLabel: 'Page suivante',
                    nextTooltip: 'Page suivante',
                    lastAriaLabel: 'Dernière page',
                    lastTooltip: 'Dernière page'
                },
            }}
        
            options={{
                pageSize:8,
                pageSizeOptions:[8,16,24],
                actionsColumnIndex: -1,
                cellStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                },
                headerStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                    backgroundColor:"#f3fbf7",
                },

        searchFieldStyle: {
          color: "#00a359",
          MuiInputUnderline: "none",
        },
      }}
    />
  );
};
export default Table;
