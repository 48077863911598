import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Collapse, Navbar, NavbarToggler, Button, NavbarBrand,
    Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import authServices from '../../services/authServices';
import { authRoutes, Routes } from '../../utils/routes';
import Modals from '../common/modal'
import ClientRegisterForm from '../register/client/register'
import { onSaveClient, onSaveTester } from "../../actions/userActions"
import Lang from "../../utils/lang"
import lng from "../../utils/utils.json"
import { toast } from 'react-toastify';
import Logo from '../../assets/logo-vector.svg'
import ProfileIcon from '../../assets/insightdata_profil.svg'
import "./navbar.css";
import TesterRegisterForm from '../register/tester/registerForm';


class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lngActive: this.props.auth.lng,
            isSelected: null,
            count: 0,
            page: '1',
            show: false,
            show2: false,
            loading: false,
            currentUser: {
                name: "",
                lastname: "",
                useCase: "",
                sector: "",
                profession: "",
                email: "",
                phone: "",
                nbEmployees: "",
                company: ""

            },
            currentTester: {
                name: "",
                lastname: "",
                dateOfBirth: "",
                gender: "",
                maritalStatus: "",
                email: "",
                phone: "",
                country: "",
                studyLevel: "",
                socialMedia: "",
                os: "",
                csp: ""

            },
            userLabel: {
                name: "Prénom",
                lastname: "Nom",
                email: "Email",
                useCase: "Cas d'utilisastion",
                sector: "Secteur",
                profession: "Fonction",
                phone: "Téléphone",
                nbEmployees: "Nombre d'employés",
                company: "Société",

            },
            testerText: {
                name: "Prénom",
                lastname: "Nom",
                email: "Email",
                age: "Age",
                csp: "CSP",
                situation: "Situation familiale",
                os: "OS",
                gender: "Genre",
                pays: "Pays de résidence",
                niveau: "Niveau d'études",
                reseau: "Réseaux Sociaux",
                phone: "Télephone",

            },
            errors: {},
            error: {},
        };
        this.onSubmit = this.onSubmit.bind(this)
        this.onSubmitTester = this.onSubmitTester.bind(this)
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.errorClient !== this.props.user.errorClient) {
            this.setState({ error: nextProps.user.errorClient && nextProps.user.errorClient.errors })
        }
        if (nextProps.user.errorTest !== this.props.user.errorTest) {
            this.setState({ error: nextProps.user.errorTest && nextProps.user.errorTest.errors })
        }
        if (nextProps.auth.lng !== this.state.lngActive) {
            this.setState({ lngActive: nextProps.auth.lng });
        }
    }
    toggleIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    onChangePage = () => {
        this.setState({
            page: '2',
        })
    };
    toggle = () => {
        this.setState({
            show: !this.state.show,
        })
       
        setTimeout(() => {
            this.setState({
                currentUser: {
                    name: "",
                    lastname: "",
                    useCase: "",
                    sector: "",
                    profession: "",
                    email: "",
                    phone: "",
                    nbEmployees: "",
                    company: ""

                },
                page:'1'
            })
        }, 500);
    }
    toggle2 = () => {
        this.setState({
            show2: !this.state.show2,
        })
       
        setTimeout(() => {
            this.setState({
                currentTester: {
                    name: "",
                    lastname: "",
                    dateOfBirth: "",
                    gender: "",
                    maritalStatus: "",
                    email: "",
                    phone: "",
                    country: "",
                    studyLevel: "",
                    socialMedia: "",
                    os: "",
                    csp: ""
    
                },
                
            })
        }, 500);
    }
    onChange = e => {
        e.preventDefault();
        this.setState({ currentUser: { ...this.state.currentUser, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({ currentTester: { ...this.state.currentTester, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };
    onChangePhone = value => {
        this.setState({ currentUser: { ...this.state.currentUser, phone: value } })
    };
    onChangePhone2 = value => {
        this.setState({ currentTester: { ...this.state.currentTester, phone: value } })
    };
    onSubmit() {
        const { name, lastname, useCase, nbEmployees,
            sector, profession, email, phone, company
        } = this.state.currentUser;
        const client = {
            name, lastname, useCase, nbEmployees,
            sector, profession, email, phone, company
        }
        this.setState({ loading: true })
        this.props.onSaveClient(client)
        setTimeout(() => {
            this.setState({ loading: false })
            !this.state.error && this.toggle()
            this.state.error ? toast.warning('Veillez vérifier vos champs') : toast.success('Merci de tenter l’aventure digitale avec Insight Data. Un membre de notre équipe va vous contacter très rapidement.')
        }, 2000);
    }
    renderModalSignup() {
        const { currentUser, userLabel, loading } = this.state
        const usecase = ["Entreprise: Projet Ponctuel", "Entreprise: Plusieurs projets à tester", "Agence ou cabinet de conseil", "Testeur"]
        const nbemployees = ["1-10", "11-50", "51-250", "+250"]
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Demander un essai'
            >
                <ClientRegisterForm
                    tog_standard={this.toggle}
                    confirmText='Demander un essai'
                    handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    nbemployees={nbemployees}
                    usecase={usecase}
                    currentUser={currentUser}
                    userText={userLabel}
                    loading={loading}
                    onChangePhone={this.onChangePhone}
                    error={this.state.error}
                    // page={this.state.page}
                    // onChangePage={this.onChangePage}
                />

            </Modals>
        )
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: !this.state[e.target.name] })
    }
    onSubmitTester() {
        const { name, lastname, gender, country,
            csp, studyLevel, os, maritalStatus,
            socialMedia, email, dateOfBirth, phone
        } = this.state.currentUser;
        const tester = {
            name, lastname, gender, country,
            csp, studyLevel, os
            // :this.state.selectedOption.map(res=> {return res.value})
            , maritalStatus,
            socialMedia, email, dateOfBirth, phone
        }
        this.setState({ loading: true })
        this.props.onSaveTester(tester)
        setTimeout(() => {
            this.setState({
                loading: false, currentUser: {
                    name: "",
                    lastname: "",
                    gender: "",
                    country: "",
                    csp: "",
                    studyLevel: "",
                    os: "",
                    maritalStatus: "",
                    socialMedia: "",
                    email: "",
                    phone: "",
                    dateOfBirth: ""
                }
            })
            this.state.error ? toast.warning('Veillez vérifier vos champs') : toast.success('Inscription effectuée avec succes Vous allez recevoir un mail vous expliquant la démarche de tests pour valider votre inscription ')
        }, 2000);
    }
    renderModalSignupTester() {
        const { currentTester, testerText, loading } = this.state
        const genders = ["Femme", "Homme"]
        const csp = ["Agriculteurs exploitants", "Artisans", " commerçants et chefs d’entreprise", "Cadres et professions intellectuelles supérieures, Professions Intermédiaires", "Employés", "Ouvriers"]
        const study = ["Brevet", "Bac", "Bac +2", "Bac +3/4", "Bac +5", "> Bac +5"]
        const martial = ["Célibataire", "Couple", "Famille sans enfants", "Famille avec enfants"]
         const os = ["IOS", "Android", "Windows", "Autre"]
        const media = ["Facebook", "Instagram", "WhatsApp", "Autre"]
        const pays = [
            "Afghanistan",
            "Afrique du Sud",
            "Albanie",
            "Algérie",
            "Allemagne",
            "Andorre",
            "Angola",
            "Anguilla",
            "Antarctique",
            "Antigua-et-Barbuda",
            "Antilles néerlandaises",
            "Arabie saoudite",
            "Argentine",
            "Arménie",
            "Aruba",
            "Australie",
            "Autriche",
            "Azerbaïdjan",
            "Bahamas",
            "Bahreïn",
            "Bangladesh",
            "Barbade",
            "Bélarus",
            "Belgique",
            "Belize",
            "Bénin",
            "Bermudes",
            "Bhoutan",
            "Bolivie",
            "Bosnie-Herzégovine",
            "Botswana",
            "Brésil",
            "Brunéi Darussalam",
            "Bulgarie",
            "Burkina Faso",
            "Burundi",
            "Cambodge",
            "Cameroun",
            "Canada",
            "Cap-Vert",
            "Ceuta et Melilla",
            "Chili",
            "Chine",
            "Chypre",
            "Colombie",
            "Comores",
            "Congo-Brazzaville",
            "Corée du Nord",
            "Corée du Sud",
            "Costa Rica",
            "Côte d’Ivoire",
            "Croatie",
            "Cuba",
            "Danemark",
            "Diego Garcia",
            "Djibouti",
            "Dominique",
            "Égypte",
            "El Salvador",
            "Émirats arabes unis",
            "Équateur",
            "Érythrée",
            "Espagne",
            "Estonie",
            "État de la Cité du Vatican",
            "États fédérés de Micronésie",
            "États-Unis",
            "Éthiopie",
            "Fidji",
            "Finlande",
            "France",
            "Gabon",
            "Gambie",
            "Géorgie",
            "Géorgie du Sud et les îles Sandwich du Sud",
            "Ghana",
            "Gibraltar",
            "Grèce",
            "Grenade",
            "Groenland",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernesey",
            "Guinée",
            "Guinée équatoriale",
            "Guinée-Bissau",
            "Guyana",
            "Guyane française",
            "Haïti",
            "Honduras",
            "Hongrie",
            "Île Bouvet",
            "Île Christmas",
            "Île Clipperton",
            "Île de l'Ascension",
            "Île de Man",
            "Île Norfolk",
            "Îles Åland",
            "Îles Caïmans",
            "Îles Canaries",
            "Îles Cocos - Keeling",
            "Îles Cook",
            "Îles Féroé",
            "Îles Heard et MacDonald",
            "Îles Malouines",
            "Îles Mariannes du Nord",
            "Îles Marshall",
            "Îles Mineures Éloignées des États-Unis",
            "Îles Salomon",
            "Îles Turks et Caïques",
            "Îles Vierges britanniques",
            "Îles Vierges des États-Unis",
            "Inde",
            "Indonésie",
            "Irak",
            "Iran",
            "Irlande",
            "Islande",
            "Israël",
            "Italie",
            "Jamaïque",
            "Japon",
            "Jersey",
            "Jordanie",
            "Kazakhstan",
            "Kenya",
            "Kirghizistan",
            "Kiribati",
            "Koweït",
            "Laos",
            "Lesotho",
            "Lettonie",
            "Liban",
            "Libéria",
            "Libye",
            "Liechtenstein",
            "Lituanie",
            "Luxembourg",
            "Macédoine",
            "Madagascar",
            "Malaisie",
            "Malawi",
            "Maldives",
            "Mali",
            "Malte",
            "Maroc",
            "Martinique",
            "Maurice",
            "Mauritanie",
            "Mayotte",
            "Mexique",
            "Moldavie",
            "Monaco",
            "Mongolie",
            "Monténégro",
            "Montserrat",
            "Mozambique",
            "Myanmar",
            "Namibie",
            "Nauru",
            "Népal",
            "Nicaragua",
            "Niger",
            "Nigéria",
            "Niue",
            "Norvège",
            "Nouvelle-Calédonie",
            "Nouvelle-Zélande",
            "Oman",
            "Ouganda",
            "Ouzbékistan",
            "Pakistan",
            "Palaos",
            "Panama",
            "Papouasie-Nouvelle-Guinée",
            "Paraguay",
            "Pays-Bas",
            "Pérou",
            "Philippines",
            "Pitcairn",
            "Pologne",
            "Polynésie française",
            "Porto Rico",
            "Portugal",
            "Qatar",
            "R.A.S. chinoise de Hong Kong",
            "R.A.S. chinoise de Macao",
            "régions éloignées de l’Océanie",
            "République centrafricaine",
            "République démocratique du Congo",
            "République dominicaine",
            "République tchèque",
            "Réunion",
            "Roumanie",
            "Royaume-Uni",
            "Russie",
            "Rwanda",
            "Sahara occidental",
            "Saint-Barthélémy",
            "Saint-Kitts-et-Nevis",
            "Saint-Marin",
            "Saint-Martin",
            "Saint-Pierre-et-Miquelon",
            "Saint-Vincent-et-les Grenadines",
            "Sainte-Hélène",
            "Sainte-Lucie",
            "Samoa",
            "Samoa américaines",
            "Sao Tomé-et-Principe",
            "Sénégal",
            "Serbie",
            "Serbie-et-Monténégro",
            "Seychelles",
            "Sierra Leone",
            "Singapour",
            "Slovaquie",
            "Slovénie",
            "Somalie",
            "Soudan",
            "Sri Lanka",
            "Suède",
            "Suisse",
            "Suriname",
            "Svalbard et Île Jan Mayen",
            "Swaziland",
            "Syrie",
            "Tadjikistan",
            "Taïwan",
            "Tanzanie",
            "Tchad",
            "Terres australes françaises",
            "Territoire britannique de l'océan Indien",
            "Territoire palestinien",
            "Thaïlande",
            "Timor oriental",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinité-et-Tobago",
            "Tristan da Cunha",
            "Tunisie",
            "Turkménistan",
            "Turquie",
            "Tuvalu",
            "Ukraine",
            "Union européenne",
            "Uruguay",
            "Vanuatu",
            "Venezuela",
            "Viêt Nam",
            "Wallis-et-Futuna",
            "Yémen",
            "Zambie",
            "Zimbabwe"]
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show2}
                toggleShow={this.toggle2}
                header='Devenir testeur'
            >
                <TesterRegisterForm
                    tog_standard={this.toggle2}
                    confirmText='Devenir testeur'
                    handleSubmit={this.onSubmitTester}
                    onchange={this.onChange}
                    currentTester={currentTester}
                    testerText={testerText}
                    loading={loading}
                    onChangePhone={this.onChangePhone2}
                    error={this.state.error}
                    csp={csp}
                    genders={genders}
                    os={os}
                    media={media}
                    handleChange={this.handleChange2}
                    martial={martial}
                    study={study}
                    pays={pays}
                    // page={this.state.page}
                    // onChangePage={this.onChangePage}
                />

            </Modals>
        )
    }
    handleChange2 = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    render() {
        const { count, lngActive } = this.state;
        const signupText = lng[lngActive].trial
        const authRoute = authRoutes()
        const initialRoute = Routes(lngActive)
        return (
            <div className="navbar__horizontal">
                {this.renderModalSignup()}
                {this.renderModalSignupTester()}
                <Navbar className="navBar__horizontal" light expand="md">
                    <NavbarBrand href="/">
                        <div className="">
                            <img
                                style={{ maxWidth: "170px" }}
                                src={Logo} alt="Logo" />
                        </div>
                    </NavbarBrand>

                    <NavbarToggler onClick={this.toggleIsOpen} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        {this.props.auth.isAuthenticated ? <Nav className="mr-auto" navbar>
                            {
                                authRoute.map((route, key) => {
                                    return (
                                        <div id="content__item__navbar" key={key}>
                                            <Link onClick={() => this.setState({ count: key })} to={route.path}>
                                                <div className={`item__nav__bar${(count === key) ? '__selected' : ''}`}>
                                                    <div className="item__header">
                                                        {route.Name}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}

                        </Nav> :
                            <Nav className='nav__bar' navbar>

                                {
                                    initialRoute.map((route, key) => {
                                        return (
                                            <div id="content__item__navbar" key={key}>
                                                <Link onClick={() => this.setState({ count: key })} to={route.path}>
                                                    <div className={`item__nav__bar${(count === key) ? '__selected' : ''}`}>
                                                        <div className="item__header">
                                                            {route.Name}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                <Button
                                    className="btn__sign"
                                    onClick={() => {
                                        this.toggle();
                                    }}
                                >
                                    {signupText}
                                </Button>
                                <Button
                                    className="btn__sign2"
                                    onClick={() => {
                                        this.toggle2();
                                    }}
                                >
                                    Devenir testeur
                                </Button>
                                <div id="content__item__navbar" >
                                    <div className="item__nav__bar_lang">
                                        <div className="item__header">
                                            <Lang />
                                        </div>
                                    </div>
                                </div>
                            </Nav>
                        }
                        {this.props.auth.isAuthenticated &&
                            <Nav className="ml-auto" navbar>
                                <UncontrolledDropdown direction="left" nav inNavbar>
                                    <DropdownToggle nav caret>
                                        <img style={{ width: '45px' }} src={ProfileIcon} alt="" />
                                    </DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem divider />
                                        <DropdownItem
                                            href="/"
                                            id="content__item__navbar"
                                            onClick={() => {
                                                authServices.logout();
                                            }}
                                        >
                                            <div className="log__out">
                                                <i className="fa fa-sign-out fa-lg" aria-hidden="true" />
                                                Logout
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        }
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = state => ({ user: state.user, auth: state.auth });

Navbar.propTypes = {
    user: PropTypes.object
};

export default connect(
    mapStateToProps, { onSaveClient,onSaveTester }
)(NavBar);
