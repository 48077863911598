import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { menuLandingTester } from "../../../utils/routes";
import NavTab from "../../navTab/navTab";
import "../landingPage.css";
import maleAvatar from "../../../assets/male-avatar.jpeg";
import bandeau from "../../../assets/bandeau.png";

import Modal from "./Modal";
import Header from "./Header";
import ModalTest from "./ModalTest";
import ModalForm from "./ModalForm";
import Questions from "./questions";

class LandingTester extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showBigHeader = (isHidden) => {
    this.setState({ isHidden: true });
    console.log(this.state.isHidden);
  };
  showSmallHeader = (isHidden) => {
    this.setState({ isHidden: false });
    console.log(this.state.isHidden);
  };
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  render() {
    const { lng } = this.props.auth;
    const location = this.props.location;
    const path = location?.pathname;
    console.log({ path });
    return (
      <React.Fragment>
        {!path.startsWith("/tester/questions") && <Header />}

        <div className="main">
          <div>
            {!path.startsWith("/tester/questions") && (
              <div style={{ width: "80%", height: 120 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <NavTab
                    history={this.props.history}
                    Menu={menuLandingTester(lng)}
                    path="/tester"
                  ></NavTab> */}
                </div>
              </div>
            )}

            {/* <Switch>
              <Route path={`/tester/questions`} component={Questions} />
              {menuLandingTester(lng).map((route) => (
                <Route
                  key={route.id}
                  path={`/tester${route.path}`}
                  component={route.component}
                />
              ))}
            </Switch> */}
            <Switch>
              <Route path={`/tester/questions/:id`} component={Questions} />
              {menuLandingTester(lng).map((route) => (
                <Route
                  key={route.id}
                  path={`/tester${route.path}`}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <Modal>
          <p>Modal</p>
        </Modal>
        <ModalTest>
          <p>test</p>
        </ModalTest>
        <ModalForm>
          <p>ppp</p>
        </ModalForm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps)(LandingTester);
