import React from "react";

function ClosedComponentByTester(props) {
  const [arrayChoices, setArrayChoices] = React.useState([]);
  const [selectedAnswer, setSelectedAnswer] = React.useState(null);
  const staticData = [
    { id: 1, value: "oui" },
    { id: 2, value: "non" },
    { id: 3, value: "peut etre" },
  ];
  const selected = "oui";
  React.useEffect(() => {
    // const localArray = Object.keys(props?.ids?.questionChoices).filter(Boolean);
    const localArray = Object.values(
      Object.keys(props?.ids?.questionChoices)
        ?.filter((key) => key.startsWith("choice"))
        .reduce((acc, key) => {
          return {
            ...acc,
            [key]: props?.ids?.questionChoices[key],
          };
        }, {})
    ).filter(Boolean);
    setArrayChoices(localArray);
    setSelectedAnswer(props?.answer);
    console.log("sssssss", localArray, selectedAnswer);
  }, []);
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 10 }}>
          <span className="top-component-fine-black-style">
            {props?.ids?.question}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div>
          <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
            Question fermée
          </span>
        </div>
        <div className="closed-by-testers-questions-container">
          <strong>
            {arrayChoices.map((resp) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 10,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={
                      resp == selectedAnswer
                        ? {
                            height: 24,
                            width: 24,
                            borderRadius: 12,
                            backgroundColor: "#17A25C",
                          }
                        : {
                            height: 24,
                            width: 24,
                            borderRadius: 12,
                            backgroundColor: "#e5e5e5",
                          }
                    }
                  />
                  <div style={{ paddingLeft: 10 }}>{resp}</div>
                </div>
              );
            })}
          </strong>
        </div>
      </div>
    </div>
  );
}

export default ClosedComponentByTester;
