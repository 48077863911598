import React from 'react';
import {
    Button, Label, Col, FormFeedback
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './register.css'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import flags from 'react-phone-number-input/flags';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
const TesterRegisterForm = ({
    tog_standard, handleChange, checkedGCU, checkedPDC, confirmText, testerText, csp, genders, os, pays, //page, onChangePage,
    handleSubmit, study, media, martial, currentTester, onchange, error, loading, onChangePhone
}) => {


    return (
        <React.Fragment >

            <AvForm onValidSubmit={handleSubmit}>
                <Col md='12'>
                    <div className='modal__form'>

                        <Label id="label__style__signup">{testerText.lastname}</Label>
                        <AvField name="lastname" value={currentTester.lastname}
                            placeholder="Nom de famille" type="text"
                            onChange={onchange}
                            id='lastName'
                            validate={{
                                maxLength: { value: 50 },
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                pattern: { value: "^[a-zA-Z ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                            }}
                        />
                        <Label id="label__style__signup">{testerText.name}</Label>
                        <AvField name="name" value={currentTester.name}
                            placeholder="Prénom" type="text"
                            onChange={onchange}
                            id='firstName'
                            validate={{
                                maxLength: { value: 50 },
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                pattern: { value: "[a-zA-Z ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                            }}
                        />
                        <Label id="label__style__signup">{testerText.email}</Label>
                        <AvField name="email" value={currentTester.email}
                            placeholder="Email" type="text"
                            onChange={onchange}
                            id='email'
                            invalid={error && error.email ? true : false}
                            validate={{

                                required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                pattern: { value: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$', errorMessage: "Cette adresse mail n'est pas valide" }
                            }}
                        />
                        {error && <FormFeedback  >{error.email} </FormFeedback>}
                        <Label id="label__style__signup">{testerText.age}</Label>
                        <AvField
                            value={currentTester.dateOfBirth}
                            type="date"
                            onChange={onchange}
                            name="dateOfBirth"
                            id="age"
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                dateRange: { start: { value: -65, units: 'years', }, end: { value: -18, units: 'years', }, errorMessage: "Votre age doit être compris entre 18 ans et 65 ans" }

                            }}

                        />
                        <Label id="label__style__signup">{testerText.phone}</Label>

                        <PhoneInput
                            // country="FR"
                            id='age'
                            flags={flags}
                            value={currentTester.phone}
                            //error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            onChange={onChangePhone} />
                        {currentTester.phone ? (isValidPhoneNumber(currentTester.phone) ? undefined : <FormFeedback  >Numéro de téléphone invalide </FormFeedback>) : null}
                        {(currentTester.gender !== "" && currentTester.phone === "") && <FormFeedback  >Ce champ est obligatoire </FormFeedback>}




                        <Label id="label__style__signup">{testerText.gender}</Label>
                        <AvField type="select" name="gender" id='genre' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre genre</option>
                            {genders.map(gender => <option key={gender} value={gender}>{gender}</option>)}
                        </AvField>
                        <Label id="label__style__signup">{testerText.situation}</Label>
                        <AvField type="select" name="maritalStatus" id='situation__familiale' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre situation familiale</option>
                            {martial.map(res => <option key={res} value={res}>{res}</option>)}
                        </AvField>
                        <Label id="label__style__signup">{testerText.pays}</Label>
                        <AvField type="select" name="country" id='pays' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre pays</option>
                            {pays.map(res => <option key={res} value={res}>{res}</option>)}
                        </AvField>
                        <Label id="label__style__signup">{testerText.csp}</Label>
                        <AvField type="select" name="csp" id='csp' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre CSP</option>
                            {csp.map(res => <option key={res} value={res}>{res}</option>)}
                        </AvField>
                        <Label id="label__style__signup">{testerText.niveau}</Label>
                        <AvField type="select" name="studyLevel" id='niveau' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre niveau d'études</option>
                            {study.map(res => <option key={res} value={res}>{res}</option>)}
                        </AvField>
                        <Label id="label__style__signup">{testerText.reseau}</Label>
                        <AvField type="select" name="socialMedia" id='reseau' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner </option>
                            {media.map(res => <option key={res} value={res}>{res}</option>)}
                        </AvField>
                        <Label id="label__style__signup">{testerText.os}</Label>
                        <AvField type="select" name="os" id='os' onChange={onchange}
                            validate={{
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },

                            }}
                        >
                            <option value="" disabled defaultValue>Veuillez sélectionner votre OS</option>
                            {os.map(res => <option key={res} value={res}>{res}</option>)}

                        </AvField>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedGCU}
                                        onChange={handleChange}
                                        name="checkedAge"
                                        color="primary"
                                    />
                                }
                            // label="J’ai lu et j’accepte la politique de confidentialité d’Inisght Data"
                            />
                             <span style={{fontSize:'11px', marginLeft:'-1.2em'}}>J’ai lu et j’accepte <a href="">les conditions générales </a>d’Insight Data</span>
                           
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedPDC}
                                        onChange={handleChange}
                                        name="checkedAge"
                                        color="primary"
                                    />
                                }
                            //label="J’ai lu et j’accepte les conditions générales d’utilisation d’Insight Data"
                            />
                            <span style={{fontSize:'11px', marginLeft:'-1.2em'}}> J’ai lu et j’accepte <a href="">la politique de confidentialité </a>d’Inisght Data</span>
                        </div>
                        <div className="btn_modal_ctn">
                            <Button type="submit" className="btn__form__modal" disabled={loading}
                            >
                                {loading && <i className="fa fa-refresh fa-spin mr-2" />}{confirmText}</Button>

                        </div>




                    </div>
                </Col>
            </AvForm>

        </React.Fragment >)
}
export default TesterRegisterForm

