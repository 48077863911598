import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = (props) => {
  const { data, options } = props
  return (
    <Line data={data} options={options} />
  )
}
  ;

export default LineChart;