import React, { useState, useEffect } from "react";
import PieComponent from "./PieComponent";
import BarComponent from "./BarComponent";
import OpenComponent from "./OpenComponent";
import axios from "axios";
import { getAuthorization } from "../../../../services/userServices";
import { apiUrl } from "../../../../config.json";
const apiEndpoint = apiUrl;

function FirstResume(props) {
  const [allData, setAllData] = useState([]);
  const [id, setId] = React.useState();
  const [loading,setLoading]=React.useState(true)
  const [components, setComponents] = React.useState([]);
  function getData() {
    return axios
      .get(apiEndpoint + `/api/stats/scenario/${props?._id}`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        setLoading(false)
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)

      });
  }
  React.useEffect(() => {
    setId(props?._id);
    getData().then((data) => {
      if(!data){
        return null
      }
      const arrayOfCharts = [];
      Object.keys(data).map((key,index) => {
        const object = data[key];
        if (object?.type === "close") {
          const arrayOfLabels = object?.answers?.map((val) => val?.answer);
          const arrayOfValue = object?.answers?.map((val) => val?.count);
          arrayOfCharts.push({
            component: (
              <PieComponent
                {...{
                  labels: arrayOfLabels,
                  data: arrayOfValue,
                  question: object?.question,
                  index:index

                }}
              />
            ),
            props: {
              labels: arrayOfLabels,
              data: arrayOfValue,
              question: object?.question,
              index:index
            },
          });
        }
        if (object?.type === "open") {
          const arrayOfLabels = object?.answers?.map((val) => val?.answer);
          const arrayOfValue = object?.answers?.map((val) => val?.count);
          arrayOfCharts.push({
            component: (
              <OpenComponent
                {...{
                  labels: arrayOfLabels,
                  data: arrayOfValue,
                  question: object?.question,
                  index:index

                }}
              />
            ),
            props: {
              labels: arrayOfLabels,
              data: arrayOfValue,
              question: object?.question,
              index:index
            },
          });
        }
        if (object?.type === "scale") {
          const arrayOfLabels = object?.answers?.map((val) => +val?.answer);
          const arrayOfValue = object?.answers?.map((val) => val?.count);
          arrayOfCharts.push({
            component: (
              <BarComponent
                {...{
                  labels: arrayOfLabels,
                  data: arrayOfValue,
                  question: object?.question,
                  index:index
                }}
              />
            ),
            type: object?.type,
          });
        }

        setComponents(arrayOfCharts);
      });
      setAllData(data);
    });
  }, [props?._id]);
  return (
    <div>
      {loading && <span style={{fontWeight:'bold'}}>Chargement...</span>}
      {components?.length && components?.map((item) => item?.component)}
    </div>
  );
}

export default FirstResume;
