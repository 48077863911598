import React from 'react';
import Table from "../../common/table"
import { connect } from 'react-redux';
import Delete from "../../../assets/insightdata_supprimer.svg"
import { deleteSubClient, updatePwd } from '../../../services/userServices'
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import AddIcon from "../../../assets/ajouter.svg"
import Modals from '../../common/modal'
import { toast } from 'react-toastify';
import SubClientForm from './subClientForm'
import { Col, Button } from 'reactstrap'
import { onGetSubClients, onSaveSubClient } from '../../../actions/userActions'
//import "./manageAdmin.css"
import PwdForm from './pwdForm'

class ManageSubClient extends React.Component {
    state = {
        data: [],
        show: false,
        show2: false,
        currentUser: {
            name: "",
            lastname: "",
            username: "",
        },
        loading: false,
        userLabel: {
            name: "Prénom",
            lastname: "Nom",
            username: "Nom d'utilisateur",
        },
        currentPwd: {
            old_password: '',
            new_password: '',
            new_password_confirm: ''
        },
        error: {}
    }

    componentDidMount() {
        if (!this.props.user.subClients[0])
            this.props.onGetSubClients()
        else {
            let subClients = []
            this.props.user.subClients.map(subClient => {
                subClients = [...subClients, {
                    createdAt: moment(subClient.createdAt).format('L'),
                    lastname: subClient.lastname,
                    id: subClient.id,
                    name: subClient.name,
                    username: subClient.username,
                    state: subClient.state,
                    color: (moment(new Date()).format('L') > moment(subClient.createdAt).format('L') + "02") || (moment(new Date()).isAfter(subClient.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: subClients })

        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.errorSubClient !== this.props.user.errorSubClient) {
            this.setState({
                error: nextProps.user.errorSubClient

            })
        }
        if ((this.props.user.subClients !== nextProps.user.subClients)) {
            let subClients = []
            nextProps.user.subClients.map(subClient => {
                subClients = [...subClients, {
                    createdAt: moment(subClient.createdAt).format('L'),
                    lastname: subClient.lastname,
                    name: subClient.name,
                    id: subClient.id,
                    username: subClient.username,
                    state: subClient.state,
                    color: (moment(new Date()).format('L') > moment(subClient.createdAt).format('L') + "02") || (moment(new Date()).isAfter(subClient.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: subClients })

        }
    }
    toggle = () => {
        this.setState({ show: !this.state.show })
        setTimeout(() => {
            this.setState({
                currentUser: {
                    name: "",
                    lastname: "",
                    username: "",
                },
            })
        }, 500);
    }
    onChange = e => {
        e.preventDefault();
        this.setState({ currentUser: { ...this.state.currentUser, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };
    toggle2 = () => {
        this.setState({ show2: !this.state.show2 })
        setTimeout(() => {
            this.setState({
                currentPwd: {
                    old_password: '',
                    new_password: '',
                    new_password_confirm: ''
                },
            })
        }, 500);
    }
    onChangePwd = e => {
        e.preventDefault();
        this.setState({ currentPwd: { ...this.state.currentPwd, [e.target.name]: e.target.value } })
    };
    onSubmitPwd = () => {
        const { old_password, new_password } = this.state.currentPwd;
        const pwd = {
            old_password, new_password
        }
        this.setState({ loading: true })
        updatePwd(pwd).then(res => {
            console.log(res);
            this.setState({ loading: false })
            this.toggle2()
        })
    }
    onSubmit = () => {
        const { name, lastname, username } = this.state.currentUser;
        const subClient = {
            name, lastname, username
        }
        this.setState({ loading: true })
        this.props.onSaveSubClient(subClient)
        setTimeout(() => {
            this.setState({ loading: false })
            !this.state.error.message && this.toggle()
            this.state.error.message && toast.warning('Veillez vérifier vos champs')
            !this.state.error.message && toast.success('Compte créé avec succès ')
        }, 2000);
    }
    onDelete = (rowId) => {
        deleteSubClient(rowId).then(res => {
            console.log(res)
            this.props.onGetSubClients()
        })
    }
    renderModalPwd() {
        const { currentPwd, userLabel, loading } = this.state
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show2}
                toggleShow={this.toggle2}
                header='Modifier mon mot de passe'
            >
                <PwdForm
                    confirmText='Valider'
                    handleSubmit={this.onSubmitPwd}
                    onChangePwd={this.onChangePwd}
                    currentPwd={currentPwd}
                    loading={loading}
                    error={this.state.error}

                />

            </Modals>
        )
    }
    renderModalSubClient() {
        const { currentUser, userLabel, loading } = this.state
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Ajouter un Compte'
            >
                <SubClientForm
                    confirmText='Ajouter un Compte'
                    handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    currentUser={currentUser}
                    userText={userLabel}
                    loading={loading}
                    error={this.state.error}
                />

            </Modals>
        )
    }
    renderTable = () => {
        // const details = order.details.filter(dur => moment(dur.from_date).format('MM') === this.state.month)
        const columns = [
            { title: "Date", field: 'createdAt' },
            { title: "Nom", field: 'lastname' },
            { title: "Prémon", field: 'name' },
            { title: "Nom d'utilisateur", field: 'username' },
            { title: "Etat", field: 'state', render: rowData => <span style={{ color: `${rowData.color}` }}>{rowData.state === "to_contact" ? "A contacter" : "Ok"}</span> },
        ]
        const actions = [{
            icon: () => <img style={{ width: '40px' }} src={Delete} alt="" />,
            tooltip: 'Delete',
            onClick: (event, rowData) => this.onDelete(rowData.id),
        },
        ]
        return (
            <Table
                title='Liste des Comptes'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (<>
            <div className='favoris'>
                <span className='db__title'>Mon Compte</span>
                <div className='display__flex__row'>
                    <Button className='shortcut' >Mes informations</Button>
                    <Button className='shortcut' onClick={() => this.toggle2()} >Gestion mot de passe</Button>
                </div>
            </div>

            <div className='display__flex__row'>
                <Col md='10'>

                    {this.renderTable()}
                    {this.renderModalSubClient()}
                    {this.renderModalPwd()}
                </Col>
                <Col md='2'>
                    <div className='scenario__info'>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Licence</span>
                            <br />
                            <br />
                            <span className='square1'>Gold</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Comptes actifs</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Nombre de scénarios</span>
                            <br />
                            <br />
                            <span className='square3'>0</span>
                        </Col>
                        <Col className="col__style">
                            <span style={{ fontWeight: 'bold' }}>Montant facturé</span>
                            <br />
                            <br />
                            <span className='square2'>0</span>
                        </Col>
                    </div>
                </Col>
                <Fab id='btn__add__manager' aria-label="add" onClick={this.toggle} >
                    <img style={{ width: '56px' }} src={AddIcon} alt="" />
                </Fab>
            </div>
        </>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user
});

export default connect(
    mapStateToProps,
    { onGetSubClients, onSaveSubClient }
)(ManageSubClient);