import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../services/authServices";
import { getAuthorization } from "../../../services/userServices";
import http from "../../../services/httpServices";
import { apiUrl } from "../../../config.json";
import axios from "axios";
import Avatar from "react-avatar";
import UpdatePasswordModal from "./UpdatePasswordModal";
import DashboardIcon from '../../../assets/insightdata_tableaudebord.svg';
import SenarioIcon from '../../../assets/insightdata_senario.svg'
import ProfileIcon from '../../../assets/insightdata_profil.svg'
import { Link } from "react-router-dom";


const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
const ProfileCT = (props) => {
  const [user, setUser] = React.useState({});
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);
  function toggleVisible(visible) {
    setVisible(!visible);
  }
  return (
    <React.Fragment>
      <div style={{backgroundColor:'#f1f1f1', height:140, display:"flex", flexDirection:'row', alignItems:"flex-start"}}>
        <div className ="profile-box">
        <img style={{ width: "60px", height: '48px' }} src={DashboardIcon} alt='' />
          <span className='profile-card'>Tableau de bord</span>
        </div>
        <div className ="profile-box">
        <img style={{ width: "60px", height: '48px' }} src={SenarioIcon} alt='' />

        <span className='profile-card'>Documents</span>

        </div>
        <div className ="profile-box">
        <img style={{ width: "60px", height: '48px' }} src={ProfileIcon} alt='' />

        <span className='profile-card'>Profil</span>

        </div>
      </div>
      <Row
        className="bg-grey no-margin  p-3 top-bandeau   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);transition: 0.3s   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);"
      >
        <Col lg="4" md="4" className="main mr-2 card-padding-top ">
          <Avatar
            size="100"
            name={user?.data?.name + " " + user?.data?.lastname}
            color="#50C878	"
            round={true}
          />

          <div className="d-flex flex-column text-padding-top">
            <span className="font-medium-2 font-weight-600">
              {" "}
              {user?.data?.name + " " + user?.data?.lastname}
            </span>
            <span>{user?.data?.country}</span>
            <span>{user?.data?.email}</span>
          </div>
          <hr />
          <div></div>
          <Col
            md="12"
            lg="12"
            className="d-flex justify-content-center align-items-center padding-btn"
          >
            <Button className="color=#301934">TELECHARGER VOTRE PHOTO</Button>
          </Col>
          <Col
            md="12"
            lg="12"
            className="d-flex justify-content-center align-items-center padding-btn"
          >
            <Button onClick={() => toggleVisible()} className="color=#301934">
              MODIFIER MON MOT DE PASSE
            </Button>
          </Col>
        </Col>
        <Col lg="7" md="7" className="p-3 main">
          <Form>
            <Row>
              <Col>
                <div className="column-left">
                  <Label>Prénom</Label>
                  <Input placeholder={user?.data?.name} />
                </div>
              </Col>
              <Col>
                <div className="column-left">
                  <Label>Nom</Label>
                  <Input placeholder={user?.data?.lastname} />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <FormGroup className="column-left">
                  <Label>Adresse email</Label>
                  <Input placeholder={user?.data?.email} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="column-left">
                  <Label>Numéro du portable</Label>
                  <Input type="number" placeholder={user?.data?.phone} />
                </FormGroup>
              </Col>
            </Row>
            <Col
              md="12"
              lg="12"
              className="d-flex justify-content-start no-margin no-padding"
            >
                          <Link to={`/client-tester/info`}>

              <span style={{fontWeight:'bold', fontSize:14, color:"green"}}>
              Accedér au questionnaire sur mes informations testeur.
              </span>
              </Link>
            </Col>
            <Col
              md="12"
              lg="12"
              className="d-flex justify-content-end no-margin no-padding"
            >
              <Button className="btn-success"> ENREGISTRER</Button>
            </Col>
          </Form>
        </Col>
      </Row>
      <UpdatePasswordModal visible={visible} toggleVisible={toggleVisible} />
    </React.Fragment>
  );
};
export default ProfileCT;
