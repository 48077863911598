import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Label, FormFeedback } from 'reactstrap';
import { onSaveTester } from "../../../actions/userActions"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css'
import "./register.css"
import { toast } from 'react-toastify';
import Select from 'react-select';

class TesterRegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentUser: {
                name: "",
                lastname: "",
                gender: "",
                country: "",
                csp: "",
                studyLevel: "",
                os: "",
                maritalStatus: "",
                socialMedia: "",
                email: "",
                phone: "",
                dateOfBirth: ""
            },
            userLabel: {
                firstName: "Prénom",
                lastNameText: "Nom",
                emailText: "Email",
                age: "Age",
                cspText: "CSP",
                situation: "Situation familiale",
                osText: "OS",
                genre: "Genre",
                pays: "Pays de résidence",
                niveau: "Niveau d'études",
                reseau: "Réseaux Sociaux",
                phoneNum: "Télephone",
            },
            selectedOption: null,
            error: {}
        };
        this.onSubmit = this.onSubmit.bind(this)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.errorTest !== this.props.user.errorTest) {
            this.setState({ error: nextProps.user.errorTest && nextProps.user.errorTest.errors })
        }
    }
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    onChange = e => {
        e.preventDefault();
        this.setState({ currentUser: { ...this.state.currentUser, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };

    onSubmit() {
        const { name, lastname, gender, country,
            csp, studyLevel, os, maritalStatus,
            socialMedia, email, dateOfBirth, phone
        } = this.state.currentUser;
        const tester = {
            name, lastname, gender, country,
            csp, studyLevel, os
            // :this.state.selectedOption.map(res=> {return res.value})
            , maritalStatus,
            socialMedia, email, dateOfBirth, phone
        }
        this.setState({ loading: true })
        this.props.onSaveTester(tester)
        setTimeout(() => {
            this.setState({
                loading: false, currentUser: {
                    name: "",
                    lastname: "",
                    gender: "",
                    country: "",
                    csp: "",
                    studyLevel: "",
                    os: "",
                    maritalStatus: "",
                    socialMedia: "",
                    email: "",
                    phone: "",
                    dateOfBirth: ""
                }
            })
            this.state.error ? toast.warning('Veillez vérifier vos champs') : toast.success('Inscription effectuée avec succes Vous allez recevoir un mail vous expliquant la démarche de tests pour valider votre inscription ')
        }, 2000);
    }

    render() {
        const { loading, selectedOption } = this.state
        const { firstName, lastNameText, emailText, age, cspText,
            situation, osText, genre, pays, niveau, reseau, phoneNum
        } = this.state.userLabel
        const { name, lastname, country, email, dateOfBirth, phone, gender
        } = this.state.currentUser;
        const signUptext = "Devenir testeur";
        const header = "Devenir testeur Insight Data"
        const genders = ["Femme", "Homme"]
        const csp = ["Agriculteurs exploitants", "Artisans", " commerçants et chefs d’entreprise", "Cadres et professions intellectuelles supérieures, Professions Intermédiaires", "Employés", "Ouvriers"]
        const study = ["Brevet", "Bac", "Bac +2", "Bac +3/4", "Bac +5", "> Bac +5"]
        const martial = ["Célibataire", "Couple", "Famille sans enfants", "Famille avec enfants"]
         const os = ["IOS", "Android", "Windows", "Autre"]
        const media = ["Facebook", "Instagram", "WhatsApp", "Autre"]
        // const os = [
        //     { value: 'IOS', label: 'IOS' },
        //     { value: 'Android', label: 'Android' },
        //     { value: 'Windows', label: 'Windows' },
        //     { value: 'Autre', label: 'Autre' },
        // ]
        const Pays = [
            "Afghanistan",
            "Afrique du Sud",
            "Albanie",
            "Algérie",
            "Allemagne",
            "Andorre",
            "Angola",
            "Anguilla",
            "Antarctique",
            "Antigua-et-Barbuda",
            "Antilles néerlandaises",
            "Arabie saoudite",
            "Argentine",
            "Arménie",
            "Aruba",
            "Australie",
            "Autriche",
            "Azerbaïdjan",
            "Bahamas",
            "Bahreïn",
            "Bangladesh",
            "Barbade",
            "Bélarus",
            "Belgique",
            "Belize",
            "Bénin",
            "Bermudes",
            "Bhoutan",
            "Bolivie",
            "Bosnie-Herzégovine",
            "Botswana",
            "Brésil",
            "Brunéi Darussalam",
            "Bulgarie",
            "Burkina Faso",
            "Burundi",
            "Cambodge",
            "Cameroun",
            "Canada",
            "Cap-Vert",
            "Ceuta et Melilla",
            "Chili",
            "Chine",
            "Chypre",
            "Colombie",
            "Comores",
            "Congo-Brazzaville",
            "Corée du Nord",
            "Corée du Sud",
            "Costa Rica",
            "Côte d’Ivoire",
            "Croatie",
            "Cuba",
            "Danemark",
            "Diego Garcia",
            "Djibouti",
            "Dominique",
            "Égypte",
            "El Salvador",
            "Émirats arabes unis",
            "Équateur",
            "Érythrée",
            "Espagne",
            "Estonie",
            "État de la Cité du Vatican",
            "États fédérés de Micronésie",
            "États-Unis",
            "Éthiopie",
            "Fidji",
            "Finlande",
            "France",
            "Gabon",
            "Gambie",
            "Géorgie",
            "Géorgie du Sud et les îles Sandwich du Sud",
            "Ghana",
            "Gibraltar",
            "Grèce",
            "Grenade",
            "Groenland",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernesey",
            "Guinée",
            "Guinée équatoriale",
            "Guinée-Bissau",
            "Guyana",
            "Guyane française",
            "Haïti",
            "Honduras",
            "Hongrie",
            "Île Bouvet",
            "Île Christmas",
            "Île Clipperton",
            "Île de l'Ascension",
            "Île de Man",
            "Île Norfolk",
            "Îles Åland",
            "Îles Caïmans",
            "Îles Canaries",
            "Îles Cocos - Keeling",
            "Îles Cook",
            "Îles Féroé",
            "Îles Heard et MacDonald",
            "Îles Malouines",
            "Îles Mariannes du Nord",
            "Îles Marshall",
            "Îles Mineures Éloignées des États-Unis",
            "Îles Salomon",
            "Îles Turks et Caïques",
            "Îles Vierges britanniques",
            "Îles Vierges des États-Unis",
            "Inde",
            "Indonésie",
            "Irak",
            "Iran",
            "Irlande",
            "Islande",
            "Israël",
            "Italie",
            "Jamaïque",
            "Japon",
            "Jersey",
            "Jordanie",
            "Kazakhstan",
            "Kenya",
            "Kirghizistan",
            "Kiribati",
            "Koweït",
            "Laos",
            "Lesotho",
            "Lettonie",
            "Liban",
            "Libéria",
            "Libye",
            "Liechtenstein",
            "Lituanie",
            "Luxembourg",
            "Macédoine",
            "Madagascar",
            "Malaisie",
            "Malawi",
            "Maldives",
            "Mali",
            "Malte",
            "Maroc",
            "Martinique",
            "Maurice",
            "Mauritanie",
            "Mayotte",
            "Mexique",
            "Moldavie",
            "Monaco",
            "Mongolie",
            "Monténégro",
            "Montserrat",
            "Mozambique",
            "Myanmar",
            "Namibie",
            "Nauru",
            "Népal",
            "Nicaragua",
            "Niger",
            "Nigéria",
            "Niue",
            "Norvège",
            "Nouvelle-Calédonie",
            "Nouvelle-Zélande",
            "Oman",
            "Ouganda",
            "Ouzbékistan",
            "Pakistan",
            "Palaos",
            "Panama",
            "Papouasie-Nouvelle-Guinée",
            "Paraguay",
            "Pays-Bas",
            "Pérou",
            "Philippines",
            "Pitcairn",
            "Pologne",
            "Polynésie française",
            "Porto Rico",
            "Portugal",
            "Qatar",
            "R.A.S. chinoise de Hong Kong",
            "R.A.S. chinoise de Macao",
            "régions éloignées de l’Océanie",
            "République centrafricaine",
            "République démocratique du Congo",
            "République dominicaine",
            "République tchèque",
            "Réunion",
            "Roumanie",
            "Royaume-Uni",
            "Russie",
            "Rwanda",
            "Sahara occidental",
            "Saint-Barthélémy",
            "Saint-Kitts-et-Nevis",
            "Saint-Marin",
            "Saint-Martin",
            "Saint-Pierre-et-Miquelon",
            "Saint-Vincent-et-les Grenadines",
            "Sainte-Hélène",
            "Sainte-Lucie",
            "Samoa",
            "Samoa américaines",
            "Sao Tomé-et-Principe",
            "Sénégal",
            "Serbie",
            "Serbie-et-Monténégro",
            "Seychelles",
            "Sierra Leone",
            "Singapour",
            "Slovaquie",
            "Slovénie",
            "Somalie",
            "Soudan",
            "Sri Lanka",
            "Suède",
            "Suisse",
            "Suriname",
            "Svalbard et Île Jan Mayen",
            "Swaziland",
            "Syrie",
            "Tadjikistan",
            "Taïwan",
            "Tanzanie",
            "Tchad",
            "Terres australes françaises",
            "Territoire britannique de l'océan Indien",
            "Territoire palestinien",
            "Thaïlande",
            "Timor oriental",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinité-et-Tobago",
            "Tristan da Cunha",
            "Tunisie",
            "Turkménistan",
            "Turquie",
            "Tuvalu",
            "Ukraine",
            "Union européenne",
            "Uruguay",
            "Vanuatu",
            "Venezuela",
            "Viêt Nam",
            "Wallis-et-Futuna",
            "Yémen",
            "Zambie",
            "Zimbabwe"]
        return (
            <div className="ctn__signup">
                <AvForm className="signup__form" onValidSubmit={this.onSubmit} >
                    <div className="m-4">
                        <span id="header__signup">{header}</span>
                    </div>
                    <Label id="label__style__signup">{lastNameText}</Label>
                    <AvField
                        value={lastname}
                        type="text"
                        onChange={this.onChange}
                        name="lastname"
                        placeholder="Nom de famille"
                        id="lastName"
                        validate={{
                            maxLength: { value: 50 },
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },
                            pattern: { value: "^[a-zA-Z ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                        }}

                    />
                    <Label id="label__style__signup">{firstName}</Label>
                    <AvField
                        value={name}
                        placeholder="Prénom"
                        type="text"
                        onChange={this.onChange}
                        name="name"
                        id="firstName"
                        validate={{
                            maxLength: { value: 50 },
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },
                            pattern: { value: "^[a-zA-Z ]{1,50}$", errorMessage: "Les chiffres et les caractéres speciaux ne sont pas autorisé" }
                        }}
                    />

                    <Label id="label__style__signup">{emailText}</Label>
                    <AvField
                        value={email}
                        placeholder="Email de travail"
                        type="text"
                        onChange={this.onChange}
                        name="email"
                        id="email"
                        invalid={this.state.error && this.state.error.email ? true : false}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },
                            pattern: { value: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$', errorMessage: "Cette adresse mail n'est pas valide" },

                        }}

                    />
                    {this.state.error && <FormFeedback  >{this.state.error.email} </FormFeedback>}

                    <Label id="label__style__signup">{age}</Label>
                    <AvField
                        value={dateOfBirth}
                        type="date"
                        onChange={this.onChange}
                        name="dateOfBirth"
                        id="age"
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },
                            dateRange: {start: {value: -65, units: 'years',} ,end: {value: -18, units: 'years',}, errorMessage: "Votre age doit être compris entre 18 ans et 65 ans"}

                        }}

                    />
                    <Label id="label__style__signup">{phoneNum}</Label>
                    <PhoneInput
                        // country="FR"
                        id='age'
                        flags={flags}
                        value={phone}
                        //error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                        onChange={value => this.setState({ currentUser: { ...this.state.currentUser, phone: value } })} />
                    {phone ? (isValidPhoneNumber(phone) ? undefined : <FormFeedback  >Numéro de téléphone invalide </FormFeedback>) : null}
                    {(gender!==""&&phone==="") &&  <FormFeedback  >Ce champ est obligatoire </FormFeedback>}
                    {/* <AvField
                        value={phone}
                        placeholder="Numéro de téléphone"
                        type="text"
                        onChange={this.onChange}
                        name="phone"
                        id="age"
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}

                    /> */}
                    <Label id="label__style__signup">{genre}</Label>
                    <AvField type="select" name="gender" id='genre' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner votre genre</option>
                        {genders.map(gender => <option key={gender} value={gender}>{gender}</option>)}
                    </AvField>
                    <Label id="label__style__signup">{situation}</Label>
                    <AvField type="select" name="maritalStatus" id='situation__familiale' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner votre situation familiale</option>
                        {martial.map(res => <option key={res} value={res}>{res}</option>)}
                    </AvField>
                    <Label id="label__style__signup">{pays}</Label>
                    <AvField type="select" name="country" id='pays' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner votre pays</option>
                        {Pays.map(res => <option key={res} value={res}>{res}</option>)}
                    </AvField>

                    <Label id="label__style__signup">{cspText}</Label>
                    <AvField type="select" name="csp" id='csp' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner votre CSP</option>
                        {csp.map(res => <option key={res} value={res}>{res}</option>)}
                    </AvField>
                    <Label id="label__style__signup">{niveau}</Label>
                    <AvField type="select" name="studyLevel" id='niveau' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner votre niveau d'études</option>
                        {study.map(res => <option key={res} value={res}>{res}</option>)}
                    </AvField>
                    <Label id="label__style__signup">{reseau}</Label>
                    <AvField type="select" name="socialMedia" id='reseau' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner </option>
                        {media.map(res => <option key={res} value={res}>{res}</option>)}
                    </AvField>
                    <Label id="label__style__signup">{osText}</Label>
                    <AvField type="select" name="os" id='os' onChange={this.onChange}
                        validate={{
                            required: { value: true, errorMessage: "Ce champ est obligatoire" },

                        }}
                    >
                        <option value="" disabled defaultValue>Veuillez sélectionner votre OS</option>
                        {os.map(res => <option key={res} value={res}>{res}</option>)}

                    </AvField>
                    {/* <Select

                        value={selectedOption}
                        onChange={this.handleChange}
                        options={os}
                        isMulti
                    /> */}
                    <Button
                        className="signup__button"
                        type="submit"
                        disabled={loading}
                    >
                        {loading && <i className="fa fa-refresh fa-spin mr-2" />}
                        {signUptext}
                    </Button>
                    <div className="footer__signup">
                        <span id="text__footer">En cliquant sur "Devenir testeur", vous acceptez la politique de confidentialité et les conditions d'utilisation d'Insight Data</span>
                    </div>
                </AvForm>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user
});

export default connect(
    mapStateToProps,
    { onSaveTester }
)(TesterRegisterForm);
