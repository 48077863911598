import React from "react";

function OpenComponentByTester(props) {
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 10 }}>
          <span className="top-component-fine-black-style">
            {props?.ids?.question}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div>
          <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
            Question ouverte
          </span>
        </div>
        <div className="border-bottoms-question open-by-testers-questions-container">
          <span style={{ color: "gray", fontWeight: "bold" }}>
            {props?.answer}
          </span>
        </div>
      </div>
    </div>
  );
}

export default OpenComponentByTester;
