import React from "react";
import BarChart from "./BarCharts";
function BarComponent({ labels, question, data, index }) {
  const [count, setCount] = React.useState(0);
  function countResp(data) {
    data.map((d) => {
      setCount(count + d);
    });
  }

  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <strong>Etape {index + 1} :</strong>
        <div style={{ paddingLeft: 20 }}>
          <span className="top-component-fine-black-style">{question}</span>
        </div>
      </div>
      <div>
        <strong> {data.reduce(reducer, 0)} réponses </strong>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="chart-container">
          <BarChart {...{ labels, data }} />
        </div>
      </div>
    </div>
  );
}

export default BarComponent;
