import HomePageForm from "../components/home/homePage"
import AboutUs from "../components/home/aboutUs"
import OurSolutions from "../components/home/ourSolutions"
import Pricing from "../components/home/pricing"
import LoginForm from "../components/login/login.jsx"
import Stripe from "../components/register/stripe/Stripe.jsx"
import DashboarAdmin from "../components/dashboard/admin/dashboardAdmin.jsx"
import TesterValidation from "../components/dashboard/admin/testerValidation.jsx"
import DashboardClient from "../components/dashboard/client/dashboardClient.jsx"
import DashboardTester from "../components/dashboard/tester/dashboardTester.jsx"
import DashboardClientTester from "../components/dashboard/clientTester/dashboardClientTester.jsx";
import TesterRegisetrForm from "../components/register/tester/register"
import LandingPage from "../components/landingPages/landingPage"
import lang from "./utils.json"
import manageAdmin from "../components/manageUser/admin/manageAdmin"
import ManageSubClient from '../components/manageUser/subcCient/manageSubClient'
import AnaylseIcon from '../assets/insightdata_analyse.svg'
import StatIcon from '../assets/insightdata_statistique.svg'
import SenarioIcon from '../assets/insightdata_senario.svg'
import DashboardIcon from '../assets/insightdata_tableaudebord.svg'
import DashboardIcons from '../assets/insightdata_senario.svg'
import ValidateClientIcon from '../assets/insightdata_validationclient.svg'
import ValidateSenarioIcon from '../assets/insightdata_validationdesenario.svg'
import AccountManagementIcon from '../assets/insightdata_gestiondescomptes.svg'
import scenarioList from "../components/scenario/scenarioList"
import CreateSenario from "../components/scenario/createSenario"
import CreateSteps from "../components/scenario/createScenario/createSteps"
import TesterPanel from "../components/scenario/testerPanel"
import stepVisualisation from "../components/scenario/createScenario/stepVisualisation"
import PanelList from "../components/scenario/panelList"
import CreatePanelInsight from "../components/scenario/createPanel/createPanelInsight"
import CreatePanelClient from "../components/scenario/createPanel/createPanelClient"
import references from "../components/home/references"
import Profile from "../components/landingPages/tester/profile"
import ProfileCT from "../components/landingPages/clientTester/profile"
import Info from "../components/landingPages/clientTester/info"
import DashboardPageTester from "../components/landingPages/tester/dashboard"
import DashboardPageClientTester from "../components/landingPages/clientTester/dashboard"
import Iban from "../components/landingPages/tester/Iban"
import Questions from "../components/landingPages/tester/questions"
import QuestionsClient from "../components/landingPages/clientTester/questions"
import RawData from "../components/landingPages/client/rawData"
import AllData from "../components/statistics/allData"
import ValidateScenario from "../components/scenario/admin/validateScenario"
import CreateScenarioAdmin from "../components/scenario/admin/createScenarioAdmin"
import Recorder from "../components/common/recorder"
import StepAnalyzes from "../components/analyzes/byStep/stepAnalyzes"
import FacialRecognition from "../components/analyzes/facialRecognition/facialRecognition"
import FacialRecognitionTester from "../components/analyzes/facialRecognition/facialRecognitionTester"
import TesterAnalyzes from "../components/analyzes/byTester/testerAnalyzes"


const allRoutes = () =>
  [
    // {
    //   path: "/home",
    //   component: HomePageForm,
    //   id: "home"
    // },
    // {
    //   path: "/login",
    //   component: LoginForm,
    //   id: "login"
    // },
    // {
    //   path: "/clientsignup",
    //   component: ClientRegisterForm,
    //   id: "register"
    // },

  ];
const authRoutes = () =>
  [
    {
      Name: "",
      path: "/",
      component: LandingPage,
      id: "home"
    },




  ];
const menuLandingClient = (lng) => [
  {
    Name: lang[lng].dashboard,
    icon: <img style={{ width: "60px", height: '48px' }} src={DashboardIcon} alt='' />,
    path: "/",
    route: "/",
    component: DashboardClient,
    id: "dashboard"
  },
  {
    Name: "Scenario",
    icon: <img style={{ width: "60px", height: '48px' }} src={SenarioIcon} alt='' />,
    path: "/scenario",
    route: "/",
    component: scenarioList,
    id: "dashboard"
  },
  {
    Name: "",
    icon: <img style={{ width: "60px", height: '48px' }} src={SenarioIcon} alt='' />,
    path: "/createscenario",
    route: "/",
    component: CreateSenario,
    id: "dashboard"
  },
  {
    Name: "",
    icon: <img style={{ width: "60px", height: '48px' }} src={SenarioIcon} alt='' />,
    path: "/testerpanel",
    route: "/",
    component: TesterPanel,
    id: "dashboard"
  },
  {
    Name: "",
    icon: <img style={{ width: "60px", height: '48px' }} src={SenarioIcon} alt='' />,
    path: "/panellist",
    route: "/",
    component: PanelList,
    id: "dashboard"
  },
  {
    Name: "",
    path: "/createsteps",
    component: CreateSteps,
    id: "scenariosteps"
  },
  {
    Name: "",
    path: "/visualisation",
    component: stepVisualisation,
    id: "visualise"
  },
  {
    Name: "",
    path: "/createpanel",
    component: CreatePanelInsight,
    id: "createPanel"
  },
  {
    Name: "",
    path: "/createpanelclt",
    component: CreatePanelClient,
    id: "CreatePanelClt"
  },
  {
    Name: "Statistiques",
    icon: <img style={{ width: "60px", height: '48px' }} src={StatIcon} alt='' />,
    path: "/statistics",
    route: "/",
    component: RawData,
    id: "rawData"
    // route: "/rawData",
    // component: RawData,
    // id: "rawData",
    // exact:true
  },
  {
    Name: "Analyses",
    icon: <img style={{ width: "60px", height: '48px' }} src={AnaylseIcon} alt='' />,
    path: "/step-analyze",
    route: "/",
    component: StepAnalyzes,
    id: "dashboard"
  },
  {
    Name: "",
    path: "/tester-analyze",
    route: "/",
    component: TesterAnalyzes,
  },
  {
    Name: "",
    path: "/facial-recognition",
    route: "/",
    component: FacialRecognition,
  },
  {
    Name: "",
    path: "/tester-facial-recognition",
    route: "/",
    component: FacialRecognitionTester,
  },
  {
    Name: "",
    route: "/rawData",
    component: RawData,
    id: "rawData",
    // exact:true,
    // component: AllData,
    // id: "dashboard"
  },

  {
    Name: "Mon Compte",
    icon: <img style={{ width: "60px", height: '48px' }} src={AccountManagementIcon} alt='' />,
    path: "/usesmanage",
    route: "/",
    component: ManageSubClient,
    id: "managesubclient"
  },

]
const menuLandingTester = (lng) => [
  {
    Name: lang[lng].dashboard,
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcon} alt='' />,
    path: "/dashboard",
    route: "/tester",
    component: DashboardPageTester,
    id: "dashboard",
    exact: true
  },
  {
    Name: "IBAN",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/iban",
    route: "/",
    component: Iban,
    id: "iban"
  },
  {
    Name: "Profile",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/profile",
    route: "/",
    component: Profile,
    id: "profile"
  },
  {
    Name: "",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/questions",
    route: "/",
    component: Questions,
    id: "questions"
  },
]

const menuLandingClientTester = (lng) => [
  {
    Name: lang[lng].dashboard,
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcon} alt='' />,
    path: "/dashboard",
    route: "/client-tester",
    component: DashboardPageClientTester,
    id: "dashboard",
    exact: true
  },
  {
    Name: "IBAN",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/iban",
    route: "/",
    component: Iban,
    id: "iban"
  },
  {
    Name: "Profile",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/profile",
    route: "/",
    component: ProfileCT,
    id: "profile"
  },
  {
    Name: "Info",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/info",
    route: "/",
    component: Info,
    id: "info"
  },
  {
    Name: "Questions",
    icon: <img style={{ width: "60px", height: '60px' }} src={DashboardIcons} alt='' />,
    path: "/questions",
    route: "/",
    component: QuestionsClient,
    id: "questions"
  },

]
const menuLandingAdmin = (lng) => [
  {
    Name: "Validation client",
    icon: <img style={{ width: "60px", height: '48px' }} src={ValidateClientIcon} alt='' />,
    path: "/",
    route: "/",
    component: DashboarAdmin,
    id: "dashboard"
  },
  {
    Name: "Validation testeur",
    icon: <img style={{ width: "60px", height: '48px' }} src={ValidateClientIcon} alt='' />,
    path: "/validation",
    route: "/",
    component: TesterValidation,
    id: "dashboard"
  },
  {
    Name: "Creation scénario",
    icon: <img style={{ width: "60px", height: '48px' }} src={SenarioIcon} alt='' />,
    path: "/createscenario",
    route: "/",
    component: CreateScenarioAdmin,
    id: "create"
  },
  {
    Name: "Validation scénario",
    icon: <img style={{ width: "60px", height: '48px' }} src={ValidateSenarioIcon} alt='' />,
    path: "/validatescenario",
    route: "/",
    component: ValidateScenario,
    id: "validate"
  },
  {
    Name: "Gestion des comptes",
    icon: <img style={{ width: "60px", height: '48px' }} src={AccountManagementIcon} alt='' />,
    path: "/manageusers",
    route: "/",
    component: manageAdmin,
    id: "manageusers"
  },

]
const Routes = (lng) =>
  [
    {
      Name: lang[lng].presentation,
      path: "/presentation",
      component: AboutUs,
      id: "presentation"
    },
    {
      Name: lang[lng].solutions,
      path: "/solution",
      component: OurSolutions,
      id: "solution"
    },

    {
      Name: lang[lng].analyzes,
      path: "/analyse",
      component: HomePageForm,
      id: "analyses"
    },
    {
      Name: lang[lng].pricing,
      path: "/pricing",
      component: Pricing,
      id: "pricing"
    },
    {
      Name: lang[lng].ref,
      path: "/ref",
      component: references,
      id: "ref"
    },
    {
      Name: lang[lng].blog,
      path: "/blog",
      component: references,
      id: "home"
    },
    // {
    //   Name: lang[lng].tester,
    //   path: "/testersignup",
    //   component: TesterRegisetrForm,
    //   id: "testersignup"
    // },
    {
      Name: lang[lng].login,
      path: "/login",
      component: LoginForm,
      id: "login"
    },
    // {
    //   Name: lang[lng].login,
    //   path: "/stripe",
    //   component: Stripe,
    //   id: "stripe"
    // },
    // {
    //   Name: "Demander un essai",
    //   path: "/clientsignup",
    //   component: ClientRegisterForm,
    //   id: "clientsignup"
    // },


  ];

export { allRoutes, authRoutes, Routes, menuLandingClient, menuLandingTester, menuLandingAdmin, menuLandingClientTester }