import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import NavBar from "./components/navbar/navbar.jsx";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { authRoutes, Routes } from "./utils/routes";
import setToken from "./utils/setToken";
import store from "./utils/store";
import { successLogin } from "./actions/authActions";
import { getCurrentUser, getJwt } from "./services/authServices";
import Toast from "./components/common/Toast";
import "./App.css";
import VerticalNavBar from './components/verticalNavBar/verticalNavBar'
import Profile from "./components/landingPages/tester/profile.jsx";
import Footer from "./components/footer/footer";
import { Col } from 'reactstrap'
import { menuLandingAdmin, menuLandingClient, menuLandingTester, menuLandingClientTester } from './utils/routes'
const tokenDecoded = getCurrentUser();
if (tokenDecoded) {
  if (tokenDecoded.exp > Date.now() / 1000) {
    setToken(getJwt());
    store.dispatch(successLogin(tokenDecoded));
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lngActive: this.props.auth.lng,
    };
  }
  render() {

    const routes = Routes(this.state.lngActive).map((route, i) => (
      <Route key={route.id + i} path={route.path} component={route.component} />
    ));
    const routesAuth = authRoutes().map((route, i) => (
      <Route key={route.id + i} path={route.path} component={route.component} />
    ));
    const route = Routes(this.state.lngActive).map((r) => r.path);
    const routeAuth = authRoutes().map((r) => r.path);

    const renderRoutes = route.includes(window.location.pathname) ? (
      <React.Fragment> {routes}</React.Fragment>
    ) : (
      <React.Fragment>
        {" "}
        <Redirect to="/presentation" />
        {routes}
      </React.Fragment>
    );

    const renderRoutesAuth = routeAuth.includes(window.location.pathname) ? (
      <React.Fragment> {routesAuth} </React.Fragment>
    ) : (
      <React.Fragment>
        {this.props.auth.user.roles &&
          this.props.auth.user.roles[0] === "ROLE_ADMIN" && (
            <Redirect to="/admin" />
          )}
        {this.props.auth.user.roles &&
          this.props.auth.user.roles[0] === "ROLE_CLIENT" && (
            <Redirect to="/client" />
          )}
        {this.props.auth.user.roles &&
          this.props.auth.user.roles[0] === "ROLE_TESTER" && (
            <Redirect to="/tester/dashboard" />
          )}
        {this.props.auth.user.roles &&
          this.props.auth.user.roles[0] === "ROLE_CLIENT_TESTER" && (
            <Redirect to="/client-tester/dashboard" />
          )}
        {routesAuth}{" "}
      </React.Fragment>
    );
    const renderNavbar = <NavBar />;
    const renderProfileRouter = (
      <Route path="/profile" component={Profile} exact={true} />
    );
    // const p = window.location.pathname;
    // console.log("ppppp", p);
    const path = window.location.pathname;
    const renderFooter = <Footer />
    const { lng } = this.props.auth
    return (
      <div className="App">

        <Router>
          {!this.props.auth.isAuthenticated && <NavBar />}
          {(!this.props.auth.isAuthenticated) ? <header className="App-header">

            {!this.props.auth.isAuthenticated && renderRoutes}
            {this.props.auth.isAuthenticated && renderRoutesAuth}

          </header> :
            <>
              {this.props.auth.user.roles[0] !== "ROLE_CLIENT_TESTER" ? <header className="App-header2">
                <Col md='3'>
                  {this.props.auth.user.roles[0] === "ROLE_ADMIN" && <VerticalNavBar history={this.props.history} Menu={menuLandingAdmin(lng)} />}
                  {this.props.auth.user.roles[0] === "ROLE_CLIENT" && <VerticalNavBar history={this.props.history} Menu={menuLandingClient(lng)} />}
                  {this.props.auth.user.roles[0] === "ROLE_TESTER" && <VerticalNavBar history={this.props.history} Menu={menuLandingTester(lng)} />}
                </Col>
                <Col md='9'>
                  {!this.props.auth.isAuthenticated && renderRoutes}
                  {this.props.auth.isAuthenticated && renderRoutesAuth}
                </Col>
              </header> :<>
                   <NavBar />
                <header className="App-header">
                  {!this.props.auth.isAuthenticated && renderRoutes}
                  {this.props.auth.isAuthenticated && renderRoutesAuth}
                </header></>
              }
            </>}
          {!this.props.auth.isAuthenticated && renderFooter}
        </Router>
        <Toast />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

export default connect(mapStateToProps, {})(App);
