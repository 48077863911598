import React from "react";
function OpenStep(props) {
  function fltr() {
    let arrayOfLabels = props?.data?.step?.answers?.map((val) => val?.answer);
  }

  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 20 }}>
          <span className="top-component-fine-black-style">
            {props?.data?.step?.question}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div>
          <strong> {props?.data?.step?.answers?.length} réponses</strong>
        </div>
        <div>
          <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
            Question ouverte
          </span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        {props?.data?.step?.answers
          ?.map((val) => val?.answer)
          .map((d) => {
            return (
              <div
                style={{
                  height: 50,
                  width: 500,
                  backgroundColor: "#f0f0f0",
                  borderRadius: 10,
                  margin: 10,
                  padding: 10,
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                {d}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default OpenStep;
