import React from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Button, Input, Label, ButtonGroup } from 'reactstrap'
import TextField from '@material-ui/core/TextField';
import Eye from '../../../assets/eye.svg'
import Edit from '../../../assets/insightdata_modifier.svg'
import ReactTooltip from 'react-tooltip';
const StepContainer = (props) => {
    const { type, etape, edit, drop, inputs, scaleRange, onChangeStep,list, data, add, substruct, btns, addStep, visualise, duplicate, step, onchange, onChangeResponse, response, onChangeType } = props
    return (
        <div >
            {/* <span>Etape {etape}</span> */}
            <div className='step_container'>
                <Col md='3'>
                    {/* <br /> */}
                    <Label className='label__step' >Url:</Label>
                    <br />
                    <Label className='label__step'>Instruction:</Label>
                    <br />
                    <Label className='label__step'>Question:</Label>

                    {/* <br />
                    <br />
                    <Label>Réponse:</Label> */}
                </Col>
                <Col md='6'>
                    <AvForm >
                        <AvField id="url__style" name='url'
                            value={step.url} onChange={onChangeStep}
                            placeholder='https://www.exemple.com/' />
                        <AvField
                            placeholder='(Facultatif)'
                            value={step.instruction}
                            onChange={onChangeStep}
                            type="textarea"
                            name="instruction"
                            id="url__style"
                        />

                        <AvField
                            placeholder='Saisissez votre question'
                            value={step.question}
                            onChange={onChangeStep}
                            type="textarea"
                            name="question"
                            id="url__style"
                        />
                        {type === "open" &&
                            <AvField
                                placeholder='Réponse longue'
                                type="text"
                                name="reponse"
                                id="resp__style"
                                disabled
                            />
                        }
                        {type === "scale" &&
                            <div className='display__flex__row'>
                                <Input
                                    value={scaleRange.minScale}
                                    type="number"
                                    onChange={onchange}
                                    name="minScale"
                                    id="scale__range"

                                />
                                <span style={{ margin: "0 1em" }}>à</span>
                                <Input
                                    value={scaleRange.maxScale}
                                    type="number"
                                    onChange={onchange}
                                    name="maxScale"
                                    id="scale__range"

                                />
                            </div>
                        }
                        {type === "scale" && <div className='display__flex__col' style={{ width: '40%' }}>
                            <TextField />
                            <TextField />
                        </div>
                        }
                        {type === "close" && <>
                            <div style={{ flexDirection: 'row', display: 'flex', marginTop: '2em' }}>
                                <Label className='radio__label' >
                                    <Input type="radio" disabled />
                                    reponse 1
                                </Label>
                                <AvField
                                    // style={{ width: '10em' }}
                                    placeholder=''
                                    value={response.choice1}
                                    type="text"
                                    onChange={onChangeResponse}
                                    name="choice1"
                                    id="url__style"
                                />
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <Label className='radio__label'>
                                    <Input type="radio" disabled />
                                    reponse 2
                                </Label>
                                <AvField
                                    // style={{ width: '10em' }}
                                    placeholder=''
                                    value={response.choice2}
                                    type="text"
                                    onChange={onChangeResponse}
                                    name="choice2"
                                    id="url__style"
                                />
                            </div>
                            {inputs.map((input, index) =>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Label >
                                        <Input type="radio" />
                                        reponse {index + 3}
                                    </Label>
                                    <AvField
                                        // style={{ width: '10em' }}
                                        placeholder=''
                                        value={Response.choice}
                                        type="text"
                                        onChange={onChangeResponse}
                                        name="username"
                                        id="url__style"
                                    />
                                </div>)}
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <span className='add__option'>Ajouter une option</span>
                            </div>
                        </>}
                    </AvForm>
                </Col>
                <Col md='3'>
                    <Input type="select" name="select" id="url__style"
                        onChange={onChangeType}
                    >
                        {[
                            //{ label: "Cases à cocher", type: '' },
                            { label: "Choix multiples", type: 'close' }, { label: "Echelle de notation", type: 'scale' }, { label: "Question ouverte", type: 'open' }].map((option, idx) => {

                                return <option key={idx} value={option.type} selected>{option.label}</option>
                            })
                        }
                    </Input>

                    <div className='icons__style'>
                        {/* <img src={Eye} alt="" /> */}
                        {list.length===data.length&&<><i className="fa fa-eye fa-lg mr-4" data-tip="visualiser"
                            // data-background-color="#002C3F " 
                            aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => visualise(etape)} />
                        <i className="fa fa-files-o fa-lg mr-4" data-tip="dupliquer"
                            //  data-background-color="#002C3F "
                            aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => duplicate()} /></>}
                        <i className="fa fa-trash-o fa-lg mr-4" data-tip="supprimer"
                            // data-background-color="#002C3F " 
                            aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => drop(etape - 1)} />
                        <i className="fa fa-check fa-lg" data-tip="valider"
                            // data-background-color="#002C3F " 
                            aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => addStep()} />
                    </div>
                </Col>
                <ReactTooltip />
            </div>
            {/* <Row>
                <span>Etape {etape}</span>
            </Row>
            <Row>

                <AvForm className='url__style'>
                    <Label className='mr-2'>Url:</Label>
                    <AvField id="url__input" name='url' value={step.url} onChange={onchange} placeholder='https://www.exemple.com/' />
                </AvForm>

            </Row>
            <Row>
                {type === "open" && <div className='step__ctn'>
                    <Col md='3'>
                        <Label>Instruction:</Label>
                        <br />
                        <br />
                        <Label>Question:</Label>
                        <br />
                        <br />
                        <Label>Réponse:</Label>
                    </Col>
                    <Col md='8'>
                        <AvForm >
                            <AvField
                                placeholder='(Facultatif)'
                                value={step.instruction}
                                type="text"
                                onChange={onchange}
                                name="instruction"
                                id="inst__open"
                            />

                            <AvField
                                placeholder='Saisissez votre question'
                                value={step.question}
                                type="text"
                                onChange={onchange}
                                name="question"
                                id="quest__open"
                            />
                        </AvForm>

                        <Input
                            placeholder=''
                            type="text"
                            name="response"
                            id="res__open"
                            disabled
                        />
                    </Col>
                    <Row style={{ margin: '-9em -2em 0 0' }}>
                        <img className='btn__edit' src={Edit} alt="" clickable />
                        <img className='btn__delete' src={Delete} alt="" onClick={() => drop(etape-1)} clickable />
                    </Row>
                </div>}
                {type === "close" && <div className='step__ctn'>
                    <Col md='3' style={{ marginTop: '-7em' }}>
                        <Label>Instruction:</Label>
                        <br />
                        <br />
                        <Label>Question:</Label>
                        <br />
                        <br />
                        <Label>Réponse:</Label>
                    </Col>
                    <Col md='8'>
                        <AvForm >
                            <div >
                                <AvField
                                    placeholder='(Facultatif)'
                                    value={step.instruction}
                                    type="text"
                                    onChange={onchange}
                                    name="instruction"
                                    id="inst__open"
                                />
                            </div>

                            <div >
                                <AvField
                                    placeholder='Saisissez votre question'
                                    value={step.question}
                                    type="text"
                                    onChange={onchange}
                                    name="question"
                                    id="quest__open"
                                />
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', marginTop: '2em' }}>
                                <Label >
                                    <Input type="radio" disabled />
                                reponse 1
                            </Label>
                                <AvField
                                    style={{ width: '10em' }}
                                    placeholder=''
                                    value={response.choice1}
                                    type="text"
                                     onChange={onChangeResponse}
                                    name="choice1"
                                    id="inst__open"
                                />
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <Label >
                                    <Input type="radio" disabled />
                                reponse 2
                            </Label>
                                <AvField
                                    style={{ width: '10em' }}
                                    placeholder=''
                                    value={response.choice2}
                                    type="text"
                                     onChange={onChangeResponse}
                                    name="choice2"
                                    id="inst__open"
                                />
                            </div>
                            {inputs.map((input, index) =>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Label >
                                        <Input type="radio" />
                                     reponse {index + 3}
                                    </Label>
                                    <AvField
                                        // style={{ width: '10em' }}
                                        placeholder=''
                                        //value={Response.choice}
                                        type="text"
                                         onChange={onChangeResponse}
                                        name="username"
                                        id="inst__open"
                                    />
                                </div>)}
                        </AvForm>
                        <div style={{ flexDirection: 'row', display: 'flex', marginLeft: '20em' }}>
                            <Button className='btn__scale mr-4' outline color='success' onClick={() => substruct()} >-</Button>
                            <Button className='btn__scale' outline color='success' onClick={() => add()} >+</Button>
                        </div>

                    </Col>
                    <Row style={{ margin: '-16.5em -2em 0 0' }}>
                        <img className='btn__edit' src={Edit} alt="" />
                        <img className='btn__delete' src={Delete} onClick={() => drop(etape-1)} alt="" />
                    </Row>
                </div>}
                {type === "scale" && <div className='step__ctn'>
                    <Col md='3'>
                        <Label>Instruction:</Label>
                        <br />
                        <br />
                        <Label>Question:</Label>
                        <br />
                        <br />
                        <Label>Réponse:</Label>
                    </Col>
                    <Col md='8'>
                        <AvForm>
                            <AvField
                                placeholder='(Facultatif)'
                                value={step.instruction}
                                type="text"
                                onChange={onchange}
                                name="instruction"
                                id="inst__open"
                            />

                            <AvField
                                placeholder='Saisissez votre question'
                                value={step.question}
                                type="text"
                                onChange={onchange}
                                name="question"
                                id="quest__open"
                            />
                        </AvForm>

                        <ButtonGroup style={{ width: '100%' }}>
                            {btns.map((obj, idx) => <Button className='btn__scale' style={{ backgroundColor: `white`, borderColor: '#00a359', color: '#002C3F' }}>{obj.btn}</Button>)}
                        </ButtonGroup>
                        <br />
                        <Row>
                            <Col style={{ textAlign: 'start' }}>
                                <i className="fa fa-thumbs-down" style={{ color: 'red' }}></i>
                                <Label className='ml-2'>Mauvais</Label>
                            </Col>
                            <Col style={{ textAlign: 'end' }}>
                                <Label className='mr-2'>Bon</Label>
                                <i className="fa fa-thumbs-up" style={{ color: 'green' }}></i>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ margin: '-11em -2em 0 0' }}>
                        <img className='btn__edit' src={Edit} alt="" />
                        <img className='btn__delete' src={Delete} onClick={() => drop(etape-1)} alt="" />
                    </Row>
                </div>}
            </Row>
            <Row>
                <Col>
                    <Button className='toolBox__button' onClick={() => duplicate()}>Dupliquer</Button>
                </Col>
                <Col>
                    <Button className='toolBox__button' onClick={() => visualise(etape)}>Visualiser</Button>
                </Col>
                <Col>
                    <Button className='toolBox__button' onClick={() => addStep(type)}>Ajouter l'étape</Button>
                </Col>
            </Row> */}
        </div>
    )
}
export default StepContainer;