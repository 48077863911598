import {
    SUCCESS_SAVE_TESTER,
    FAILED_SAVE_TESTER,
    SUCCESS_SAVE_CLIENT,
    FAILED_SAVE_CLIENT,
    SUCCESS_GET_USER_CONNECTED,
    FAILED_GET_USER_CONNECTED,
    SUCCESS_GET_CLIENTS,
    FAILED_GET_CLIENTS,
    SUCCESS_GET_ADMINS,
    FAILED_GET_ADMINS,
    FAILED_SAVE_ADMIN,
    FAILED_SAVE_SUBCLIENT,
    SUCCESS_GET_TESTERS,
    FAILED_GET_TESTERS,
    FAILED_GET_SUBCLIENTS,
    SUCCESS_GET_SUBCLIENTS
} from "../typeActions/user";

import {
    saveTester, saveClient, getAdmins,getSubClients,saveSubClient,
    getConnectedUser, getClients, saveAdmin, getTesters
} from "../services/userServices"

export const onSaveTester = (user) => {
    return dispatch => {
        saveTester(user).then(res => {
            dispatch(succesSaveTester(user, dispatch))
        })
            .catch(err => {
                failedSaveTester(err, dispatch)
            })
    }
};
export const onSaveClient = (user) => {
    return dispatch => {
        saveClient(user).then(res => {
            dispatch(succesSaveClient(user, dispatch))
        })
            .catch(err => {
                failedSaveClient(err, dispatch)
            })
    }
};
export const onSaveAdmin = (user) => {
    return dispatch => {
        saveAdmin(user).then(res => {
            getAdmins().then(admins =>
                dispatch(successGetAdmins(admins, dispatch)))
                .catch(err => { failedGetAdmins(err, dispatch) })
        })
            .catch(err => {
                failedSaveAdmin(err, dispatch)
            })
    }
};
export const onSaveSubClient = (user) => {
    return dispatch => {
        saveSubClient(user).then(res => {
            getSubClients().then(subClients =>
                dispatch(successGetSubClients(subClients, dispatch)))
                .catch(err => { failedGetSubClients(err, dispatch) })
        })
            .catch(err => {
                failedSaveSubClient(err, dispatch)
            })
    }
};

export const getUserConnected = () => {
    return dispatch => {
        getConnectedUser().then(user =>
            dispatch(successGetUserConnected(user, dispatch)))
            .catch(err => { failedGetUserConnected(err, dispatch) })
    }
}
export const onGetClients = () => {
    return dispatch => {
        getClients().then(users =>
            dispatch(successGetClients(users, dispatch)))
            .catch(err => { failedGetClients(err, dispatch) })
    }
}
export const onGetAdmins = () => {
    return dispatch => {
        getAdmins().then(admins =>
            dispatch(successGetAdmins(admins, dispatch)))
            .catch(err => { failedGetAdmins(err, dispatch) })
    }
}
export const onGetTesters = () => {
    return dispatch => {
        getTesters().then(testers =>
            dispatch(successGetTesters(testers, dispatch)))
            .catch(err => { failedGetTesters(err, dispatch) })
    }
}
export const onGetSubClients = () => {
    return dispatch => {
        getSubClients().then(subClients =>
            dispatch(successGetSubClients(subClients, dispatch)))
            .catch(err => { failedGetSubClients(err, dispatch) })
    }
}


const successGetUserConnected = (user, dispatch) => {
    dispatch({ type: SUCCESS_GET_USER_CONNECTED, user });
}

const failedGetUserConnected = (error, dispatch) => {
    dispatch({ type: FAILED_GET_USER_CONNECTED, payload: error });
}
const successGetClients = (users, dispatch) => {
    dispatch({ type: SUCCESS_GET_CLIENTS, users });
}

const failedGetClients = (error, dispatch) => {
    dispatch({ type: FAILED_GET_CLIENTS, payload: error });
}
const successGetAdmins = (admins, dispatch) => {
    dispatch({ type: SUCCESS_GET_ADMINS, admins });
}

const failedGetAdmins = (error, dispatch) => {
    dispatch({ type: FAILED_GET_ADMINS, payload: error });
}
const successGetSubClients = (subClients, dispatch) => {
    dispatch({ type: SUCCESS_GET_SUBCLIENTS, subClients });
}

const failedGetSubClients = (error, dispatch) => {
    dispatch({ type: FAILED_GET_SUBCLIENTS, payload: error });
}
const successGetTesters = (testers, dispatch) => {
    dispatch({ type: SUCCESS_GET_TESTERS, testers });
}

const failedGetTesters = (error, dispatch) => {
    dispatch({ type: FAILED_GET_TESTERS, payload: error });
}
const failedSaveSubClient = (error, dispatch) => {
    dispatch({ type: FAILED_SAVE_SUBCLIENT, payload: error });
}

export const succesSaveTester = (user, dispatch) => {
    dispatch({ type: SUCCESS_SAVE_TESTER, user });
}

export const failedSaveTester = (error, dispatch) => {
    dispatch({ type: FAILED_SAVE_TESTER, payload: error.response && error.response.data });
}
export const succesSaveClient = (user, dispatch) => {
    dispatch({ type: SUCCESS_SAVE_CLIENT, user });
}

export const failedSaveClient = (error, dispatch) => {
    dispatch({ type: FAILED_SAVE_CLIENT, payload: error.response && error.response.data });
}
export const failedSaveAdmin = (error, dispatch) => {
    dispatch({ type: FAILED_SAVE_ADMIN, payload: error.response && error.response.data });
}
