import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());


export const getScenarioAnalyses = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/analyze/scenario/${id}`, {
        headers: getAuthorization()
    })
    const { data } = scenario
    return data;
}
export const getStepAnalyses = async (id) => {
    const step = await axios.get(apiEndpoint + `/api/analyze/step/${id}`, {
        headers: getAuthorization()
    })
    const { data } = step
    return data;
}
export const getTesterAnalyses = async (id) => {
    const tester = await axios.get(apiEndpoint + `/api/analyze/test/${id}`, {
        headers: getAuthorization()
    })
    const { data } = tester
    return data;
}
export const getjourneyMapTester = async (id) => {
    const journeyMap = await axios.get(apiEndpoint + `/api/analyze/testers/scenario/${id}`, {
        headers: getAuthorization()
    })
    const { data } = journeyMap
    return data;
}
export const getTestId = async (id) => {
    const test = await axios.get(apiEndpoint + `/api/tests/scenario/${id}`, {
        headers: getAuthorization()
    })
    const { data } = test
    return data;
}
export const getTestById = async (id) => {
    const test = await axios.get(apiEndpoint + `/api/tests/${id}`, {
        headers: getAuthorization()
    })
    const { data } = test
    return data;
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}
function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}