import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../../services/authServices";
import { getAuthorization } from "../../../../services/userServices";
import http from "../../../../services/httpServices";
import { apiUrl } from "../../../../config.json";
import axios from "axios";
import Multiselect from 'multiselect-react-dropdown';


const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
function WebEq() {
  const [user, setUser] = React.useState({});
const[options,setOptions] = React.useState([{name: 'Windows XP ou inférieur', id: 1},{name: 'Windows Vista', id: 2},{name: 'Windows Phone', id: 3},{name: 'Windows 8', id: 4},{name: 'Windows 10 ou supérieur', id: 5},{name: 'Symbian', id: 6},{name: 'Linux', id: 7},{name: 'Kindle', id: 8},{name: 'iOS 11 ou supérieur', id: 9},{name: 'iOS 7 ou inférieur', id: 10},{name: 'BlackBerry', id: 11},{name: 'Android 6 ou supérieur', id: 12}])
  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);
  return (
    <Col lg="7" md="7" className="p-3 main">
      <Form>
        <Row>
        <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
              Quels réseaux sociaux utilisez-vous ? </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Je n’utilise pas les réseaux sociaux</option>
                <option>Instagram</option>
                <option>Snapchat</option>
                <option>YouTube</option>
                <option>Facebook</option>
                <option>LinkedIn</option>
              </Input>
            </div>

          </Col>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>A quelle fréquence achetez-vous sur Internet ?  </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Au moins 1 fois par mois</option>
                <option>Au moins 1 fois tous les 3 mois</option>
                <option>Au moins 1 fois tous les 6 mois</option>
                <option>Au moins 1 fois par an</option>
                <option>Jamais</option>
              </Input>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}>
              Avec-vous déjà acheté sur internet avec votre téléphone mobile ?
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Oui</option>
                <option>Non</option>
            
              </Input>
            </div>
          </Col>
          <Col>
            <div className="column-left">
              <Label style={{ fontWeight: "bold" }}> Combien de temps passez-vous sur Internet par jour ? 
              </Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Moins de 5 minutes</option>
                <option>5 à 30 minutes</option>
                <option>30 à 60 minutes</option>
                <option>1-3 heures</option>
                <option>3 heures et plus</option>
              </Input>
            </div>
          </Col>

 
        </Row>
        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', paddingTop:20}}>
        <Col lg='6'>
            <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start',marginLeft:-10}}>
            <span style={{fontWeight:'bold'}}> Votre systémes d'exploitation</span>
            </div> 
            <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start',marginLeft:-10, paddingTop:10}}>

          <Multiselect
options={options} // Options to display in the dropdown
selectedValues={[]} // Preselected value to persist in dropdown
displayValue="name" // Property name to display in the dropdown options
placeholder="Systémes d'exploitation"
/>
</div>
          </Col>
          </div>

        <Col
          md="12"
          lg="12"
          className="d-flex justify-content-end no-margin no-padding p-3 m-3"
        >
          <Button className="btn-success"> ENREGISTRER</Button>
        </Col>
      </Form>
    </Col>
  );
}

export default WebEq;
