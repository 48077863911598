import {
    EMAIL_CHANGED,
    PASSWORD_CHANGED,
    LOGOUT, FAILED_LOGIN,
    SUCCESS_LOGIN,
    TAB_DASHBOARD_CHANGED,
    LNG_CHANGED,
} from "./../typeActions/auth";

const INITIAL_STATE = {
    isAuthenticated: false,
    username: "",
    password: "",
    user: {},
    lng: 'Fr',
    tab: "0",
    currentUserId: "",
    loading: true,
    error: "",
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LNG_CHANGED:
            return {
                ...state,
                lng: action.payload
            }
        case TAB_DASHBOARD_CHANGED:
            return {
                ...state,
                tab: action.payload
            }

        case EMAIL_CHANGED:
            return {
                ...state,
                username: action.payload,
                error: ""
            };
        case PASSWORD_CHANGED:
            return {
                ...state,
                password: action.payload,
                error: ""
            };
        case LOGOUT:
            return {
                isAuthenticated: false,
                username: "",
                password: "",
                user: {},
                lng: 'Fr',
                currentUserId: "",
                loading: true,
                error: ""
            };
        case SUCCESS_LOGIN:
            return {
                ...state,
                username: "",
                password: "",
                isAuthenticated: true,
                user: action.user,
                currentUserId: action.user.user_id,
                loading: false
            };
        case FAILED_LOGIN:
            return {
                ...state,
                password: "",
                error: action.payload
            };
        default:
            return state;
    }
};
