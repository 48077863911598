import React from "react";
import Table from "../../common/table";
import { connect } from "react-redux";
import { onGetClients } from "../../../actions/userActions";
import ShowMore from "../../../assets/insightdata_consulter.svg";
import UserDetails from "./userDetails";
import Modals from "../../common/modal";
import { validateClient } from "../../../services/userServices";
import moment from "moment";

class DashboardAdmin extends React.Component {
  state = {
    data: [],
    details: {},
    show: false,
  };

  componentDidMount() {
    if (!this.props.user.users[0]) this.props.onGetClients();
    else {
      let clients = [];
      this.props.user.users.map((client) => {
        clients = [
          ...clients,
          {
            createdAt: moment(client.createdAt).format("L"),
            lastname: client.lastname,
            id: client.id,
            name: client.name,
            company: client.company,
            phone: client.phone,
            email: client.email,
            useCase: client.useCase,
            state: client.state,
            color:
              moment(new Date()).format("L") >
                moment(client.createdAt).format("L") + "02" ||
              moment(new Date()).isAfter(client.createdAt, "year")
                ? "red"
                : "",
          },
        ];
      });
      this.setState({ data: clients });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user.users !== nextProps.user.users) {
      let clients = [];
      nextProps.user.users.map((client) => {
        clients = [
          ...clients,
          {
            createdAt: client.createdAt,
            lastname: client.lastname,
            id: client.id,
            name: client.name,
            company: client.company,
            phone: client.phone,
            email: client.email,
            useCase: client.useCase,
            state: client.state,
            color:
              moment(new Date()).format("L") >
                moment(client.createdAt).format("L") + "02" ||
              moment(new Date()).isAfter(client.createdAt, "year")
                ? "red"
                : "",
          },
        ];
      });
      this.setState({ data: clients });
    }
  }
  showDetails = (data) => {
    this.setState({
      show: !this.state.show,
      details: !this.state.show && data,
    });
  };
  activate = () => {
    validateClient(this.state.details.id).then((res) => {
      this.props.onGetClients();
      this.setState({ show: !this.state.show });
    });
  };
  renderClientDetails() {
    return (
      <Modals
        modalSize="modal-xl"
        show={this.state.show}
        toggleShow={this.showDetails}
        header={this.state.details.name}
      >
        <UserDetails
          data={this.state.details}
          role="ROLE_CLIENT"
          activate={this.activate}
        />
      </Modals>
    );
  }
  renderTable = () => {
    const columns = [
      { title: "Date", field: "createdAt" },
      { title: "Nom", field: "lastname" },
      { title: "Prémon", field: "name" },
      { title: "Société", field: "company" },
      { title: "Téléphone", field: "phone" },
      { title: "Mail", field: "email" },
      { title: "Cas d'utilisation", field: "useCase" },
      {
        title: "Etat",
        field: "state",
        render: (rowData) => (
          <span style={{ color: `${rowData.color}` }}>
            {rowData.state === "to_contact" ? "A contacter" : "Ok"}
          </span>
        ),
      },
    ];
    const actions = [
      {
        icon: () => <img style={{ width: "30px" }} src={ShowMore} alt="" />,
        tooltip: "Détails",
        onClick: (event, rowData) => this.showDetails(rowData),
      },
    ];

    return (
      <Table
        title="Liste des clients"
        columns={columns}
        data={this.state.data}
        actions={actions}
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderTable()}
        {this.renderClientDetails()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

export default connect(mapStateToProps, { onGetClients })(DashboardAdmin);
