import React from "react";
import Table from "../../common/table";
import maleAvatar from "../../../assets/male-avatar.jpeg";
import go from "../../../assets/go.png";
import { Col, Row } from "reactstrap";
import ModalForm from "./ModalForm";
import { getCurrentUser, getJwt } from "../../../services/authServices";
import { getAuthorization } from "../../../services/userServices";
import http from "../../../services/httpServices";
import { apiUrl } from "../../../config.json";
import { Link } from "react-router-dom";
import axios from "axios";
import UserAvatar from "react-user-avatar";
import Avatar from 'react-avatar';

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());

const DashboardPageClientTester = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [scenarios, setScenarios] = React.useState([]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  

  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log('user',response)
      })
      .catch((error) => {
        console.log(error, "err");
      });

    axios
    .get(apiEndpoint + "/api/scenario", {
      headers: getAuthorization(),
    })
    .then((response) => {
    //  setScenarios(response && response.data);
      setScenarios((scenarios) => [
        ...scenarios,
        ...(response && response.data),
      ]);
      setTimeout(() => {
        console.log(response && response.data);
      }, 4000);
    })

      // .get(apiEndpoint + "/api/stats/scenario/15", {
      //   headers: getAuthorization(),
      // })
        // setScenarios((scenarios) => [
        //   ...scenarios,
        //   ...(response && response.data),
        // ]);
 
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);

  function handleClose() {
    setVisible(!visible);
  }
  function openModalQuiz(id) {
    props.history.push(`/client-tester/questions/${id}`);
    console.log(id);
  }
  function handleNavigation(id) {
    <Link to={`/tester/questions/${id}`} />;
    console.log(id);
  }
  function navigateProfile(){
    <Link to={`/client-tester/profile`} />;
  }
  const renderTable = () => {
    const columns = [
      { title: "Numéro de scénario", field: "id" },
      { title: "Nom du scénario", field: "title" },
      { title: "Date de création", field: "createdAt" },
      { title: "Type", field: "moderate" },
      { title: "Etat", field: "etat" },
    ];
    const actions = [
      {
        icon: (event, rowData) => (
          <Link to={`/client-tester/questions/${rowData?.id}`}>
            <img style={{ width: "30px" }} src={go} alt="" />
          </Link>
        ),
        //  icon: (event, rowData) =>

        tooltip: "commencer le quiz",
        onClick: (event, rowData) => {
          openModalQuiz(rowData?.id);
        },

        //  onClick: (event, rowData) =><Link to={`/tester/questions/${rowData?.id}`}> .</Link>
      },
    ];

    // actions={[
    //   rowData => ({
    //     icon: () => <Link to={`/tester/questions/${rowData?.id}`}>Edit(Replace with icon code)</Link>,
    //     tooltip: 'Edit ',
    //     onClick: (rowData)
    //   })
    // ]};

    return (
      <Table
        title="LISTE DES SCENARIOS"
        columns={columns}
        data={scenarios}
        actions={actions}
      />
    );
  };

  return (
    <React.Fragment>
      <ModalForm visible={visible} handleClose={handleClose} />
      <Col
        lg="12"
        md="12"
        className="d-flex flex-wrap justify-content-between align-items-end"
        style={{marginTop:'3em'}}
      >
        <div className="ml-5 mt-5 mb-5">
          <span className="welcome-text">
            Bienvenue {user?.data?.name} !
          </span>
        </div>

       
      </Col>

      <div
        style={{
          height: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: 150,
            width: 600,
            backgroundColor: "#F8F8F8",
            borderRadius: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginLeft:60
          }}
        >
          <div
            style={{
              height: "100%",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "30%",
                }}
              >
                <span style={{ fontSize: 17}}>
                  Scénarios
                </span>
                <span
                  style={{
                    fontSize: 17,
                  }}
                >
                  à tester
                </span>
              </div>
              <span
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                1
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 17}}>
                Scénarios
              </span>
              <span
                style={{
                  fontSize: 17,
                }}
              >
                Terminé
              </span>
            </div>
            <span
              style={{ fontSize: 30, fontWeight: "bold"}}
            >
              0
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 17}}>
                Scénarios
              </span>
              <span style={{ fontSize: 17}}>
                Fermés
              </span>
            </div>
            <span style={{ fontSize: 30, fontWeight: "bold"}}>
              0
            </span>
          </div>
        </div>
       
        <div
          style={{
            height: 150,
            width: 200,
            backgroundColor: "#F8F8F8",
            borderRadius: 10,
            marginRight: 70,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
              alignItems:'center',

            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "30%",
              }}
            >
              <span style={{ fontSize: 17, fontWeight: "bold" }}>Profil</span>
              <Avatar size="65" name={user?.data?.name +" "+ user?.data?.lastname} color="#50C878	" round={true} />

            </div>
            <div style={{paddingTop:40, display:'flex', flexDirection:'column'}}>
            <span style={{ fontSize: 15, color: "black" }}>
            {user?.data?.name} {user?.data?.lastname}
            </span>
            <Link to={`/client-tester/profile`}>
            <span style={{ fontSize: 15, color: "blue" }}> 
            Modifier
            </span>
            </Link>
            </div>
           
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          marginTop: 20,
          paddingBottom: 50,
          justifyContent: "center",
          paddingLeft: "5%",
        }}
      >
        {renderTable()}
      </div>
    </React.Fragment>
  );
};
export default DashboardPageClientTester;
