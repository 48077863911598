import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../../services/authServices";
import { getAuthorization } from "../../../../services/userServices";
import http from "../../../../services/httpServices";
import { apiUrl } from "../../../../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());

function Display({ sex,date }) {

    return (
      <>
        <Col>
          <div className="column-left">
            <Label style={{ fontWeight: "bold" }}>Genre</Label>
            <Input type="select" name="select" id="exampleSelect">
              <option>Homme</option>
              <option>Femme</option>
            </Input>
          </div>
        </Col>
        <Col>
          <div className="column-left">
            <Label style={{ fontWeight: "bold" }}>Date de naissance</Label>
            <Input
              type="date"
              name="date"
              id="exampleDate"
              placeholder="Date"
            />
          </div>
        </Col>
      </>
    );
  }

function SitFam() {
  const [counter, setCounter] = useState(0);
  const [user, setUser] = React.useState({});
  const [kids,setKids] = React.useState([{
      sex:'',
      date:''
  }])
  const incrementCounter = () => {
      setKids([...kids,{sex:'',date:''}])
  };
  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, []);
  return (
    <Col lg="7" md="7" className="p-3 main">
      <Form>
        <Row>
          <Col lg="6">
            <div className="column-left-1">
              <Label style={{ fontWeight: "bold" }}>Situation maritale</Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>Marié(e)</option>
                <option>Pacsé(e)</option>
                <option>Célibataire</option>
                <option>Séparé(e)</option>
                <option>Divorcé(e)</option>
                <option>Veuf(ve)</option>
              </Input>
            </div>
          </Col>
        </Row>
        <Col
          style={{
            marginLeft: -15,
            paddingTop: 20,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            Genre et année de naissance de vos enfants{" "}
          </span>
          <div style={{ paddingLeft: 30, paddingTop: -10 }}>
            <span
              style={{ fontSize: 20, fontWeight: "bold" }}
              onClick={() => {
                incrementCounter();
              }}
            >
              +
            </span>
          </div>
        </Col>
        <Row style={{ paddingTop: 20 }}>
            {kids.map((kid,index)=>{
                return(
                    <Display {...kid} />

                )
            })}
        </Row>

        <Col md="12" lg="12" className="d-flex justify-content-end p-3 m-3">
          <Button className="btn-success"> ENREGISTRER</Button>
        </Col>
      </Form>
    </Col>
  );
}

export default SitFam;
