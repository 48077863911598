import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());



export const getConnectedUser = async () => {
    const user = await axios.get(apiEndpoint + "/api/user", {
        headers: getAuthorization()
    })
    const { data } = user
    return data;
}
export const getSubClients = async () => {
    const subClients = await axios.get(apiEndpoint + "/api/subclients", {
        headers: getAuthorization()
    })
    const { data } = subClients
    return data;
}
export const getClients = async () => {
    const Clients = await axios.get(apiEndpoint + "/api/clients", {
        headers: getAuthorization()
    })
    const { data } = Clients
    return data.reverse();
}
export const getAdmins = async () => {
    const admins = await axios.get(apiEndpoint + "/api/admins", {
        headers: getAuthorization()
    })
    const { data } = admins
    return data;
}
export const getTesters = async () => {
    const testers = await axios.get(apiEndpoint + "/api/testers", {
        headers: getAuthorization()
    })
    const { data } = testers
    return data.reverse();
}
export const validateClient = async (id) => {
    const client = await axios.get(apiEndpoint + `/api/clients/${id}/validate`, {
        headers: getAuthorization()
    })
    const { data } = client
    return data;
}
export const validateTester = async (id) => {
    const tester = await axios.get(apiEndpoint + `/api/testers/${id}/validate`, {
        headers: getAuthorization()
    })
    const { data } = tester
    return data;
}
export async function deleteAdmin(id) {
    const res = await axios.delete(apiEndpoint +`/api/admins/${id}`, { headers: getAuthorization() });
    return res
  }
export async function deleteSubClient(id) {
    const res = await axios.delete(apiEndpoint +`/api/subclients/${id}`, { headers: getAuthorization() });
    return res
  }
  
export async function saveTester(user) {
    const res = await http.post(apiEndpoint + "/signup-tester", user);
    return res;
}
export async function saveClient(user) {
    const res = await http.post(apiEndpoint + "/signup-client", user);
    return res;
}
export async function saveAdmin(user) {
    const res = await http.post(apiEndpoint + "/api/admins", user, {
        headers: getAuthorization()
    });
    return res;
}
export async function saveSubClient(user) {
    const res = await http.post(apiEndpoint + "/api/subclients", user, {
        headers: getAuthorization()
    });
    return res;
}
export async function updatePwd(pwd) {
    const res = await http.post(apiEndpoint + "/api/changePassword", pwd, {
        headers: getAuthorization()
    });
    return res;
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}
export function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}
