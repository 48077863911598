import React from "react";
import BarChart from "./BarCharts";
// function ClosedBarComponent({ labels, question, data }) {
function ClosedBarComponent(props) {
  const [labels, setLabels] = React.useState([]);
  const [data, setData] = React.useState([]);
  const fillData = () => {
    setLabels(props?.data?.step?.answers?.map((val) => val?.answer));
    setData(props?.data?.step?.answers?.map((val) => val?.count));
  };
  React.useEffect(() => {
    fillData();
  }, []);
  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.count;
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 20 }}>
          <span className="top-component-fine-black-style">
            {props?.data?.step?.question}
          </span>
        </div>
      </div>
      <div>
        <strong>
          {props?.data?.step?.answers.reduce(reducer, 0)} réponses
        </strong>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="chart-container">
          <BarChart {...{ labels, data }} />
        </div>
      </div>
    </div>
  );
}

export default ClosedBarComponent;
