import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../services/authServices";
import { getAuthorization } from "../../../services/userServices";
import http from "../../../services/httpServices";
import { apiUrl } from "../../../config.json";
import axios from "axios";
import DashboardIcon from "../../../assets/insightdata_tableaudebord.svg";
import SenarioIcon from "../../../assets/insightdata_senario.svg";
import ProfileIcon from "../../../assets/insightdata_profil.svg";
import InfosPers from "./infos/InfosPers";
import SitFam from "./infos/SitFam";
import SitProf from "./infos/SitProf";
import WebEq from "./infos/WebEq";
import ActL from './infos/ActL';
const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
const Info = (props) => {
  const [user, setUser] = React.useState({});
  const [selectedId, setSelectedId] = useState(1);
  const infoData = [
    { name: "Informations personnelles", id: 1 },
    { name: "Situation familiale", id: 2 },
    { name: "Situation profesionnelle", id: 3 },
    { name: "Web et équipements", id: 4 },
    { name: "Activités et loisirs", id: 5 },
  ];

  React.useEffect(() => {
    axios
      .get(apiEndpoint + "/api/currentuser", {
        headers: getAuthorization(),
      })
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "err");
      });
  }, [selectedId]);

  function selectedIdHandler(_id) {
    console.log("in", _id);
    setSelectedId(_id);
  }
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#f1f1f1",
          height: 140,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div className="profile-box">
          <img
            style={{ width: "60px", height: "48px" }}
            src={DashboardIcon}
            alt=""
          />
          <span className="profile-card">Tableau de bord</span>
        </div>
        <div className="profile-box">
          <img
            style={{ width: "60px", height: "48px" }}
            src={SenarioIcon}
            alt=""
          />

          <span className="profile-card">Documents</span>
        </div>
        <div className="profile-box">
          <img
            style={{ width: "60px", height: "48px" }}
            src={ProfileIcon}
            alt=""
          />

          <span className="profile-card">Profil</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          padding: 40,
          backgroundColor: "#f1f1f1",
          height: 20,
          alignItems: "flex-start",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <span style={{ color: "green", fontWeight: "bold", fontSize: 22 }}>
          Mes informations testeur
        </span>
      </div>
      <Row className="bg-grey no-margin  p-3 top-bandeau   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);transition: 0.3s   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);">
        <Col
          lg="3"
          md="3"
          className=" d-flex main mr-2 card-padding-top align-items-start flex-column hh"
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ fontWeight: "bold", fontSize: 16 }}>
              {" "}
              Taux complétion :
            </span>
            <span> </span>
            <span style={{ fontWeight: "bold", fontSize: 18, color: "blue" }}>
              40%
            </span>
          </div>

          <div className="d-flex flex-column text-padding-top align-items-start justify-content-space-around">
            {infoData.map((info, index) => {
              return (
                <div
                  style={{ padding: 5 }}
                  key={info.id}
                  onClick={() => {
                    selectedIdHandler(info.id);
                  }}
                  className="select-id-handler"
                >
                  <span
                    style={
                      selectedId === info.id
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                  >
                    {" "}
                    {info.name}{" "}
                  </span>
                </div>
              );
            })}

            <hr />
          </div>
        </Col>
        {selectedId === 1 ? (
        <React.Fragment>
         <InfosPers />
        </React.Fragment>
      ) : selectedId === 2 ? (
        <SitFam />
      ) : selectedId ===3 ? (
       <SitProf/>
      ): selectedId ===4 ? (<WebEq/>):(<ActL/>)
        
      }
      </Row>
    </React.Fragment>
  );
};
export default Info;
