import React from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import Stripe from "../../../assets/Stripe.svg";
import { getCurrentUser, getJwt } from "../../../services/authServices";
import { getAuthorization } from "../../../services/userServices";
import { apiUrl } from "../../../config.json";
import axios from "axios";
import Cards from "react-credit-cards"
import { toast } from 'react-toastify';
import "react-credit-cards/es/styles-compiled.css";

const apiEndpoint = apiUrl;
const PK =
  "pk_test_51HbjZfHfp9Tnj7L7lUclfITl4fAGXuzIolP7qnZ5lKjX90S4lSFjk1ruzp6FcKte2WY4TkisJN52kRH445Y0yT5x00U3ffYOgi";
const URL = "https://api.stripe.com/v1/tokens";

const Iban = (props) => {
  const [user, setUser] = React.useState({});
  const [id, setId] = React.useState('');
  const [state, setState] = React.useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });
  function handleChange({ target: { name, value } }) {
    setState({
      ...state,
      [name]: value,
    });
  }

  React.useEffect(() => {
    axios
    .get(apiEndpoint + "/api/user", {
      headers: getAuthorization(),
    })
    .then((response) => {
      setId(response.data.id)
    })
    .catch((error) => {
      console.log(error, "err");
    });
  }, []);

  function senData() {
    const cardInfo = formatCreditCardDetails(state);
    const request = {
      headers: { Authorization: `Bearer ${PK}` },
      method: "POST",
    };
    return fetch(`${URL}?${cardInfo}&currency=eur`, request, {
      mode: "no-cors",
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res, id });
        axios.put(apiEndpoint + `/api/testers/${id}/iban`,{"stripeToken" : res?.id } , {
          headers: getAuthorization(),
        })
        .then((response) => {
          console.log("success");
          toast.success("Carte ajoutée avec succées");
        })
        .catch((error) => {
          console.log(error, id, "err");
         // toast.error("une erreur s'est produite");

        });
      });
  }
  const formatCreditCardDetails = ({ number, name, cvc, expiry }) => {
    const [month, year] = expiry.split("/");
    const card = {
      "card[number]": number,
      "card[exp_month]": month,
      "card[exp_year]": `20${year}`,
      "card[cvc]": cvc,
      "card[name]": name,
    };
    const cardInfo = Object.keys(card)
      .map((key) => key + "=" + card[key])
      .join("&");

    return cardInfo;
  };

  return (
    <React.Fragment>
      <Row className="bg-grey no-margin  p-2 m-2 top-bandeau   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);transition: 0.3s   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);">
        <Col
          lg="6"
          md="6"
          className="bank-card mr-2 card-padding-top no-padding no-margin"
        >
          <div className="bank-card-header ">
            <strong>Données bancaires</strong>
            <img style={{ height: 70, width: 220 }} src={Stripe} alt="" />
          </div>
          <div className="bank-card-body ">
            <span className="card-texts">
              Le transfert de vos gains est effectué par un système sécurisé de
              Stripe.
            </span>
            <span className="card-texts">
              1- Cela sert uniquement au versement de vos gains.
            </span>
            <span className="card-texts">2- Aucun débit ne sera effectué.</span>
            <span className="card-texts">
              3- Conformément à notre contrat de prestation de service testeur,
              pour les testeurs résidant à l'étranger tout virement vers des
              Comptes Stripe donnera lieu à des frais administratifs qui seront
              imputés sur le versement. Ces frais, à votre charge, seront
              automatiquement déduits de la somme transférée.
            </span>
            <div style={{ paddingTop: 20 }}>
              <span className="important-text">IMPORTANT</span>
            </div>
            <div style={{ paddingTop: 10 }}>
              <span className="card-texts">
                Vous devez également renseigner votre adresse postale pour que
                nous puissions procéder au versement de vos gains. Compléter ces
                informations.
              </span>
            </div>
          </div>
          <div style={{paddingTop:20}}>
          <Cards
				cvc={state.cvc}
				expiry={state.expiry}
				focus={state.focus}
				name={state.name}
				number={state.number}
			/>
      </div>
          <Col>
            <div className="iban">
              <Label className="font-weight-700 ">Titulaire de la carte*</Label>
              <Input name="name" placeholder="Nom & Prénom" onChange={handleChange} />
            </div>
          </Col>
          <Col>
            <div className="iban">
              <Label className="font-weight-700 ">NUMERO DE LA CARTE*</Label>
              <Input
                name="number"
                value={state?.number}
                placeholder="Numero de carte"
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col>
            <div className="iban">
              <Label className="font-weight-700 ">CVC*</Label>
              <Input name="cvc" placeholder="CVC" onChange={handleChange} type="password"/>
            </div>
          </Col>
          <Col>
            <div className="iban">
              <Label className="font-weight-700 ">DATE D'EXPIRATION*</Label>
              <Input
                name="expiry"
                placeholder="mm / aa"
                onChange={handleChange}
              />
            </div>
          </Col>

          <hr />
          <Col
            md="12"
            lg="12"
            className="d-flex justify-content-center align-items-center padding-btn"
          >
            <Button color="success" onClick={()=>senData()}>
              Enregistrer
            </Button>
          </Col>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Iban;
