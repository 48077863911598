import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Input, Label, Spinner } from "reactstrap"
import ToolBox from './toolBox'
import StepContainer from './step'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { saveStep } from '../../../services/scenarioServices'
import "../scenario.css"
import ConfirmModal from '../../common/confirmModal'
import { toast } from 'react-toastify';
import Plus from '../../../assets/plus.svg';

class CreateSteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            inputs: [],
            data: [],
            list: [],
            show: false,
            done: false,
            btns: [],
            response: {
                choice1: '',
                choice2: ''
            },
            showLoader: false,
            step: {
                url: "",
                instruction: "",
                question: ""
            },
            scaleRange: {
                minScale: "",
                maxScale: ""
            }
            ,
            idScenario: '',
            type: 'open'
        };

    }

    componentDidMount() {
        this.setState({ list: this.props.location.state?.data, data: this.props.location.state?.data, idScenario: this.props.location.state?.scenarioId })
        setTimeout(() => {
            this.setState({ showLoader: true })
        }, 1000);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
    }
    onchange = (e) => {
        e.preventDefault();
        this.setState({ scaleRange: { ...this.state.scaleRange, [e.target.name]: e.target.value } })
    }
    onChangeResponse = (e) => {
        e.preventDefault();
        this.setState({ response: { ...this.state.response, [e.target.name]: e.target.value } })
    }
    onChangeStep = (e) => {
        e.preventDefault();
        this.setState({ step: { ...this.state.step, [e.target.name]: e.target.value } })
    }
    onChangeType = (e) => {
        this.setState({ type: e.target.value }, () => this.onStepEdit())
    }
    handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(this.state.list);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        this.setState({ list: items });
    }

    onScale = () => {
        this.setState({
            show: !this.state.show,
            btns: [],
            scaleRange: {
                minScale: "",
                maxScale: ""
            }
        })
    }
    validateScale = () => {
        // const { minScale, maxScale } = this.state.scaleRange
        // const { step } = this.state
        // const colors = [
        //     "red",
        //     '#ff0606',
        //     '#eb6200',
        //     '#ff7c06',
        //     '#22780f',
        //     'green',
        // ]
        // if (minScale && maxScale) {

        //     for (let i = minScale; i <= maxScale; i++) {
        //         this.state.btns.push({ btn: i, color: colors[i % colors.length] });
        //     }
        this.setState({
            show: !this.state.show, list: [...this.state.list, {
                id: `${this.state.list.length + 1} `,
                component: <StepContainer
                    type="scale"
                    etape={this.state.list.length + 1}
                    btns={this.state.btns}
                    addStep={this.validateStep}
                    visualise={this.visualise}
                    duplicate={this.duplicate}
                    step={this.state.step}
                    onChangeStep={this.onChangeStep}
                    drop={this.Delete}
                    onChangeType={this.onChangeType}
                    onchange={this.onchange}
                    scaleRange={this.state.scaleRange}
                    list={this.state.list}
                    data={this.state.data}
                />
            }]
        })
        //  }
        // else
        //     toast.warning('Vous devez saisir le nombre min et max pour proceder')
    }
    onOpen = () => {
        const { step } = this.state
        this.setState({
            list: [...this.state.list, {
                id: `${this.state.list.length + 1} `,
                component: <StepContainer
                    type="open"
                    etape={this.state.list.length + 1}
                    addStep={this.validateStep}
                    visualise={this.visualise}
                    duplicate={this.duplicate}
                    onChangeStep={this.onChangeStep}
                    step={this.state.step}
                    drop={this.Delete}
                    onChangeType={this.onChangeType}
                    list={this.state.list}
                    data={this.state.data}
                />
            }]
        })
    }
    onClose = () => {
        const { step } = this.state
        this.setState({
            list: [...this.state.list, {
                id: `${this.state.list.length + 1} `,
                component: <StepContainer
                    type="close"
                    inputs={this.state.inputs}
                    add={this.add}
                    substruct={this.substruct}
                    etape={this.state.list.length + 1}
                    addStep={this.validateStep}
                    visualise={this.visualise}
                    duplicate={this.duplicate}
                    step={this.state.step}
                    onChangeStep={this.onChangeStep}
                    drop={this.Delete}
                    onChangeResponse={this.onChangeResponse}
                    response={this.state.response}
                    onChangeType={this.onChangeType}
                    list={this.state.list}
                    data={this.state.data}
                />
            }]
        })
    }
    onStep = () => {
        if (this.state.list.length === this.state.data.length) {
            this.state.type === "scale" && this.validateScale()
            this.state.type === "open" && this.onOpen()
            this.state.type === "close" && this.onClose()
        }
        else
            toast.info("Vous devez valider l'étape pour pouvoir rajouter une autre")
    }
    onStepEdit = () => {
        this.setState({ list: this.state.list.splice(-1) })
        this.state.type === "scale" && this.validateScale()
        this.state.type === "open" && this.onOpen()
        this.state.type === "close" && this.onClose()
    }
    duplicate = () => {
        if (this.state.list.length === this.state.data.length)
            this.setState({
                list: [...this.state.list, {
                    id: this.state.list[this.state.list.length - 1].id + 1,
                    component: this.state.list[this.state.list.length - 1].component
                }]
            })
        else
            toast.info("Vous devez valider l'étape pour pouvoir la dupliquer")
    }
    Delete = (index) => {
        this.setState({
            list: this.state.list.filter(res => res !== this.state.list[index]),
            data: this.state.data.filter(res => res !== this.state.data[index]),

        })
    }
    visualise = (id) => {
        if (this.state.list.length === this.state.data.length)
            this.props.history.push({
                pathname: "/client/visualisation",
                state: {
                    data: this.state.data,
                    id: id,
                    scenarioId: this.props.location.state.scenarioId
                }
            })
        else
            toast.info("Vous devez valider l'étape pour pouvoir la visualiser")
    }
    toggleCM = (id) => {
        this.setState({ open: !this.state.open, stepId: id })
    }
    renderConfirmModal() {

        return (
            <ConfirmModal
                // modalSize="modal-xl"
                show={this.state.open}
                toggleShow={this.toggleCM}
                header='Détail Testeur'
            //  onSubmit={}
            />

        )
    }

    add = () => {
        this.setState({ inputs: [...this.state.inputs, {}] })
    }
    substruct = () => {
        let array = [...this.state.inputs]
        array.splice(this.state.inputs.length - 1, 1);
        this.setState({ inputs: array })
    }
    validateStep = () => {
        this.state.type === "scale" && this.setState({
            data: [...this.state.data, {
                url: this.state.step.url,
                instruction: this.state.step.instruction,
                question: this.state.step.question,
                questionChoices: {
                    minScale: parseInt(this.state.scaleRange.minScale, 10),
                    maxScale: parseInt(this.state.scaleRange.maxScale, 10),
                },
                number: this.state.list.length,
                type: 'scale'
            }],
            step: {
                url: "",
                instruction: "",
                question: ""
            },
        })
        this.state.type === "open" && this.setState({
            data: [...this.state.data, {
                url: this.state.step.url,
                instruction: this.state.step.instruction,
                question: this.state.step.question,
                // questionChoices: {
                //     choice1: "",
                // },
                number: this.state.list.length,
                type: 'open'
            }],
            step: {
                url: "",
                instruction: "",
                question: ""
            },

        })
        this.state.type === "close" && this.setState({
            data: [...this.state.data, {
                url: this.state.step.url,
                instruction: this.state.step.instruction,
                question: this.state.step.question,
                questionChoices: {
                    choice1: this.state.response.choice1,
                    choice2: this.state.response.choice2,

                },
                number: this.state.list.length,
                type: 'close'
            }],
            step: {
                url: "",
                instruction: "",
                question: ""
            },

        })
        toast.success('Etape ajoutée avec succès')
    }
    submit = () => {
        saveStep(this.state.idScenario, this.state.data).then(res => {
            this.props.history.push('/client/testerpanel')
            toast.success('Scénario créé avec succès')
        })
    }

    render() {
        const { list, done, data } = this.state
        if (this.state.showLoader === false)
            return (<div className="scenario__ctn">

                <div style={{ display: 'flex', margin: 'auto' }}>
                    <Spinner type="grow" color="success" className='mr-2' />
                    <Spinner type="grow" color="success" className='mr-2' />
                    <Spinner type="grow" color="success" className='mr-2' />
                    <Spinner type="grow" color="success" className='mr-2' />
                    <Spinner type="grow" color="success" className='mr-2' />
                </div>

            </div>)
        return (<>
            {done && <div className="scenario2__ctn" >
                {/* {this.renderConfirmModal()} */}
                <Col md='1'></Col>
                <Col md='10'>
                    <div className='header' style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="scenario__name">{this.props.scenario.scenarios[this.props.scenario.scenarios.length - 1].title}</span>

                        <label className='desc__style'>Description:</label>
                        <Input
                            type='textarea'
                            id='text__area'
                            placeholder=' Le descriptif vous permet en quelques lignes de présenter le sujet de votre test afin de donner envie au testeur de le réaliser.
                            Ce texte se retrouvera dans le corps de l`email qui prévient les testeurs de l’arrivée d’un nouveau test.'
                        />
                    </div>

                    <DragDropContext onDragEnd={this.handleOnDragEnd}>
                        <Droppable droppableId="steps">
                            {(provided) => (
                                <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', paddingInlineStart: "0" }}>
                                    {list.map(({ component, id }, index) => {
                                        return (
                                            <Draggable key={id} draggableId={id} index={index}>
                                                {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <span className='step__title'>Etape {index + 1}</span>
                                                        {component}
                                                    </li>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                    }
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {this.state.data[0] && <Button className='finish' onClick={this.submit}> Créer le scenario</Button>}
                </Col>
                <Col md='1'>
                    <img src={Plus} style={{ cursor: 'pointer', position: 'fixed', alignItems: 'start', display: 'flex', marginTop: '11em' }} onClick={() => this.onStep()} alt="" />
                </Col>

                {/* {(list[0]) && */}
                {/* <Row style={{ padding: '2em' }}> */}
                {/* <Col md='7'>

                </Col> */}
                {/* <Col md='4'>
                        <ToolBox
                            show={this.state.show}
                            onScale={this.onScale}
                            onOpen={this.onOpen}
                            validateScale={this.validateScale}
                            onClose={this.onClose}
                            onchange={this.onchange}
                            scaleRange={this.state.scaleRange}
                            list={this.state.list}
                            data={this.state.data}
                        />
                    </Col> */}
                {/* </Row> */}
                {/* } */}
                {/* {!this.state.list[0] && <Row style={{ justifyContent: "center" }}>
                    <ToolBox
                        show={this.state.show}
                        onScale={this.onScale}
                        onOpen={this.onOpen}
                        validateScale={this.validateScale}
                        onClose={this.onClose}
                        onchange={this.onchange}
                        scaleRange={this.state.scaleRange}
                        list={this.state.list}
                        data={this.state.data}
                    />
                </Row>} */}

            </div>}
            {!done && <div className='step__creation'>
                <Col md='2'></Col>
                <Col md='8'><span className='step__init '>Vous pouvez démarrer la création de votre scénario, qui se résume en un ensemble d’étapes.</span>
                    <p className='step__init__body mt-4'>
                        Les étapes peuvent être des questions ouvertes sous forme de tâches : "Trouvez les différents moyens de contact des utilisateurs."
                    </p>
                    <p className='step__init__body'>
                        Ou encore des questions plus précises : "Comment évaluez-vous la simplicité à commander un produit sur ce site internet ? "
                    </p>
                    <p className='step__init__body'>
                        Vous faites comme vous le souhaitez.</p>

                    <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>

                        <Button className='go' onClick={() => this.setState({ done: true })}>Commencer</Button>
                    </div>
                </Col>
                <Col md='2'></Col>
            </div>}
        </>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {}
)(CreateSteps);