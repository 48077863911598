import { array } from "prop-types";
import React from "react";
import PieChart from "./PieChart";
function PieComponent({ question, labels, data, index }) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <strong>Etape {index + 1} : </strong>
        <div style={{ paddingLeft: 20 }}>
          <span className="top-component-fine-black-style">{question}</span>
        </div>
      </div>
      <div>
        <strong>{data.reduce(reducer, 0)} réponses </strong>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="pie-chart-container ">
          <PieChart {...{ data, labels }} />
        </div>
      </div>
    </div>
  );
}

export default PieComponent;
