import React, { useEffect, useState } from "react";
import Logo from "../../../assets/logo-vector.svg";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
} from "reactstrap";

const UpdatePasswordModal = ({
  show,
  children,
  url,
  visible,
  toggleVisible,
}) => {
  return (
    <Modal
      isOpen={visible}
      className="modal-l center-modal border-radius-20 justify-content-start align-items-start"
      toggle={toggleVisible}
    >
      <Row className="padding-modal">
        <Col md="6" sm="6" lg="6" className="p-2 d-flex justify-content-start">
          <img style={{ maxWidth: "170px" }} src={Logo} alt="Logo" />
        </Col>
        <Col
          md="6"
          sm="6"
          lg="6"
          className="p-2 d-flex justify-content-end height-X"
        >
          <Button onClick={toggleVisible}>
            <span>X</span>
          </Button>
        </Col>
      </Row>

      <ModalBody>
        <Col
          md="12"
          lg="12"
          className="d-flex flex-column justify-content-space-around align-items-start"
        >
          <strong style={{ color: "green" }}>MODIFIER MON MOT DE PASSE</strong>
          <Form>
            <Col style={{ paddingTop: 15 }}>
              <div className="column-left">
                <Label style={{ fontWeight: "bold" }}>
                  Ancien mot de passe
                </Label>
                <Input type="password" placeholder={"Ancien mot de passe"} style={{width:400}}/>
              </div>
            </Col>

            <Col style={{ paddingTop: 15 }}>
              <Label style={{ fontWeight: "bold" }}>Nouveau mot de passe</Label>
              <Input type="password" placeholder={"Nouveau mot de passe"} />
            </Col>
            <Col style={{ paddingTop: 15 }}>
              <FormGroup className="column-left">
                <Label style={{ fontWeight: "bold" }}>
                  Retaper le nouveau mot de passe
                </Label>
                <Input type="password" placeholder={"Nouveau mot de passe"} />
              </FormGroup>
            </Col>
          </Form>
        </Col>
      </ModalBody>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <Button onClick={toggleVisible} className="btn-success" style={{width:400}}>
          {" "}
          Modifier
        </Button>
      </div>
    </Modal>
  );
};
export default UpdatePasswordModal;
