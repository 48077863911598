import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Label, Input } from "reactstrap"
import Table from "../../common/table"
import Fab from '@material-ui/core/Fab';
import AddIcon from "../../../assets/ajouter.svg"
import ShowMore from "../../../assets/insightdata_consulter.svg"
import TesterForm from './testerForm';
import Modals from '../../common/modal'
import { savePanel } from '../../../services/scenarioServices'
import { toast } from 'react-toastify';

class CreatePanelClient extends Component {
    state = {
        idScenario: "",
        currentPanel: {
            name: "",
            scenarioName: {},
            file: ""
        },
        show: false,
        clientTesters: {
            name: "",
            lastname: "",
            email: "",
        },
        loading: false,
        userLabel: {
            name: "Prénom",
            lastname: "Nom",
            email: "Email",
        },
        data: [],
        error: {}
    }
    toggle = () => {
        this.setState({ show: !this.state.show })
        setTimeout(() => {
            this.setState({
                clientTesters: {
                    name: "",
                    lastname: "",
                    email: "",
                },
            })
        }, 500);
    }
    onSubmit = () => {
        this.setState({ ...this.state, data: [...this.state.data, this.state.clientTesters], show: !this.state.show })
    }
    onChangePanel = e => {
        e.preventDefault();
        this.setState({ currentPanel: { ...this.state.currentPanel, [e.target.name]: e.target.value } })
    };
    onChangeScenario = e => {
        e.preventDefault();
        this.setState({ ...this.state, idScenario: e.target.value })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({ clientTesters: { ...this.state.clientTesters, [e.target.name]: e.target.value } })
    };
    validate = () => {
        const { currentPanel, data, idScenario } = this.state
        // const formData = new FormData();
        // formData.append("file", currentPanel.file);
        // formData.append("name", currentPanel.name);
        // formData.append("scenarioName", currentPanel.scenarioName);
        // formData.append("clientTesters", data);
        // formData.append("product", "");
        // formData.append("type", 'client');
       
        const panel = {
            name: currentPanel.name,
            scenarioName: "",
            clientTesters: data,
            testersNb: data.length,
            product: "",
            type: 'client'
        }
        savePanel(panel, idScenario).then(res => {
            toast.success('Panel ajouter avec success')
            this.props.history.push('/client/panellist')
            console.log(res);
        })

    }
    renderModaltester() {
        const { clientTesters, userLabel, loading } = this.state
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Ajouter Testeur'
            >
                <TesterForm
                    confirmText='Ajouter testeur'
                    handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    currentUser={clientTesters}
                    userText={userLabel}
                    loading={loading}
                    error={this.state.error}
                />

            </Modals>
        )
    }
    renderTable = () => {
        const columns = [
            { title: "Nom ", field: 'lastname' },
            { title: "Prénom", field: 'name' },
            { title: "Mail", field: 'email' },

        ]
        const actions = [{
            icon: () => <img style={{ width: "30px" }} src={ShowMore} alt="" />,
            tooltip: 'Détails',
           // onClick: (event, rowData) => this.showDetails(rowData),
        },

        ]

        return (
            <Table
                title='Liste des testeurs'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        const {
            name,
        } = this.state.currentPanel
        const scenarios =[{id:"",title:'veulliez selectionner votre scénario'},...this.props.scenario.scenarios]
        return (<div className="scenario__ctn" >
            <span className="title__style">Panel de testeurs</span>
            <Row className='row_panel'>
                <Col md='12'>
                    <Row className='row__form'>
                        <Label className='label__form'>Nom du scénario:</Label>
                        <Input type="select" name="scenarioName" className='label__form' style={{ width: '40vw' }} onChange={this.onChangeScenario}>
                            {scenarios.map((scenario) => <option key={scenario.id} value={scenario.id}>{scenario.title}</option>)}
                        </Input>
                        {/* <Input
                            //  placeholder='(Facultatif)'
                            value={scenarioName}
                            type="text"
                            onChange={this.onChangePanel}
                            name="scenarioName"
                            id=""
                            style={{ width: '40vw' }}
                        /> */}
                    </Row>
                    <Row className='row__form'>
                        <Label className='label__form'>Nom du panel:</Label>
                        <Input
                            //   placeholder='(Facultatif)'
                            value={name}
                            type="text"
                            onChange={this.onChangePanel}
                            name="name"
                            id=""
                            style={{ width: '40vw' }}
                        />
                    </Row>
                    {this.renderTable()}
                    {this.renderModaltester()}
                    <Fab id='btn__add__tester' aria-label="add" onClick={this.toggle} >

                        <img style={{ width: '56px' }} src={AddIcon} alt="" />
                    </Fab>
                </Col>
            </Row>
            <Row className='row__create_sc'>
                <Button className='signup__button' style={{ width: "10em" }} onClick={this.validate}> Valider</Button>
            </Row>
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {}
)(CreatePanelClient);