import React from "react";
import OpenStep from "./OpenStep";
import ClosedStep from "./ClosedStep";
import ClosedBarComponent from "./ClosedBarComponent";

function StepResume(props) {
  const [prps, setPrps] = React.useState({});
  React.useEffect(() => {
    setPrps(props);
    console.log(props, "props");
  }, [props?.id]);
  return (
    <div>
      {props?.step?.type === "open" ? (
        <React.Fragment>
          <OpenStep data={props} />
        </React.Fragment>
      ) : props?.step?.type === "close" ? (
        <div>
          <ClosedStep data={props} />
        </div>
      ) : (
        props?.step?.type === "scale" && (
          <div>
            <ClosedBarComponent data={props} />
          </div>
        )
      )}
    </div>
  );
}

export default StepResume;
