import jwtDecode from "jwt-decode";
import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";
const apiEndpoint = apiUrl + "/api/login_check";
const tokenKey = "token";
http.setJwt(getJwt());

export async function loginUser(username, password) {
  const user = await axios.post(apiEndpoint, { username, password });
  return user;
}

export function getCurrentUser() {
    try {
      const jwt = localStorage.getItem(tokenKey);
      const userDecoded = jwtDecode(jwt);
      return userDecoded
    } catch (ex) {
      return null;
    }
  }
  
export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
   localStorage.removeItem(tokenKey);
}

export function getJwt() {
  localStorage.getItem(tokenKey);
}

export default {
  loginUser,
  loginWithJwt,
  logout,
  getJwt
};
