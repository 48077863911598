import React from 'react';
import { Redirect } from "react-router";
import { changeActiveTabDashboard } from "../../actions/authActions";
import { connect } from "react-redux";
import { Link, Switch, Route } from "react-router-dom"
import PropTypes from "prop-types";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './verticalNavBar.css'
import Logo from '../../assets/logo-vector.svg'
import authServices from '../../services/authServices';
import Help from '../../assets/help.svg'
import Logout from '../../assets/logout.svg'
import HelpForm from './helpForm';
import Modals from '../common/modal'

class VerticalNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.auth.tabDashboard,
            count: 0,
            show: false,
            loading: false,
            currentHelp: {
                subject: '',
                lastname: '',
                name: '',
                email: '',
                comment: ''
            }
        }
    }
    toggle = () => {
        this.setState({ ...this.state, show: !this.state.show })
    }
    onChange = e => {
        e.preventDefault();
        this.setState({ currentHelp: { ...this.state.currentHelp, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };
    onChangePhone = value => {
        this.setState({ currentHelp: { ...this.state.currentHelp, phone: value } })
    };
    renderModalHelp() {

        const subject = ["Scénario", "Panel", "Mot de passe", "Résiliation", "Autre"]
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Demande d’aide'
            >
                <HelpForm
                    //  tog_standard={this.toggle}
                    confirmText='Envoyer la demande'
                    //handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    subject={subject}
                    currentHelp={this.state.currentHelp}
                    loading={this.state.loading}
                    onChangePhone={this.onChangePhone}
                    error={this.state.error}
                />

            </Modals>
        )
    }
    render() {
        let { activeTab } = this.state
        let dropDown = ["Scenario", "Statistiques", "Analyses"]
        return <ProSidebar>
            {this.renderModalHelp()}
            <SidebarHeader>
                <img src={Logo} alt="" style={{ maxWidth: "170px" }} />
            </SidebarHeader>
            <SidebarContent>
                {this.props.Menu.map((route, i) => {
                    return (route.Name !== "") && <Menu iconShape="square">
                        {!(dropDown.includes(route.Name)) && <MenuItem
                            icon={route.icon}
                        >{route.Name}
                            {this.props.auth.user.roles[0] === "ROLE_ADMIN" && <Link to={`/admin${route.path}`} />}
                            {this.props.auth.user.roles[0] === "ROLE_CLIENT" && <Link to={`/client${route.path}`} />}
                            {this.props.auth.user.roles[0] === "ROLE_TESTER" && <Link to={`/tester${route.path}`} />}

                        </MenuItem>}
                        {route.Name === "Scenario" && <SubMenu title={route.Name}
                            icon={route.icon}
                        >
                            <MenuItem>Créer un scenario <Link to="/client/createscenario" /></MenuItem>
                            <MenuItem>Liste des scenarios <Link to="/client/scenario" /></MenuItem>
                            <MenuItem>Créer un panel <Link to="/client/testerpanel" /></MenuItem>
                            <MenuItem>Liste de Panels<Link to="/client/panellist" /></MenuItem>
                        </SubMenu>}
                        {route.Name === "Statistiques" && <SubMenu title={route.Name} icon={route.icon} >
                            <MenuItem>Panel<Link to="/client/statistics" /></MenuItem>
                            <MenuItem>Réponses <Link to="/client/statistics" /></MenuItem>
                        </SubMenu>}
                        {route.Name === "Analyses" && <SubMenu title={route.Name}
                            icon={route.icon}
                        >
                            <SubMenu title="Sémantique"  >
                                <MenuItem> Par étape <Link to="/client/step-analyze" /></MenuItem>
                                <MenuItem> Par testeur <Link to="/client/tester-analyze" /></MenuItem>
                            </SubMenu>
                            <SubMenu title="Reconnaissance faciale"  >
                                <MenuItem> Par étape <Link to="/client/facial-recognition" /></MenuItem>
                                <MenuItem> Par testeur <Link to="/client/tester-facial-recognition" /></MenuItem>
                            </SubMenu>
                        </SubMenu>}
                    </Menu>
                })}
            </SidebarContent>
            <SidebarFooter>
                {this.props.auth.user.roles[0] === "ROLE_CLIENT" && <img src={Help} style={{ height: '15px', cursor: 'pointer' }} alt="" className='mr-4' onClick={() => this.toggle()} />}
                <a style={{ textDecoration: 'none' }} href="/" onClick={() => {
                    authServices.logout();
                }}> <img src={Logout} style={{ height: '15px' }} alt="" /> </a>
            </SidebarFooter>
        </ProSidebar>;
    }
}

VerticalNavBar.propTypes = {
    auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { changeActiveTabDashboard })(VerticalNavBar);
