import React from 'react';
import Table from "../../common/table"
import { connect } from 'react-redux';
//import { onGetClients } from "../../../actions/userActions"
import Delete from "../../../assets/insightdata_supprimer.svg"
import AddIcon from "../../../assets/ajouter.svg"
import { deleteAdmin } from '../../../services/userServices'
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import Modals from '../../common/modal'
import { toast } from 'react-toastify';
import AdminForm from './adminForm'
import { onSaveAdmin, onGetAdmins } from '../../../actions/userActions'
import "./manageAdmin.css"

class ManageAdmin extends React.Component {
    state = {
        data: [],
        show: false,
        currentUser: {
            name: "",
            lastname: "",
            username: "",
            email: "",
        },
        loading: false,
        userLabel: {
            name: "Prénom",
            lastname: "Nom",
            email: "Email",
            username: "Nom d'utilisateur",
        },
        error: {}
    }

    componentDidMount() {
      if (!this.props.user.admins[0] )
      this.props.onGetAdmins()
      else
      {
        let admins = []
        this.props.user.admins.map(admin => {
            admins = [...admins, {
                createdAt: moment(admin.createdAt).format('L'),
                lastname: admin.lastname,
                id: admin.id,
                name: admin.name,
                email: admin.email,
                state: admin.state,
                color: (moment(new Date()).format('L')>moment(admin.createdAt).format('L')+"02")||(moment(new Date()).isAfter(admin.createdAt, 'year'))? "red":""
            }]
        })
        this.setState({ data: admins })

      }

        //getConnectedUser().then(res => console.log(res))
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.errorAdmin !== this.props.user.errorAdmin) {
            this.setState({
                error: nextProps.user.errorAdmin
              
            })
        }
        if ((this.props.user.admins !== nextProps.user.admins)) {
            let admins = []
            nextProps.user.admins.map(admin => {
                admins = [...admins, {
                    createdAt: moment(admin.createdAt).format('L'),
                    lastname: admin.lastname,
                    name: admin.name,
                    id: admin.id,
                    email: admin.email,
                    state: admin.state,
                    color: (moment(new Date()).format('L')>moment(admin.createdAt).format('L')+"02")||(moment(new Date()).isAfter(admin.createdAt, 'year'))? "red":""
                }]
            })
            this.setState({ data: admins })

        }
    }
    toggle = () => {
        this.setState({ show: !this.state.show })
        setTimeout(() => {
            this.setState({
                currentUser: {
                    name: "",
                    lastname: "",
                    username: "",
                    email: "",
                },
            })
        }, 500);
    }
    onChange = e => {
        e.preventDefault();
        this.setState({ currentUser: { ...this.state.currentUser, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };
    onSubmit = () => {
        const { name, lastname, username, email } = this.state.currentUser;
        const admin = {
            name, lastname, username, email
        }
        this.setState({ loading: true })
        this.props.onSaveAdmin(admin)
        setTimeout(() => {
            this.setState({ loading: false })
            !this.state.error.message && this.toggle()
            this.state.error.message ? toast.warning('Veillez vérifier vos champs') : toast.success('Admin créé avec succès ')
        }, 2000);
    }
    onDelete=(rowId)=>{
        deleteAdmin(rowId).then(res=>{
            console.log(res)
            this.props.onGetAdmins()
        })
    }
    renderModalAdmin() {
        const { currentUser, userLabel, loading } = this.state
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Ajouter un Administrateur'
            >
                <AdminForm
                    confirmText='Ajouter un Administrateur'
                    handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    currentUser={currentUser}
                    userText={userLabel}
                    loading={loading}
                    error={this.state.error}
                />

            </Modals>
        )
    }
    renderTable = () => {
     // const details = order.details.filter(dur => moment(dur.from_date).format('MM') === this.state.month)
        const columns = [
            { title: "Date", field: 'createdAt'  },
            { title: "Nom", field: 'lastname' },
            { title: "Prémon", field: 'name' },
            { title: "Mail", field: 'email' },
            { title: "Etat", field: 'state', render: rowData => <span style={{color:`${rowData.color}`}}>{rowData.state==="to_contact"?"A contacter":"Ok"}</span> },
        ]
        const actions = [{
            icon: () => <img style={{width:'40px'}} src={Delete} alt="" />,
            tooltip: 'Delete',
            onClick: (event, rowData) => this.onDelete(rowData.id),
        },
        ]
        return (
            <Table
                title='Liste des administrateurs'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (<div >
            {this.renderTable()}
            {this.renderModalAdmin()}
            <Fab id='btn__add__manager' aria-label="add" onClick={this.toggle} >
              
                <img  style={{width:'56px'}} src={AddIcon} alt="" />
            </Fab>
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user
});

export default connect(
    mapStateToProps,
    { onSaveAdmin, onGetAdmins }
)(ManageAdmin);