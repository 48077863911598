import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Input, Label } from "reactstrap"
import "../scenario.css"
class StepVisualisation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    UNSAFE_componentWillMount() {
        this.setState({ data: this.props.location.state.data, id: this.props.location.state.id })

    }
    UNSAFE_componentWillReceiveProps(nextProps) {
    }
    back = () => {
        this.props.history.push({
            pathname: "/client/createsteps",
            state: {
                data: this.state.data,
                scenarioId: this.props.location.state.scenarioId
            }
        })
    }
    render() {
        const { data, id } = this.state
        return (
            <div className="scenario__ctn" >
           <Row>
           <Col md='9'>
            <Row className='row__visu'>
                    <Label className='label__form'>Instruction:</Label>
                    <Input
                        placeholder='(Facultatif)'
                        value={data[id - 1].instruction}
                        type="text"
                        //onChange={onchange}
                        disabled
                        name="instruction"
//id="input__visu"
                    />
                </Row>
                <Row className='row__visu'>
                    <Label className='label__form'>Question:</Label>
                    <Input
                        placeholder='(Facultatif)'
                        value={data[id - 1].question}
                        type="text"
                        disabled
                        //  onChange={onchange}
                        name="question"
                     //   id="input__visu"
                    />
                </Row>
                <Row className='row__visu'>
                    <Label className='label__form'>Réponse:</Label>
                    <Input
                        placeholder=''
                        type="text"
                        name="response"
                        disabled
                      //  id="input__visu"
                    // disabled
                    />
                </Row>
            </Col>
            <Col md='3' className='col__back'>
            <Button className='toolBox__button' onClick={() => this.back()}>Retour</Button>
            </Col>
           </Row>
                <iframe className='iframe__form' src={data[id - 1].url}></iframe>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {}
)(StepVisualisation);