import React, { Component } from "react";
import { connect } from "react-redux";
import Equation from "../../assets/Insight_data_equation.svg";
import Client from "../../assets/Insight_data_tableau_récap_02.svg";
import Diff from "../../assets/Insight_data_tableau_récap_03.svg";
import Innovation from "../../assets/Insight_data_tableau_récap_35.svg";
import Scenario from "../../assets/Insight_data_tableau_récap_13.svg";
import Audiance from "../../assets/Insight_data_tableau_récap_14.svg";
import Analyse from "../../assets/Insight_data_tableau_récap_15.svg";
import Produit from "../../assets/Insight_data_tableau_récap_16.svg";
// import UXUI from "../../assets/Insight_data_tableau_récap_39.svg"
// import Marketing from "../../assets/Insight_data_tableau_récap_40.svg"
// import Executive from "../../assets/Insight_data_tableau_récap_41.svg"
// import Production from "../../assets/Insight_data_tableau_récap_42.svg"
// import Prototype from "../../assets/Insight_data_tester_des_prototypes.svg"
// import Application from "../../assets/Insight_data_tester_vos_applications.svg"
// import SiteWeb from "../../assets/Insight_data_tester_vos_sites_web.svg"
import Peter from "../../assets/peter.jpg";
import Landing from "../../assets/landing.png"
// import QQ from "../../assets/Insight_data_tableau_récap_38.svg"
import Price1 from "../../assets/price1.svg";
import Price2 from "../../assets/price2.svg";
import Management from "../../assets/management.svg";
import Equipes from "../../assets/equipes.svg";
import Designers from "../../assets/designers.svg";
import Expert from "../../assets/expert.svg";
import Tick from "../../assets/tick.svg";
import video from "../../assets/presentation.mp4";
import Modal from "./Modal";
import "./homePage.css";
import { Player } from "video-react";
import { Col, Row, Button } from "reactstrap";
import CookieConsent, { Cookies } from "react-cookie-consent";

const HomePageForm = (props) => {
  const [visible, setVisible] = React.useState(false);
  function toggleVisible() {
    setVisible(!visible);
  }
  const { scroll } = props;
  window.scrollTo(0, scroll);
  const btn1Text = "Profiter d'un mois gratuit";
  const btn2Text = "Planifier une demo";
  const [play, setPlay] = React.useState(false);
  return (
    <div className="home__ctn">
      {/* <Piechart
                    data={data}
                    colors={COLORS}
                />
                <Linechart
                    data={data1}
                /> */}
      <div className="container_home">
        <div style={{ display: "flex", padding: "0 3em" }}>
          <Col md="5">
            <Row>
              <span className="title__1">
                Vos clients vous parlent, nous vous aidons à les comprendre.{" "}
              </span>
            </Row>
            {/* <Row>
              <p className='title__1'>nous vous aidons à les comprendre. </p>
            </Row>
            <Row>
            <p className='title__3'>Insight Data  « Just Get In Touch » </p>
          </Row> */}
            <br />
            <br />
            <Row>
              <span className="title__2">
                Insight Data « Just Get In Touch », la plateforme d'optimisation
                de l'expérience utilisateur​
              </span>
            </Row>
            {/* <Row>
              <Col>
                <Row>
                  <Button className='signup__button' style={{ width: "18em" }} onClick=""> Pourquoi nous faire confiance ?</Button>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Button className='signup__button' style={{ width: "18em" }} onClick=""> Regardez une Démo</Button>
                </Row>
              </Col>
            </Row> */}
          </Col>
          <Col md="7">
            {play ? (
              <Player
                playsInline
                autoPlay
                src={video}
                //style={{ height: '10em !important' }}
              />
            ) : (
              <img
                src={Landing}
                alt=""
                className="img_video"
                onClick={() => setPlay(true)}
              />
            )}
          </Col>
        </div>
        {/* <div style={{ height: '17em', backgroundColor: '#fff' }}>
        </div> */}
        <br />
        <br />
        <img style={{ maxHeight: "38vh" }} src={Equation} alt="" />
      </div>

      <div className="container_home">
        <div
          style={{
            display: "flex",
            padding: "0 3em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Row style={{ marginBottom: "1em" }}>
            <span className="title__1">
              Insight Data : une plateforme pour faire quoi ?{" "}
            </span>
          </Row>
          <Row>
            <p className="title__2">
              Vous testez vos prototypes, vos sites internet ou vos
              applications. Nous collectons des vidéos commentées, des verbatims
              écrits et des commentaires. et les scorons avec notre moteur
              d’intelligence artificiel.
            </p>
          </Row>
          <br />
          <br />
          <Row>
            <Button className="btn_home" onClick="">
              {" "}
              Tester notre IA en 3 minutes seulement
            </Button>
          </Row>
        </div>
        <br />
        <br />
        <br />
        <div style={{ display: "flex", padding: "0 3em" }}>
          <Col md="4">
            <div className="green_container">
              <span className="title__1">68%</span>
              <p className="title__2">
                des utilisateurs quittent un site à cause d’une mauvaise
                expérience de navigation
              </p>
            </div>
          </Col>
          <Col md="8">
            <div className="transparent_container">
              <span className="title__1">La solution ? </span>
              <p className="title__2">
                85% des problèmes peuvent être détectés et résolus en effectuant
                un test sur un groupe de 5 utilisateurs.
              </p>
              <p className="title__2">
                1€ investi en expérience utilisateur génère un revenu de 100€ en
                retour.
              </p>
            </div>
          </Col>

          {/* <div style={{ display: 'flex' }}>
            <Col>
              <img style={{ width: '20em', height: '7em' }} src={Prototype} alt="" />
              <p className='img__legend'>Tester des prototypes</p>
            </Col>
            <Col>
              <img style={{ width: '20em', height: '7em' }} src={SiteWeb} alt="" />
              <p className='img__legend'>Tester vos sites web</p>
            </Col>
            <Col>
              <img style={{ width: '20em', height: '7em' }} src={Application} alt="" />
              <p className='img__legend'>Tester vos applications</p>
            </Col>
          </div> */}
        </div>
      </div>
      {/* <div >
        <div style={{ display: 'flex', padding: '0 3em' }}>
          <Row >
            <p className='title__1'>Insight Data : une plateforme pour faire quoi ? </p>
          </Row>
        </div>
        <div className='row__stat'>
          <Col md='8'>
            <Row>
              <p className='title__4'>Des données statistiques à votre disposition​​ </p>
            </Row>
            <div className='counters'>
              <p className='list1'>Statistique de panel</p>
              <p className='list1'>Statistique de réponses utilisateurs</p>
            </div>
            <Row>
              <p className='title__4'>Mais surtout des tests qualitatifs !</p>
            </Row>
            <div className='counters2'>
              <p className='list2'>Tests modérés et non modérés</p>
              <p className='list2'>Test simple ou AB Testing</p>
              <p className='list2'>Test de chaleur (Heatmap)</p>
            </div>
          </Col>
          <Col md='4'>
            <div className='col__ux'>
              <img style={{ width: '25em', margin: 'auto' }} src={QQ} alt="" />
              <p className='Ux__legend'>
                L’UX quantititive donne le « Quoi » alors que la recherche qualitative donne le « Pourquoi ».
              </p>
            </div>
          </Col>
        </div>
      </div> */}
      <div className="container_home">
        <div
          style={{
            display: "flex",
            padding: "0 3em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span className="title__1">
            L’UX quantititive donne le « QUOI »,{" "}
          </span>
          <span className="title__1">
            alors que la recherche qualitative donne le « POURQUOI »{" "}
          </span>
          <Row>
            <Col md="5">
              <div className="card__container">
                <span className="title__card">
                  Des données statistiques à votre disposition​​
                </span>
                <div style={{ margin: "auto 0 0 0" }}>
                  <img className="mr-2" src={Tick} alt="" />
                  <span className="text__card">Statistiques de panels </span>
                </div>
                <div>
                  <img className="mr-2" src={Tick} alt="" />
                  <span className="text__card">
                    Statistiques de réponses utilisateurs
                  </span>
                </div>
              </div>
            </Col>
            <Col md="2"></Col>
            <Col md="5">
              <div className="card__container">
                <span className="title__card">
                  Mais surtout des tests qualitatifs !​
                </span>
                <div style={{ margin: "auto 0 0 0" }}>
                  <img className="mr-2" src={Tick} alt="" />
                  <span className="text__card">
                    Tests modérés et non modérés
                  </span>
                </div>
                <div>
                  <img className="mr-2" src={Tick} alt="" />
                  <span className="text__card">
                    Tests simples ou AB Testing
                  </span>
                </div>
                <div>
                  <img className="mr-2" src={Tick} alt="" />
                  <span className="text__card">Emotional Map</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* <div style={{ display: 'flex', padding: '0 3em' }}>
          <Row >
            <p className='title__1'>Pourquoi utiliser notre plateforme ?</p>
          </Row>
        </div>
        <div className='row__equation'>
          <Row >
            <p className='why'>
              Quelques faits…
              <ul>
                <li>70% des utilisateurs abandonnent leur achat en ligne en raison d’une mauvaise expérience utilisateur​</li>
                <li>62% des utilisateurs mécontents partagent leur ressenti.</li>
                <li>La recherche UX montre que 1€ investi en expérience utilisateur génère un revenu de 100€ en retour</li>
              </ul>
              Notre plateforme Insight Data mélange de la collecte d’Insight avec un moteur d’IA  et vous permet de :
            </p>
          </Row>
          <div style={{ display: 'flex' }}>
            <Col>
              <img style={{ width: '20em', height: '15em' }} src={Innovation} alt="" />
              <p className='img__legend'>Innover plus vite</p>
            </Col>
            <Col>
              <img style={{ width: '20em', height: '15em' }} src={Diff} alt="" />
              <p className='img__legend'>Vous Différencier de vos concurrents</p>
            </Col>
            <Col>
              <img style={{ width: '20em', height: '15em' }} src={Client} alt="" />
              <p className='img__legend'>Augmenter l’expérience client</p>
            </Col>
          </div>
        </div> */}
      </div>
      <div className="container_home">
        <div
          style={{
            display: "flex",
            padding: "0 3em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p className="title__1">Insight Data : Comment ca marche ?</p>
        </div>
        <div className="row__stat">
          <Col>
            <img
              style={{ width: "15em", height: "12em" }}
              src={Scenario}
              alt=""
            />
            <p className="title__5">1.Créer vos scenarios</p>
            <p className="description">
              Créer des scenarios de tests ciblés pour collecter le maximum de
              ressent utilisateurs ​(Tests de site web, applications mobiles,
              prototypes….)​
            </p>
          </Col>
          <Col>
            <img
              style={{ width: "15em", height: "12em" }}
              src={Audiance}
              alt=""
            />
            <p className="title__5">2.Cibler votre ​audience</p>
            <p className="description">
              Définisser précisément le panel de clients dont vous souhaitez
              collecter les impressions ou utiliser votre propre panel de
              testeurs.​
            </p>
          </Col>
          <Col>
            <img
              style={{ width: "15em", height: "12em" }}
              src={Analyse}
              alt=""
            />
            <p className="title__5">3.Analyser les retours ​clients</p>
            <p className="description">
              Grâce à notre moteur d’intelligence artificielle, visualiser
              simplement le ressenti de vos clients et optimisez vos
              applications
            </p>
          </Col>
          <Col>
            <img
              style={{ width: "15em", height: "12em" }}
              src={Produit}
              alt=""
            />
            <p className="title__5">4.Améliorer vos produits</p>
            <p className="description">
              Rassembler vos observations, prioriser vos retours et
              générer/partager facilement un rapport de préconisations.
            </p>
          </Col>
        </div>
        <Button
          className="btn__1"
          // onClick={() => {
          //     this.toggle();
          // }}
        >
          {btn1Text}
        </Button>

        <Button
          className="btn__2"
          // onClick={() => {
          //     this.toggle();
          // }}
        >
          {btn2Text}
        </Button>
      </div>
      <div className="container_home">
        <div
          style={{
            display: "flex",
            padding: "0 3em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Row>
            <Col md="4">
              <div
                style={{
                  display: "flex",
                  padding: "5em",
                  flexDirection: "column",
                }}
              >
                <Row>
                  <span className="title__6">Qui utilise Insight Data ? </span>
                </Row>
                <br />
                <br />
                <Row>
                  <span className="title__7">
                    Créer une culture “Customer Centric” devient un impératif
                    pour tous en entreprise.​
                  </span>
                </Row>
              </div>
            </Col>
            <Col md="4">
              <div style={{ alignItems: "start" }}>
                <div className="card__container2">
                  <Col md="2">
                    <img src={Management} alt="" />
                  </Col>
                  <Col md="1"></Col>
                  <Col md="9">
                    <Row>
                      <span className="title__card2">Top Management</span>
                      <p className="text__card2">
                        Soutenir les décisions critiques avec des données
                        objectives. Adopter une approche centrée utilisateur et
                        Data dans la prise de décision.
                      </p>
                    </Row>
                  </Col>
                </div>
                <div className="card__container2">
                  <Col md="2">
                    <img src={Designers} alt="" />
                  </Col>
                  <Col md="1"></Col>
                  <Col md="9">
                    <Row>
                      <span className="title__card2">Designers UI / UX</span>
                      <p className="text__card2">
                        Réaliser des tests utilisateur et des AB tests, valider
                        et optimiser des designs ou des prototypes.
                      </p>
                    </Row>
                  </Col>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div>
                <div className="card__container2">
                  <Col md="2">
                    <img src={Expert} alt="" />
                  </Col>
                  <Col md="1"></Col>
                  <Col md="9">
                    <Row>
                      <span className="title__card2">
                        Experts Marketing & Communication
                      </span>
                      <p className="text__card2">
                        Acquérir et fidéliser plus de clients! Comprendre les
                        perceptions et les attentes à chaque campagne.
                      </p>
                    </Row>
                  </Col>
                </div>
                <div className="card__container2">
                  <Col md="2">
                    <img src={Equipes} alt="" />
                  </Col>
                  <Col md="1"></Col>
                  <Col md="9">
                    <Row>
                      <span className="title__card2">
                        Equipes techniques de développement Agile
                      </span>
                      <p className="text__card2">
                        Faire participer vos utilisateurs à chaque étape du
                        processus Scrum afin d’optimiser le Time To Market
                      </p>
                    </Row>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className='container_home'>
        <div style={{ display: 'flex', padding: '0 3em' }}>
          <Row >
            <p className='title__1'>Qui utilise Insight Data ?</p>
          </Row>
        </div>
        <div className='row__equation'>
          <Row >
            <p className='why'>
              Créer une culture Custmer Centric devient un impératif de tous en entreprise, des designers aux top executives.
            </p>
          </Row>
          <div className='counters3'>
            <Col>
              <div className='reasons'>
                <img style={{ width: '12em', height: "9em" }} src={UXUI} alt="" />
                <div className='display__flex'>
                  <p className='reason'>Designer UI / UX​</p>
                  <p className='description2'>Réaliser des tests utilisateur et des AB tests, valider et optimiser des designs ou des prototypes.</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='reasons'>
                <img style={{ width: '12em', height: "10em" }} src={Marketing} alt="" />
                <div className='display__flex'>
                  <p className='reason'>Expert Marketing​</p>
                  <p className='description2'>Acquérir et fidéliser plus de clients! Comprendre les perceptions et les attentes des clients à chaque campagne publicitaire.</p>
                </div>
              </div>
            </Col>
          </div>
          <div style={{ display: 'flex' }}>
            <Col>
              <div className='reasons' style={{ margin: 'auto' }}>
                <img style={{ width: '12em', height: "12em" }} src={Executive} alt="" />
                <div className='display__flex'>
                  <p className='reason'>Exécutives​</p>
                  <p className='description2'>Soutenir les décisions critiques avec des données objectives. Adopter une approche centrée utilisateur et Data dans la prise de décision.</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='reasons' style={{ margin: 'auto' }}>
                <img style={{ width: '12em', height: "12em" }} src={Production} alt="" />
                <div className='display__flex'>
                  <p className='reason'>Equipe de production Agile​</p>
                  <p className='description2'>Faire participer vos utilisateurs à chaque étape du processus Scrum afin d’optimiser le Time To Market.</p>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
      <div className='container_home'>
        <div style={{ display: 'flex', padding: '0 3em' }}>
          <Row >
            <p className='title__1'>Pricing</p>
          </Row>
        </div>
        <div className='row__stat'>
          <Col>
            <div className='mobile_container'>
              <p className='mobile_header'>Suite Initiation</p>
              <hr className="line2" />
              <div style={{ height: "4em", backgroundColor: '#fff' }}>
                <p className='mobile_body'>Core</p>
                <p className='description3'>First UX step</p>
              </div>
              <hr className="line2" />
              <div style={{ height: '15em', padding: '1rem 0rem' }}>
                <p><b style={{ fontSize: '1.2em' }}>750</b><sup><i className="fa fa-eur"></i> HT</sup>/mois</p>
                <ul>
                  <li className='description4' style={{ textAlign: 'initial' }}>Scenario (Web ou Mobile)</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Panels personnalisés</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse démographique</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse statistique par étape</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse statistiques par testeurs</li>
                </ul>
              </div>
              <Button className='btn__mobile' onClick="">JE COMMENCE</Button>
              <p className="description3">1 mois d'éssai gratuit</p>
            </div>
          </Col>
          <Col>
            <div className='mobile2_container'>
              <p className='mobile_header'> La plus populaire</p>
              <hr className="line2" />
              <div style={{ height: "4em", backgroundColor: '#00a359' }}>
                <p className='mobile_body2'>Pro</p>
                <p className='mobile_body3'>Ux value</p>
              </div>
              <hr className="line2" />
              <div style={{ height: '15em', padding: '1rem 0' }}>
                <p><b style={{ fontSize: '1.2em' }}>1350</b><sup><i className="fa fa-eur"></i> HT</sup>/mois</p>
                <ul>
                  <li className='description4' style={{ textAlign: 'initial' }}>Scenario (Web ou Mobile)</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Scenario produits ou services physiques</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Panels personnalisés</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse statistique par étape</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse statistiques par testeurs</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Journey Map</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>AB Testing</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Test Modérés</li>
                </ul>
              </div>
              <Button className='btn__mobile' onClick="">JE COMMENCE</Button>
              <p className="description3">1 mois d'éssai gratuit</p>
            </div>
          </Col>
          <Col>
            <div className='mobile_container'>
              <p className='mobile_header'>Suite Complète</p>
              <hr className="line2" />
              <div style={{ height: "4em", backgroundColor: '#fff' }}>
                <p className='mobile_body'>Entreprise</p>
                <p className='description3'>Full UX centrics</p>
              </div>
              <hr className="line2" />
              <div style={{ height: '15em', padding: '1rem 0' }}>
                <p><b style={{ fontSize: '1.2em' }}>2250</b><sup><i className="fa fa-eur"></i> HT</sup>/mois</p>
                <ul>
                  <li className='description3' style={{ textAlign: 'initial' }}>Scenario (Web ou Mobile)</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Scenario produits ou services physiques</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Panels personnalisés</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse statistique par étape</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse statistiques par testeurs</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Journey Map</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>AB Testing</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Test Modérés</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Statistiques Avancées</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Analyse Longitudinale</li>
                  <li className='description4' style={{ textAlign: 'initial' }}>Reverse Personnalisé</li>
                </ul>
              </div>
              <Button className='btn__mobile2' onClick="">JE COMMENCE</Button>

            </div>
          </Col>
        </div>
      </div> */}
      <div className="container_home">
        <div className="row__stat2">
          <Col md="10">
            <p className="ref">
              Nous avons apprécié la rapidité et la qualité des retours
              utilisateurs que nous avons pu collecter. Le visionnage ciblé des
              vidéos nous a permis de cibler nos développements à mettre en
              oeuvre dans une logique de quick wins.
            </p>
            <p className="name__font">
              Peter Rebelo Coelho, Chief Operating Officer chez Omnevo
            </p>

            {/* <a href=
              "http://51.68.231.155:4000/"
            >
              <Button className='signup__button' style={{ width: "18em" }} onClick=""> Essayer l'analyse sémantique</Button>
            </a> */}
          </Col>
          <Col md="2">
            <img
              style={{ borderRadius: "25%", width: "8em" }}
              src={Peter}
              alt=""
            />
          </Col>
          {/* <Col>
            <img style={{ width: '28em', height: "22em" }} src="https://img-0.journaldunet.com/lfMd6xKwCd9GKyemqLk5Pbr6qmg=/1280x/smart/85f23b7b0e5b4b8980a9ccb0c61afac7/ccmcms-jdn/11921174.jpg" alt="" />
          </Col> */}
        </div>
        <div className="container_home">
          <div
            style={{
              display: "flex",
              padding: "0 3em",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Row>
              <Col md="5">
                <div className="card__container3">
                  <span className="title__8">Pay as you go</span>
                  {/* <p><b style={{ fontSize: '1.2em' }}>1350</b><sup><i className="fa fa-eur"></i> HT</sup>/mois</p> */}
                  <img className="m-2" src={Price1} alt="" />
                  <span className="more">en savoir plus </span>
                </div>
              </Col>
              <Col md="2"></Col>
              <Col md="5">
                <div className="card__container3">
                  <span className="title__8">Abonnement​</span>
                  <img className="m-2" src={Price2} alt="" />
                  {/* <p><b style={{ fontSize: '1.2em' }}>1350</b><sup><i className="fa fa-eur"></i> HT</sup>/mois</p> */}
                  {/* <img className='mr-2' src={Tick} alt="" /> */}
                  <span className="more">en savoir plus </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="container_home">
          <div
            style={{
              display: "flex",
              padding: "0 3em",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p className="title__8">
              Si vous souhaitez vous rapprocher de vos utilisateurs, faites un
              pas de plus vers eux.
            </p>
          </div>
          <div className="row__equation">
            <div style={{ display: "flex" }}>
              <Col>
                <img
                  style={{ width: "20em", height: "15em" }}
                  src={Diff}
                  alt=""
                />
                <p className="img__legend">
                  Vous Différencier de vos concurrents
                </p>
              </Col>
              <Col>
                <img
                  style={{ width: "20em", height: "15em" }}
                  src={Innovation}
                  alt=""
                />
                <p className="img__legend">Innover plus vite</p>
              </Col>

              <Col>
                <img
                  style={{ width: "20em", height: "15em" }}
                  src={Client}
                  alt=""
                />
                <p className="img__legend">Augmenter l’expérience client</p>
              </Col>
            </div>
          </div>
          <Button
            className="btn__1"
            // onClick={() => {
            //     this.toggle();
            // }}
          >
            {btn1Text}
          </Button>

          <Button
            className="btn__2"
            // onClick={() => {
            //     this.toggle();
            // }}
          >
            {btn2Text}
          </Button>
        </div>
      </div>
      {/* <Modal /> */}
      <CookieConsent
        location="bottom"
        buttonText="Autoriser tous les coockies"
        cookieName="myAwesomeCookieName2"
        declineButtonText="Utiliser Uniquement les cookies nécessaires"
        enableDeclineButton
        onDecline={() => {
          toggleVisible();
        }}
        style={{
          background: "#2B373B",
          alignItems: "flex-start",
          textAlign: "left",
          alignItems: "center",
        }}
        declineButtonStyle={{
          background: "gray",
          color: "white",
          borderRadius: 5,
        }}
        buttonStyle={{
          color: "white",
          background: "green",
          borderRadius: 5,
        }}
        expires={150}
        debug={true}
        enableDeclineButton
        flipButtons
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            paddingLeft: 200,
            paddingRight: 200,
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Ce site Web utilise des cookies.
          </span>
          <span className="font-small-3">
            Nous utilisons des cookies pour personnaliser le contenu et les
            publicités, pour fournir des fonctionnalités de médias sociaux et
            pour analyser notre trafic. Nous partageons également des
            informations sur votre utilisation de notre site avec nos
            partenaires de médias sociaux, de publicité et d'analyse qui peuvent
            les combiner avec d'autres informations que vous leur avez fournies
            ou qu'ils ont collectées à partir de votre utilisation de leurs
            services. Vous consentez à nos cookies si vous continuez à utiliser
            notre site Web.
          </span>
        </div>
      </CookieConsent>
      <Modal visible={visible} toggleVisible={toggleVisible} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(HomePageForm);
