import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Label, Button, Input } from "reactstrap"
import Excel from '../../../assets/excel.svg'
import Heart from '../../../assets/heart.svg'
import Eye from '../../../assets/eye.svg'
import Filter from '../../../assets/filter.svg'
import Edit from '../../../assets/edit.svg'
import Play from '../../../assets/play.svg'
import Linechart from "../../common/charts/line"
import video from "../../../assets/presentation.mp4"
import Table from "../../common/table"
import { Player } from 'video-react';
import { getScenarioById, getScenarios } from '../../../services/scenarioServices'
import { getTestId, getTesterAnalyses, getjourneyMapTester } from '../../../services/analyzeServices'
import CommentaireForm from '../commentaire'
import Modals from '../../common/modal'
import LineChart from '../../common/charts/lineChart2';
// import "./stepAnalyzes.css"

class TesterAnalyzes extends React.Component {
    state = {
        btn: 'all',
        btn2: 'moyenne',
        scenarios: [],
        scenario: {},
        tester: {},
        tests: [],
        play:false

    }

    componentDidMount() {
        getScenarios().then(res => {
            this.setState({ scenarios: res })
        })
        getScenarioById(this.props.scenario.scenarios.length - 1).then(res => {
            this.setState({ scenario: res, step: res.steps[res.steps.length - 1] })
            getTestId(res.id).then(test => {
                this.setState({ tests: test })
            })
        })
        getTesterAnalyses(1).then(res => {
            this.setState({ tester: res })
        })
        getjourneyMapTester(this.props.scenario.scenarios.length - 1).then(res => {
            this.setState({ journeyMap: res })
        })

    }
    UNSAFE_componentWillReceiveProps(nextProps) {

    }
    renderTable = () => {
        const columns = [
            { title: "Etape", field: 'number' },
            { title: "Score", field: 'score' },
            { title: "Intensité", field: 'intensity' },
            { title: "Retranscription", field: 'retanscription' },
            { title: "Durée", field: 'duration' },


        ]
        const actions = [
            {
                icon: () => <img style={{ width: "14px", margin: '0 0 0 1em' }} src={Edit} alt="" />,
                tooltip: 'Commentaire',
                onClick: (event, rowData) => this.toggle(),
            },
            {
                icon: () => <img style={{ width: "14px", margin: '0 1em' }} src={Eye} alt="" />,
                tooltip: 'Détails',
                onClick: (event, rowData) => console.log(rowData),
            },
            // {
            //     icon: () => <img style={{ width: "14px", marginRight: '1em' }} src={Heart} alt="" />,
            //     tooltip: 'Favoris',
            //     onClick: (event, rowData) => console.log(rowData),
            // },
            {
                icon: () => <img style={{ width: "14px" }} src={Excel} alt="" />,
                tooltip: 'Excel',
                onClick: (event, rowData) => console.log(rowData),
            },

        ]

        return (
            <Table
                title=''
                columns={columns}
                data={[{ number: '1', score: '1', intensity: '', retranscription: 'llllllù', duration: '9.2 sec' }]}
                actions={actions}
            />
        )
    }
    toggle = () => {
        this.setState({ show: !this.state.show })
        
    }
    renderModalCommentaire() {
        const { clientTesters, userLabel, loading } = this.state
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Ajouter un commentaire'
            >
                <CommentaireForm
                    confirmText='Ajouter commentaire'
                    handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    currentUser={clientTesters}
                    userText={userLabel}
                    loading={loading}
                    error={this.state.error}
                />

            </Modals>
        )
    }
    filter = (e) => {
        this.setState({ ...this.state, btn: e })
    }
    filter2 = (e) => {
        this.setState({ ...this.state, btn2: e })
    }
    onChange = (e) => {
        this.setState({
            step: this.state.scenario.steps[e.target.value]
        })
    }
    onChangeScenario = (e) => {
        this.setState({
            scenario: this.state.scenarios[e.target.value]
        })
    }
    render() {
        // const data1 = [
        //     {
        //         name: 'Page A', uv: 4000, pv: 2400,
        //     },
        //     {
        //         name: 'Page B', uv: 3000, pv: 1398,
        //     },
        //     {
        //         name: 'Page C', uv: 2000, pv: 9800,
        //     },
        //     {
        //         name: 'Page D', uv: 2780, pv: 3908,
        //     },
        //     {
        //         name: 'Page E', uv: 1890, pv: 4800,
        //     },
        //     {
        //         name: 'Page F', uv: 2390, pv: 3800,
        //     },
        //     {
        //         name: 'Page G', uv: 3490, pv: 4300,
        //     },
        // ];
        const { btn, btn2, scenario, scenarios, play } = this.state
        const rand = () => Math.round(Math.random() * 20 - 10);
        const data = {
            labels: ['testeur 1', 'testeur 2', 'testeur 3', 'testeur 4', 'testeur 5', 'testeur 6'],
            datasets: [
              {
                type: 'line',
                label: 'Journey map',
                backgroundColor: '#00a359',
                borderColor: '#00a359',
                borderWidth: 2,
                fill: false,
                data: [rand(), rand(), rand(), rand(), rand(), rand()],
              },
            
            ],
          };
          const options2 = {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            };
        return (
            <div className='analyze_form'>
                <Row className='header'>
                    <Col>
                        <Row>
                            <Input type="select" name="select" className='btn_filter_select_scena' id="exampleSelect" onChange={this.onChangeScenario}>
                                {this.state.scenarios?.map((scen, idx) => {
                                    console.log(scenario);
                                    return <option key={idx} value={idx} selected>{scen.title}</option>
                                })
                                }

                            </Input>
                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Etapes</Label>
                        </Row>
                        <Row>
                            <span className='header_span'>{scenario.steps?.length} </span>

                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Testeurs</Label>
                        </Row>
                        <Row>

                            <span className='header_span'></span>
                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Panel</Label>
                        </Row>
                        <Row>

                            <span className='header_span'></span>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Label className="header_label">Score</Label>

                        </Row>
                        <Row>
                            <span className='header_span'></span>

                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Durée</Label>
                        </Row>
                        <Row>
                            <span className='header_span'></span>

                        </Row>
                    </Col>
                </Row>
                <Row className='body1'>
                    <Row className='row_1'>
                        {/* <img src={Heart} alt="" className="img_style" /> */}
                        <img src={Excel} alt="" className="img_style" />
                    </Row>
                    <div className='chart_container'>
                        {/* <Row className='filter'>
                            <Label className='filter_text'>Scénario</Label>
                            
                        </Row> */}
                        <Row className='filter'>
                            <img src={Filter} alt="" className="img_style2" />
                            <Label className='filter_text'>Filter</Label>
                            <Button className={`btn_filter${(btn === "all") ? '_selected' : ''}`} onClick={() => this.filter('all')}>All</Button>
                            <Button className={`btn_filter${(btn === "reponse") ? '_selected' : ''}`} onClick={() => this.filter('reponse')}>Réponse</Button>
                            <Button className={`btn_filter${(btn === "commentaire") ? '_selected' : ''}`} onClick={() => this.filter('commentaire')}>Commentaire</Button>
                            <Button className={`btn_filter${(btn === "video") ? '_selected' : ''}`} onClick={() => this.filter('video')}>Vidéo</Button>
                            <span className='journey_map' onClick={() => this.filter('map')}>Jouney Map Testeur</span>

                        </Row>
                        <Row className='filter2'>
                            <Button className={`btn_filter${(btn2 === "moyenne") ? '_selected' : ''}`} onClick={() => this.filter2('moyenne')}>Moyenne</Button>
                            <Button className={`btn_filter${(btn2 === "ecart") ? '_selected' : ''}`} onClick={() => this.filter2('ecart')}>Ecart-type</Button>
                        </Row>
                        <Row className='chat_style'>
                        <LineChart
                                data={data}
                                options={options2}
                            />
                        </Row>
                        {/* <Row style={{ justifyContent: 'center', }}>
                            <Col>
                                <Row>
                                    <Label className='label_top'>Top 3</Label>
                                    <div className='top_1'></div>
                                    <div className='top_2'></div>
                                    <div className='top_3'></div>
                                </Row>
                            </Col>
                            <Col>
                                <Row>

                                    <Label className='label_top2'>Top 3</Label>
                                    <div className='top_3_1'></div>
                                    <div className='top_3_2'></div>
                                    <div className='top_3_3'></div>
                                </Row>

                            </Col>
                        </Row> */}
                    </div>
                </Row>
                <Row className='body2'>
                    <Row className='row_1'>
                        {/* <img src={Heart} alt="" className="img_style" /> */}
                        <img src={Excel} alt="" className="img_style" />
                    </Row>
                    <div className='chart_container'>
                        <Row className='filter'>

                            <Input type="select" name="select" className='btn_filter_select' id="exampleSelect" onChange={this.onChange}>
                                {this.state.tests?.map((step, idx) => {
                                    return <option key={idx} value={idx} selected>testeur {idx + 1}</option>
                                })
                                }

                            </Input>
                            <span className='duration '>Durée moyenne : 10 sec </span>
                            <span className='journey_map'>Analyse par testeur </span>
                        </Row>
                        <Row className='filter'>
                            {this.renderTable()}
                            {this.renderModalCommentaire()}
                        </Row>
                        <Row className='filter'>
                            <span></span>
                            <span></span>
                        </Row>
                        <Row className='chat_style'>
                            <Col md='7'>
                            {play ? <Player
                                    autoPlay
                                    playsInline
                                    src={video}
                                /> :
                                    <div className='video_container'>
                                        <img src={Play} alt="" className='play' onClick={() => this.setState({ play: !this.state.play })} />
                                    </div>}
                            </Col>
                            <Col md="5">
                                <Row style={{ textAlign: 'center', marginTop: '2em', paddingLeft: '1em' }}>
                                    <Label className="video_label">Réponse:</Label>
                                    <span className='video_span'></span>
                                </Row>
                                <Row style={{ textAlign: 'center', marginTop: '2em', paddingLeft: '1em' }}>
                                    <Label className="video_label">Commentaire:</Label>
                                    <span className='video_span'></span>
                                </Row>
                                <Row style={{ textAlign: 'center', marginTop: '2em', paddingLeft: '1em' }}>
                                    <Label className="video_label">Retranscription vidéo:</Label>
                                    <span className='video_span'></span>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {}
)(TesterAnalyzes);