import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Label, Button, Input } from "reactstrap"
import Excel from '../../../assets/excel.svg'
import Heart from '../../../assets/heart.svg'
import Eye from '../../../assets/eye.svg'
import Compare from '../../../assets/compare.svg'
import Info from '../../../assets/info.svg'
import Linechart from "../../common/charts/lineChart2"
import Bar from "../../common/charts/bar"
import video from "../../../assets/presentation.mp4"
import Table from "../../common/table"
import { Player } from 'video-react';
import { getScenarioById, getScenarios } from '../../../services/scenarioServices'
import { getScenarioAnalyses, getStepAnalyses, getTestById } from '../../../services/analyzeServices'
import CommentaireForm from '../commentaire'
import Modals from '../../common/modal'
import "./facialRecognition.css"

class FacialRecognition extends React.Component {
    state = {
        btn: 'joie',
        scenarios: [],
        scenario: {},
        step: {},
        

    }

    componentDidMount() {
        getScenarios().then(res => {
            this.setState({ scenarios: res, 
            //     data1 : [
            //     {
            //         name: 'Page A', uv: this.state.btn2==='moyenne'?4000:0, pv: this.state.btn2==='ecart'?2400:0,
            //     },
            //     {
            //         name: 'Page B', uv: this.state.btn2==='moyenne'?3000:0, pv: this.state.btn2==='ecart'?1398:0,
            //     },
            //     {
            //         name: 'Page C', uv: this.state.btn2==='moyenne'?2000:0, pv: this.state.btn2==='ecart'?9800:0,
            //     },
            //     {
            //         name: 'Page D', uv: this.state.btn2==='moyenne'?2780:0, pv: this.state.btn2==='ecart'?3908:0,
            //     },
            //     {
            //         name: 'Page E', uv: this.state.btn2==='moyenne'?1890:0, pv: this.state.btn2==='ecart'?4800:0,
            //     },
            //     {
            //         name: 'Page F', uv: this.state.btn2==='moyenne'?2390:0, pv: this.state.btn2==='ecart'?3800:0,
            //     },
            //     {
            //         name: 'Page G', uv: this.state.btn2==='moyenne'?3490:0, pv: this.state.btn2==='ecart'?4300:0,
            //     },
            // ] 
        })
        })
        getScenarioAnalyses(this.props.scenario.scenarios.length - 1).then(res => {
            this.setState({ scenariosAnalyze: res })
        })
        getStepAnalyses(this.props.scenario.scenarios.length - 1).then(res => {
            this.setState({ stepAnalyze: res })
        })
        getScenarioById(this.props.scenario.scenarios.length - 1).then(res => {
            this.setState({ scenario: res, step: res.steps[res.steps.length - 1] })
        })

    }
    UNSAFE_componentWillReceiveProps(nextProps) {

    }
    toggle = () => {
        this.setState({ show: !this.state.show })
        
    }
    renderModalCommentaire() {
        const { clientTesters, userLabel, loading } = this.state
        return (
            <Modals
                // modalSize="modal-lg"
                show={this.state.show}
                toggleShow={this.toggle}
                header='Ajouter un commentaire'
            >
                <CommentaireForm
                    confirmText='Ajouter commentaire'
                    handleSubmit={this.onSubmit}
                    onchange={this.onChange}
                    currentUser={clientTesters}
                    userText={userLabel}
                    loading={loading}
                    error={this.state.error}
                />

            </Modals>
        )
    }
 
    filter = (e) => {
        this.setState({ ...this.state, btn: e })
    }
    onChange = (e) => {
        this.setState({
            step: this.state.scenario.steps[e.target.value]
        },()=>{
            getStepAnalyses(this.state.step?.id).then(res => {
                this.setState({ stepAnalyze: res })
            })
        })
    }
    onChangeScenario = (e) => {
        this.setState({
            scenario: this.state.scenarios[e.target.value]
        },()=>{
            getScenarioAnalyses(this.state.scenario.id).then(res => {
                this.setState({ scenariosAnalyze: res })
            })
        })
    }
    render() {
      
        const { btn, btn2, scenario, scenarios } = this.state
   
          const rand = () => Math.round(Math.random() * 20 - 10);

          const data = {
            labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
            datasets: [
              {
                type: 'line',
                label: 'moyenne',
                backgroundColor: '#00a359',
                borderColor: '#00a359',
                borderWidth: 2,
                fill: false,
                data: [rand(), rand(), rand(), rand(), rand(), rand()],
              },
              {
                type: 'scatter',
                label: 'testeur 1',
                backgroundColor: '#00a359',
                borderColor: '#00a359',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'testeur 2',
                backgroundColor: '#00a359',
                borderColor: '#00a359',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'testeur 3',
                backgroundColor: '#00a359',
                borderColor: '#00a359',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
            
            ],
          };
          const data1 = {
            labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
            datasets: [
              {
                type: 'line',
                label: 'Dataset 1',
                backgroundColor: '#024089',
                borderColor: '#024089',
                borderWidth: 2,
                fill: false,
                data: [rand(), rand(), rand(), rand(), rand(), rand()],
              },
              {
                type: 'scatter',
                label: 'Dataset 2',
                backgroundColor: '#024089',
                borderColor: '#024089',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'Dataset 3',
                backgroundColor: '#024089',
                borderColor: '#024089',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'Dataset 4',
                backgroundColor: '#024089',
                borderColor: '#024089',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
            
            ],
          };
          const data2 = {
            labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
            datasets: [
              {
                type: 'line',
                label: 'Dataset 1',
                backgroundColor: '#F68E12',
                borderColor: '#F68E12',
                borderWidth: 2,
                fill: false,
                data: [rand(), rand(), rand(), rand(), rand(), rand()],
              },
              {
                type: 'scatter',
                label: 'Dataset 2',
                backgroundColor: '#F68E12',
                borderColor: '#F68E12',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'Dataset 3',
                backgroundColor: '#F68E12',
                borderColor: '#F68E12',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'Dataset 4',
                backgroundColor: '#F68E12',
                borderColor: '#F68E12',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
            
            ],
          };
          const data3 = {
            labels:['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
            datasets: [
              {
                type: 'line',
                label: 'Dataset 1',
                backgroundColor: '#FB4141',
                borderColor: '#FB4141',
                borderWidth: 2,
                fill: false,
                data: [rand(), rand(), rand(), rand(), rand(), rand()],
              },
              {
                type: 'scatter',
                label: 'Dataset 2',
                backgroundColor: '#FB4141',
                borderColor: '#FB4141',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'Dataset 3',
                backgroundColor: '#FB4141',
                borderColor: '#FB4141',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
              {
                type: 'scatter',
                label: 'Dataset 4',
                backgroundColor: '#FB4141',
                borderColor: '#FB4141',
                data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
                borderWidth: 2,
              },
            
            ],
          };
          const options2 = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 5,
                  },
                },
              ],
            },
          };

        const dataOptions = {
            labels:['testeur 1', 'testeur 2', 'testeur 3', 'testeur 4', 'testeur 5', 'testeur 6'],
            datasets: [
              {
                label: "indice d'emotion",
                data:[4, 1, 3, 5, 2, 3],
                backgroundColor: "#17A25C ",
                borderColor: "#00a359",
              },
            ],
          };
          
          const options = {
            indexAxis: "x",
            ticks: {
              beginAtZero: true, // minimum value will be 0.
              // <=> //
              min: 0,
              max: 5,
              stepSize: 1, // 1 - 2 - 3 ...
              major: {
                //           enabled: true
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                },
              ],
            },
          };
        return (
            <div className='analyze_form'>
                <Row className='header'>
                    <Col>
                        <Row>
                        <Input type="select" name="select" className='btn_filter_select_scena' id="exampleSelect" onChange={this.onChangeScenario}>
                                {this.state.scenarios?.map((scen, idx) => {
                                    console.log(scenario);
                                    return <option key={idx} value={idx} selected>{scen.title}</option>
                                })
                                }

                            </Input>
                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Etapes</Label>
                        </Row>
                        <Row>
                            <span className='header_span'>{scenario.steps?.length} </span>

                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Testeurs</Label>
                        </Row>
                        <Row>

                            <span className='header_span'></span>
                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Panel</Label>
                        </Row>
                        <Row>

                            <span className='header_span'></span>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Label className="header_label">Score</Label>

                        </Row>
                        <Row>
                            <span className='header_span'></span>

                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <Label className="header_label">Durée</Label>
                        </Row>
                        <Row>
                            <span className='header_span'></span>

                        </Row>
                    </Col>
                </Row>
                <Row className='body1'>
                    <Row className='row_1'>
                        <img src={Info} alt="" className="img_style" />
                        <img src={Excel} alt="" className="img_style" />
                    </Row>
                    <div className='chart_container'>
                     
                        <Row className='filter'>
                            <img src={Compare} alt="" className="img_style2" />
                            <Label className='filter_text'>Comparer</Label>
                            <Button className={`btn_filter1${(btn === "joie") ? '_selected1' : ''}`} onClick={() => this.filter('joie')}>Joie</Button>
                            <Button className={`btn_filter2${(btn === "surprise") ? '_selected2' : ''}`} onClick={() => this.filter('surprise')}>Surprise</Button>
                            <Button className={`btn_filter3${(btn === "tristesse") ? '_selected3' : ''}`} onClick={() => this.filter('tristesse')}>Tristesse</Button>
                            <Button className={`btn_filter4${(btn === "colère") ? '_selected4' : ''}`} onClick={() => this.filter('colère')}>Colère</Button>
                            <span className='journey_map' onClick={() => this.filter('map')}>Courbe d’émotions</span>

                        </Row>
                        <Row className='chat_style'>
                            {btn==='joie'&&<Bar
                                data={data}
                                options={options2}
                            />}
                            {btn==='surprise'&&<Bar
                                data={data1}
                                options={options2}
                            />}
                           {btn==='tristesse'&& <Bar
                                data={data2}
                                options={options2}
                            />}
                            {btn==='colère'&&<Bar
                                data={data3}
                                options={options2}
                            />}
                        </Row>
                        {/* <Row style={{ justifyContent: 'center', }}>
                            <Col>
                                <Row>
                                    <Label className='label_top'>Top 3</Label>
                                    <div className='top_1'></div>
                                    <div className='top_2'></div>
                                    <div className='top_3'></div>
                                </Row>
                            </Col>
                            <Col>
                                <Row>

                                    <Label className='label_top2'>Top 3</Label>
                                    <div className='top_3_1'></div>
                                    <div className='top_3_2'></div>
                                    <div className='top_3_3'></div>
                                </Row>

                            </Col>
                        </Row> */}
                    </div>
                </Row>
                <Row className='body2'>
                    <Row className='row_1'>
                        <img src={Info} alt="" className="img_style" />
                        <img src={Excel} alt="" className="img_style" />
                    </Row>
                    <div className='chart_container'>
                        <Row className='filter'>

                            <Input type="select" name="select" className='btn_filter_select_scena' id="exampleSelect" onChange={this.onChange}>
                                {this.state.scenario?.steps?.map((step, idx) => {
                                    return <option key={idx} value={step} selected>Etape {idx + 1}</option>
                                })
                                }

                            </Input>
                            <span className='duration '></span>
                            <span className='journey_map'>Emotion par étape </span>
                        </Row>
                        <Row className='filter'>
                            {/* {this.renderModalCommentaire()} */}
                        </Row>
                        <Row className='filter'>
                            <span></span>
                            <span></span>
                        </Row>
                        <Row className='chat_style'>
                        <Bar data={dataOptions} options={options} />
                        </Row>
                    </div>
                </Row>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {}
)(FacialRecognition);