import React, { useEffect, useState } from "react";
import { Form, Input, Label, Col, Row, FormGroup, Button } from "reactstrap";
import { getCurrentUser, getJwt } from "../../../../services/authServices";
import { getAuthorization } from "../../../../services/userServices";
import http from "../../../../services/httpServices";
import { apiUrl } from "../../../../config.json";
import axios from "axios";



const apiEndpoint = apiUrl;
const tokenKey = "token";
http.setJwt(getJwt());
function InfosPers() {
    const [user, setUser] = React.useState({});
    React.useEffect(() => {
        axios
          .get(apiEndpoint + "/api/currentuser", {
            headers: getAuthorization(),
          })
          .then((response) => {
            setUser(response);
            console.log(response);
          })
          .catch((error) => {
            console.log(error, "err");
          });
      }, []);
    return (
        <Col lg="7" md="7" className="p-3 main">
        <Form>
          <Row>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Prénom</Label>
                <Input placeholder={user?.data?.name} />
              </div>
            </Col>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Nom</Label>
                <Input placeholder={user?.data?.lastname} />
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>date de naissance</Label>
                <Input placeholder={"jj/mm/aaaa"} />
              </div>
            </Col>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Code postal</Label>
                <Input placeholder={"xxxx"} />
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Ville</Label>
                <Input placeholder={"Ville"} />
              </div>
            </Col>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Pays</Label>
                <Input placeholder={"pays"} />
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Genre</Label>
                <Input placeholder={"genre"} />
              </div>
            </Col>
            <Col>
              <div className="column-left">
                <Label style={{fontWeight:'bold'}}>Langue maternelle</Label>
                <Input placeholder={"Langue maternelle"} />
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <FormGroup className="column-left">
                <Label style={{fontWeight:'bold'}}>Adresse email</Label>
                <Input placeholder={user?.data?.email} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="column-left">
                <Label style={{fontWeight:'bold'}}>Numéro du portable</Label>
                <Input type="number" placeholder={user?.data?.phone} />
              </FormGroup>
            </Col>
          </Row>
          <Col
            md="12"
            lg="12"
            className="d-flex justify-content-end no-margin no-padding"
          >
            <Button className="btn-success"> ENREGISTRER</Button>
          </Col>
        </Form>
      </Col>
    )
}

export default InfosPers
