import React, { useEffect, useState } from "react";
import { ReactMic } from "react-mic";
import headset from "../../../assets/headset.png";
import micro from "../../../assets/micro.png";
import Logo from "../../../assets/logo-vector.svg";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";

const useAudio = (url) => {
  const [audio] = useState(
    new Audio("https://www.kozco.com/tech/organfinale.wav")
  );
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const ModalTest = ({ show, children, url }) => {
  const [visible, setVisible] = React.useState(true);
  const [record, setRecord] = React.useState(false);

  const [playing, toggle] = useAudio(
    "https://www.kozco.com/tech/organfinale.wav"
  );

  function handleClose() {
    setVisible(!visible);
  }
  function startRecording() {
    setRecord(true);
  }

  function stopRecording() {
    setRecord(false);
  }
  function toggleRecord() {
    setRecord(!record);
  }
  function onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob);
  }

  function onStop(recordedBlob) {
    console.log("recordedBlob is: ", recordedBlob);
  }
  return (
    <Modal
      isOpen={visible}
      className="modal-xl center-modal"
      toggle={handleClose}
    >
      <Row className="padding-modal">
        <Col md="6" sm="6" lg="6" className="p-2 d-flex justify-content-start">
          <img style={{ maxWidth: "170px" }} src={Logo} alt="Logo" />
        </Col>
        <Col
          md="6"
          sm="6"
          lg="6"
          className="p-2 d-flex justify-content-end height-X"
        >
          <Button onClick={handleClose}>
            <span>X</span>
          </Button>
        </Col>
      </Row>

      <ModalBody>
        <Col
          md="12"
          lg="12"
          className="d-flex flex-column justify-content-space-around"
        >
          <strong>
            Bienvenue au sein de la communauté de testeurs insightData.
          </strong>
          <span>Nous sommes trés heureux de t'accueillir parmis nous.</span>
          <span>
            Nous allons te proposer deux tests pour s'assurer que ton
            ordinateur/mobile sont bien paramétrés.
          </span>
          <div className="test-sound ">
            <img className="headset-image" src={headset} alt="" />
            <span className="font-weight-600 ">Tester le son.</span>
            <Button className="btn-success" onClick={toggle}>
              {playing ? "Arréter" : "Démarrer"}
            </Button>
          </div>
          <div className="test-mic ">
            <img className="headset-image" src={micro} alt="" />
            <span className="font-weight-600 ">Tester le micro.</span>
            <Button onClick={toggleRecord} className="btn-success">
              {record ? "Arréter" : "Démarrer"}
            </Button>
          </div>
        </Col>
        <div>
          <ReactMic
            record={record}
            onStop={onStop}
            onData={onData}
            strokeColor="green"
            backgroundColor="#F0F0F0"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleClose} className="btn-success">
          {" "}
          Plus tard
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ModalTest;
