import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { menuLandingClientTester } from "../../../utils/routes";
import NavTab from "../../navTab/navTab";
import "../landingPage.css";
import maleAvatar from "../../../assets/male-avatar.jpeg";
import bandeau from "../../../assets/bandeau.png";

import Modal from "./Modal";
import Header from "./Header";
import ModalTest from "./ModalTest";
import ModalForm from "./ModalForm";
import QuestionsClient from "./questions";

class LandingClientTester extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showBigHeader = (isHidden) => {
    this.setState({ isHidden: true });
    console.log(this.state.isHidden);
  };
  showSmallHeader = (isHidden) => {
    this.setState({ isHidden: false });
    console.log(this.state.isHidden);
  };
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  render() {
    const { lng } = this.props.auth;
    const location = this.props.location;
    const path = location?.pathname;
    console.log({ path });
    return (
      <React.Fragment>
        <div className="main">
          <div>
            {/* <Switch>
              <Route path={`/tester/questions`} component={Questions} />
              {menuLandingTester(lng).map((route) => (
                <Route
                  key={route.id}
                  path={`/tester${route.path}`}
                  component={route.component}
                />
              ))}
            </Switch> */}
            <Switch>
              <Route
                path={`/client-tester/questions/:id`}
                component={QuestionsClient}
              />
              {menuLandingClientTester(lng).map((route) => (
                <Route
                  key={route.id}
                  path={`/client-tester${route.path}`}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps)(LandingClientTester);
