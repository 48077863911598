import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());

export const getScenarios = async () => {
    const scenarios = await axios.get(apiEndpoint + "/api/scenario", {
        headers: getAuthorization()
    })
    const { data } = scenarios
    return data;
}
export const getCount = async () => {
    const compteur = await axios.get(apiEndpoint + "/api/clients/compteurs", {
        headers: getAuthorization()
    })
    const { data } = compteur
    return data;
}
export const getScenarioById = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/scenario/${id}`, {
        headers: getAuthorization()
    })
    const { data } = scenario
    return data;
}
export async function saveScenario(scenario) {
    const res = await http.post(apiEndpoint + "/api/scenario", scenario, {
        headers: getAuthorization()
    });
    return res;
}
export const saveStep = async (id, steps) => {
    const step = await axios.post(apiEndpoint + `/api/scenario/${id}/steps`, steps, {
        headers: getAuthorization()
    })
    const { data } = step
    return data;
}
export const saveVideo = async (id, video) => {
    const res = await axios.post(apiEndpoint + `/api/tests/${id}`,video, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}
export async function deleteScenario(id) {
    const res = await axios.delete(apiEndpoint + `/api/scenario/${id}`, { headers: getAuthorization() });
    return res
}
export const getPanels = async () => {
    const panels = await axios.get(apiEndpoint + "/api/panels", {
        headers: getAuthorization()
    })
    const { data } = panels
    return data;
}
export const savePanel = async (panel,id) => {
    const res = await axios.post(apiEndpoint + `/api/panels/scenario/${id}`, panel, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}
export function getJwt() {
    return localStorage.getItem(tokenKey);
}
function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}