import React from "react";

class DashboardClientTester extends React.Component {
  state = {};
  render() {
    return null;
  }
}

export default DashboardClientTester;
