import React from "react";
import ClosedComponentByTester from "./ClosedComponentByTester";
import OpenComponentByTester from "./OpenComponentByTester";
import ScaleComponentByTester from "./ScaleComponentByTester";
import axios from "axios";
import { getAuthorization } from "../../../../services/userServices";
import { apiUrl } from "../../../../config.json";
const apiEndpoint = apiUrl;

function TesterResume(props) {
  const [testerData, setTesterData] = React.useState([]);
  const [listOfTesters, setListOfTesters] = React.useState([]);
  const [currentTester, setCurrentTester] = React.useState(null);
  const [stepCount, setStepCount] = React.useState(1);
  const LoadTestersByScenario = (x) => {
    axios
      .get(apiEndpoint + `/api/scenario/${x}/testers`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        if (response?.data) setListOfTesters(response?.data);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };
  const LoadScenarioByTester = async (id, _id) => {
    axios
      .get(apiEndpoint + `/api/stats/tester/${id}/${_id}`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        console.log("ddddddddddddddddddd", response?.data[0]);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };
  React.useEffect(() => {
    LoadTestersByScenario(props?.senario);
  }, [props]);
  const getStepsCount = () => {
    const nbTesters = listOfTesters?.length;
    return (
      <div className="d-flex ">
        <span
          className="mr-2 cursor-pointer strong"
          onClick={() => incrimentTester(-1)}
        >
          -
        </span>
        <span>{` ${stepCount}/ ${nbTesters} `}</span>
        <span
          className="ml-2 cursor-pointer strong"
          onClick={() => incrimentTester(1)}
        >
          +
        </span>
        {/* <img
          src={arrowL}
          alt=""
          className="excel-img cursor-pointer"
          onClick={() => incrementStep(1)}
        /> */}
      </div>
    );
  };
  const incrimentTester = () => {};
  return (
    <React.Fragment>
      <div>
        {props?.ScenarioOfTester?.map((ids) => {
          if (ids.step.type === "close") {
            return (
              <ClosedComponentByTester ids={ids?.step} answer={ids?.answer} />
            );
          }
          if (ids.step.type === "open") {
            return (
              <OpenComponentByTester ids={ids?.step} answer={ids?.answer} />
            );
          }
          if (ids.step.type === "scale") {
            return (
              <ScaleComponentByTester ids={ids?.step} answer={ids?.answer} />
            );
          }
        })}
      </div>
    </React.Fragment>
  );
}

export default TesterResume;
