import React from "react";

function ClosedStep(props) {
  React.useEffect(() => {}, []);

  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue?.count;

  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 20 }}>
          <span className="top-component-fine-black-style">
            {props?.data?.step?.question}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div>
          <strong>
            {" "}
            {props?.data?.step?.answers?.reduce(reducer, 0)} réponses
          </strong>
        </div>
        <div>
          <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
            Question fermée
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F0F0F0",
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        {props?.data?.step?.answers.map((d) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                padding: 10,
                margin: 10,
                backgroundColor: "white",
                alignItems: "flex-start",
                borderRadius: 5,
                height: 150,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "0.1px solid gray",
                  paddingLeft: 15,
                }}
              >
                <div
                  style={{
                    height: 26,
                    width: 26,
                    borderRadius: 13,
                    backgroundColor: "#17A25C",
                  }}
                />
                <div
                  style={{
                    paddingLeft: 15,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  {d?.answer}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  paddingLeft: 15,
                }}
              >
                {" "}
                <span style={{ fontWeight: "bold", color: "gray" }}>
                  {" "}
                  {d?.count} réponses
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ClosedStep;
