import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Label, Input } from "reactstrap"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { savePanel } from '../../../services/scenarioServices'
import { toast } from 'react-toastify';

class CreatePanelInsight extends Component {
    state = {
        currentPanel: {
            name: "",
            scenarioName: "",
            testersNb: "",
            product: "",
            minAge: "",
            maxAge: "",
            gender: "",
            csp: "",
            os: "",
            studyLevel: "",
            country: "",
            maritalStatus: "",
            type: 'insight data'
        },
        idScenario: "",
        checkedAge: false,
        checkedCSP: false,
        checkedOS: false,
        checkedGenre: false,
        checkedEtude: false,
        checkedPays: false,
    }
    onChange = e => {
        e.preventDefault();
        this.setState({ currentPanel: { ...this.state.currentPanel, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" }, error: {} })
    };
    onChangeScenario = e => {
        e.preventDefault();
        this.setState({ ...this.state, idScenario: e.target.value })
    };
    submit = () => {
        const { name, testersNb, product, minAge, maxAge, gender, csp, os, studyLevel, country, maritalStatus, type } = this.state.currentPanel
        const panel = {
            name, scenarioName: "", testersNb: parseInt(testersNb), product, minAge: parseInt(minAge), maxAge: parseInt(maxAge), gender, csp, os, studyLevel, country, maritalStatus, type
        }
        savePanel(panel, this.state.idScenario).then(res => {
            toast.success('Panel ajouter avec success')
            this.props.history.push('/client/panellist')
            console.log(res);
        })
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: !this.state[e.target.name] })
    }
    render() {
        const scenarios = [{ id: "", title: 'veulliez selectionner votre scénario' }, ...this.props.scenario.scenarios]
        const {
            name,
            scenarioName,
            testersNb,
            minAge, maxAge
        } = this.state.currentPanel
        const genders = ["Femme", "Homme"]
        const csp = ["Agriculteurs exploitants", "Artisans", " commerçants et chefs d’entreprise", "Cadres et professions intellectuelles supérieures, Professions Intermédiaires", "Employés", "Ouvriers"]
        const study = ["Brevet", "Bac", "Bac +2", "Bac +3/4", "Bac +5", "> Bac +5"]
        const pays = ["", "..."]
        const os = ["IOS", "Android", "Windows", "Autre"]
        const support = ["Ordinateur", "Mobile", "Tablette"]
        const { checkedAge, checkedCSP, checkedEtude, checkedGenre, checkedOS, checkedPays } = this.state
        return (<div className="paneltester__ctn" >
            <span className="title__Panel">Créer un panel de testeurs</span>
            <Row className='row_panel'>
                <Col md='12'>
                    <Row className='row__form'>
                        <Label className='label__form3'>Nom du scénario:</Label>
                        <Input type="select" name="scenarioName" id="panel" onChange={this.onChangeScenario}>
                            {scenarios.map((scenario) => <option key={scenario.id} value={scenario.id}>{scenario.title}</option>)}
                        </Input>
                        {/* <Input
                            //  placeholder='(Facultatif)'
                            value={scenarioName}
                            type="text"
                            onChange={this.onChange}
                            name="scenarioName"
                            id=""
                        /> */}
                    </Row>
                    <Row className='row__form'>
                        <Label className='label__form3'>Nom du panel:</Label>
                        <Input
                            // placeholder='(Facultatif)'
                            value={name}
                            type="text"
                            onChange={this.onChange}
                            name="name"
                            id="panel"
                        />
                    </Row>
                    <Row className='row__form'>
                        <Label className='label__form'>Nombre de testeurs:</Label>
                        <Input
                            //   placeholder='(Facultatif)'
                            value={testersNb}
                            type="number"
                            onChange={this.onChange}
                            name="testersNb"
                            id="number"
                        />
                    </Row>
                    <Row className='row__form'>
                        <Label className='label__form'>Support de test:</Label>
                        <Input type="select" name="product" id="support" onChange={this.onChange}>
                            {support.map(supp => <option key={supp} value={supp}>{supp}</option>)}

                        </Input>
                    </Row>
                </Col>
            </Row>
            {/* {(checkedAge || checkedCSP || checkedEtude || checkedGenre || checkedOS || checkedPays) &&  */}
            <span className="title__Panel">Filters</span>
            <Row className='row_panel'>
                <Col md='12'>
                    {/* <Row className='row__form'> */}
                        {/* <Label className='label__form'>Filter:</Label> */}

                        {/* {checkedAge &&  */}
                        <Row className='row__form'>
                            <Label className='label__form'>Age:</Label>
                            <Input
                                //   placeholder='(Facultatif)'
                                value={minAge}
                                type="number"
                                onChange={this.onChange}
                                name="minAge"
                                id="number2"
                            />
                            <Label className='label__form2'>Min</Label>
                            <Input
                                //   placeholder='(Facultatif)'
                                value={maxAge}
                                type="number"
                                onChange={this.onChange}
                                name="maxAge"
                                id="number2"
                            />
                            <Label className='label__form2'>Max</Label>
                        </Row>
                        {/* } */}
                        {/* {checkedGenre && */}
                         <Row className='row__form'>
                            <Label className='label__form'>Genre:</Label>
                            <Input type="select" name="gender" id="support2" onChange={this.onChange}>
                                {genders.map(supp => <option key={supp} value={supp}>{supp}</option>)}

                            </Input>
                        </Row>
                         {/* } */}
                        {/* {checkedCSP &&  */}
                        <Row className='row__form'>
                            <Label className='label__form'>Csp:</Label>
                            <Input type="select" name="select" id="support2" onChange={this.onChange}>
                                {csp.map(supp => <option key={supp} value={supp}>{supp}</option>)}

                            </Input>
                        </Row>
                        {/* } */}
                        {/* {checkedOS &&  */}
                        <Row className='row__form'>
                            <Label className='label__form'>OS:</Label>
                            <Input type="select" name="select" id="support2" onChange={this.onChange}>
                                {os.map(supp => <option key={supp} value={supp}>{supp}</option>)}

                            </Input>
                        </Row>
                        {/* }
                        {checkedEtude &&  */}
                        <Row className='row__form'>
                            <Label className='label__form'>Niveau d'étude:</Label>
                            <Input type="select" name="studyLevel" id="support2" onChange={this.onChange}>
                                {study.map(supp => <option key={supp} value={supp}>{supp}</option>)}

                            </Input>
                        </Row>
                        {/* }
                        {checkedPays &&  */}
                        <Row className='row__form'>
                            <Label className='label__form'>Pays de résidance:</Label>
                            <Input type="select" name="country" id="support2" onChange={this.onChange}>
                                {pays.map(supp => <option key={supp} value={supp}>{supp}</option>)}

                            </Input>
                        </Row>
                        {/* } */}


                    {/* </Row> */}
                    {/* } */}
                </Col>
                {/* <Col md='4'>
                    <div className='filterBox__ctn'>
                        <Row style={{ marginTop: '-1em' }}><h6 style={{ color: '#00a359' }}>Boite à filtres</h6></Row>
                        <Row style={{ marginTop: '2em' }}>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedAge}
                                            onChange={this.handleChange}
                                            name="checkedAge"
                                            color="primary"
                                        />
                                    }
                                    label="Age"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedGenre}
                                            onChange={this.handleChange}
                                            name="checkedGenre"
                                            color="primary"
                                        />
                                    }
                                    label="Genre"
                                />
                            </Col>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedCSP}
                                            onChange={this.handleChange}
                                            name="checkedCSP"
                                            color="primary"
                                        />
                                    }
                                    label="CSP"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedOS}
                                            onChange={this.handleChange}
                                            name="checkedOS"
                                            color="primary"
                                        />
                                    }
                                    label="OS"
                                />
                            </Col>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedEtude}
                                            onChange={this.handleChange}
                                            name="checkedEtude"
                                            color="primary"
                                        />
                                    }
                                    label="N.Etude"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedPays}
                                            onChange={this.handleChange}
                                            name="checkedPays"
                                            color="primary"
                                        />
                                    }
                                    label="Pays"
                                />
                            </Col>
                        </Row>
                    </div>
                </Col> */}
            </Row>
            <Row className='row__create_sc'>
                <Button className='finish' style={{ width: "10em" }} onClick={this.submit}> Créer le panel</Button>
            </Row>
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    {}
)(CreatePanelInsight);