import {
    SUCCESS_GET_SCENARIOS, FAILED_GET_SCENARIOS, FAILED_GET_PANELS,
    SUCCESS_GET_PANELS, SUCCESS_GET_COUNT, FAILED_GET_COUNT
} from "../typeActions/scenario"

const INITIAL_STATE = {
    scenario: {},
    scenarios: [],
    panels: [],
    count:{},
    error: "",

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUCCESS_GET_SCENARIOS:
            return {
                ...state,
                scenarios: action.scenarios,
                error: ""
            };
        case SUCCESS_GET_COUNT:
            return {
                ...state,
                count: action.count,
                error: ""
            };
        case SUCCESS_GET_PANELS:
            return {
                ...state,
                panels: action.panels,
                error: ""
            };
        case FAILED_GET_PANELS:
            return {
                ...state,
                error: action.payload
            };
        case FAILED_GET_COUNT:
            return {
                ...state,
                error: action.payload
            };
        case FAILED_GET_SCENARIOS:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};
