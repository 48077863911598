import {
    EMAIL_CHANGED,
    PASSWORD_CHANGED,
    LOGOUT, FAILED_LOGIN,
    LNG_CHANGED,
    SUCCESS_LOGIN,
    TAB_DASHBOARD_CHANGED
} from "./../typeActions/auth";
import { loginUser, loginWithJwt, getCurrentUser } from "../services/authServices"

export const onEmailChange = username => {
    return { type: EMAIL_CHANGED, payload: username };
};

export const onPasswordChange = password => {
    return { type: PASSWORD_CHANGED, payload: password };
};

export const changeLng = (lang) => dispatch => {
    return dispatch({
        type: LNG_CHANGED,
        payload: lang
    })
}

export const changeActiveTabDashboard = (tab) => dispatch => {
    return dispatch({
        type: TAB_DASHBOARD_CHANGED,
        payload: tab
    })
  }

export const onLogout = () => {
    return { type: LOGOUT };
};


export const onLoginUser = (username, password, rememberMe) => {
    return dispatch => {
        loginUser(username, password).then(User => {
            const { data } = User;
            // if (rememberMe) {
            //     loginWithJwt(data.refresh)
            //     const userDecoded = getCurrentUser(data)
            //     dispatch(successLogin(userDecoded));
            // } else {
            loginWithJwt(data.token)
            const userDecoded = getCurrentUser(data)
            dispatch(successLogin(userDecoded));
            // }
        })
            .catch(err => {
                failedLogin(err, dispatch)
            })
    };
}
export const successLogin = (user) => {
    return {
        type: SUCCESS_LOGIN,
        user
    };
};

const failedLogin = (error, dispatch) => {

    dispatch({
        type: FAILED_LOGIN,
        payload: error.response ? error.response.data : error
    })

};
