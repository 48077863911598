import {
    SUCCESS_GET_SCENARIOS,
    FAILED_GET_SCENARIOS, FAILED_GET_PANELS,
    SUCCESS_GET_PANELS, SUCCESS_GET_COUNT, FAILED_GET_COUNT
} from "../typeActions/scenario"
import { getScenarios, getPanels, getCount } from "../services/scenarioServices"

export const onGetScenarios = () => {
    return dispatch => {
        getScenarios().then(scenarios =>
            dispatch(successGetScenarios(scenarios, dispatch)))
            .catch(err => { failedGetScenarios(err, dispatch) })
    }
}
export const onGetCount = () => {
    return dispatch => {
        getCount().then(count =>
            dispatch(successGetCount(count, dispatch)))
            .catch(err => { failedGetCount(err, dispatch) })
    }
}

const successGetScenarios = (scenarios, dispatch) => {
    dispatch({ type: SUCCESS_GET_SCENARIOS, scenarios });
}

const failedGetScenarios = (error, dispatch) => {
    dispatch({ type: FAILED_GET_SCENARIOS, payload: error });
}
export const onGetPanels = () => {
    return dispatch => {
        getPanels().then(panels =>
            dispatch(successGetPanels(panels, dispatch)))
            .catch(err => { failedGetPanels(err, dispatch) })
    }
}

const successGetPanels = (panels, dispatch) => {
    dispatch({ type: SUCCESS_GET_PANELS, panels });
}

const failedGetPanels = (error, dispatch) => {
    dispatch({ type: FAILED_GET_PANELS, payload: error });
}
const successGetCount = (count, dispatch) => {
    dispatch({ type: SUCCESS_GET_COUNT, count });
}

const failedGetCount = (error, dispatch) => {
    dispatch({ type: FAILED_GET_COUNT, payload: error });
}
