import React from "react";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
//const HorizontalBar = require("react-chartjs-2").HorizontalBar;
import { Bar, HorizontalBar } from "react-chartjs-2";
//import {HorizontalBar} from 'react-chartjs-2';

function BarChart({ labels = [], data = [] }) {
  const dataOptions = {
    labels,
    datasets: [
      {
        label: "Nombre de réponses",
        data,
        backgroundColor: "#17A25C",
        borderColor: "#17A25C",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Question à échelle de notation ",
      },
    },
    indexAxis: "y",
    ticks: {
      beginAtZero: true, // minimum value will be 0.
      // <=> //
      min: 0,
      max: 5,
      stepSize: 1, // 1 - 2 - 3 ...
      major: {
        //           enabled: true
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
        },
      ],
    },
  };
  // options: {
  //   scales: {
  //     x: {
  //       type: 'time',
  //       display: true,
  //       title: {
  //         display: true,
  //         text: 'Date'
  //       },
  //       ticks: {
  //         major: {
  //           enabled: true
  //         },
  //         color: (context) => context.tick && context.tick.major && '#FF0000',
  //         font: function(context) {
  //           if (context.tick && context.tick.major) {
  //             return {
  //               weight: 'bold'
  //             };
  //           }
  //         }
  //       }
  //     },
  //     y: {
  //       display: true,
  //       title: {
  //         display: true,
  //         text: 'value'
  //       }
  //     }
  //   }
  // }

  return <Bar data={dataOptions} options={options} />;
}

export default BarChart;
