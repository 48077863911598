import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LandingAdmin from "./admin/landing";
import LandingClient from "./client/landing";
import LandingTester from "./tester/landing";
import LandingClientTester from "./clientTester/landing";
import "./landingPage.css";

class LandingPage extends Component {
  render() {
    console.log(this.props.auth.user.roles[0], "rrrr");
    const path = window.location.pathname;
    return (
      <React.Fragment>
        {!path.startsWith("/client-tester/questions/") &&
        !path.startsWith("/tester/questions/") ? (<>
          <div className="landing__ctn">
            {this.props.auth.user.roles[0] === "ROLE_ADMIN" && (
              <LandingAdmin
                history={this.props.history}
                location={this.props.location}
              />
            )}
            {this.props.auth.user.roles[0] === "ROLE_CLIENT" && (
              <LandingClient
                history={this.props.history}
                location={this.props.location}
              />
            )}
            {this.props.auth.user.roles[0] === "ROLE_TESTER" && (
              <LandingTester
                history={this.props.history}
                location={this.props.location}
              />
            )}
            
          </div>
          <div className='landing__client_tester'>
          {this.props.auth.user.roles[0] === "ROLE_CLIENT_TESTER" && (
              <LandingClientTester
                history={this.props.history}
                location={this.props.location}
              />
            )}
          </div>
          </>
        ) : (
          <div className="question_dyn">
            {this.props.auth.user.roles[0] === "ROLE_ADMIN" && (
              <LandingAdmin
                history={this.props.history}
                location={this.props.location}
              />
            )}
            {this.props.auth.user.roles[0] === "ROLE_CLIENT" && (
              <LandingClient
                history={this.props.history}
                location={this.props.location}
              />
            )}
            {this.props.auth.user.roles[0] === "ROLE_TESTER" && (
              <LandingTester
                history={this.props.history}
                location={this.props.location}
              />
            )}
            {this.props.auth.user.roles[0] === "ROLE_CLIENT_TESTER" && (
              <LandingClientTester
                history={this.props.history}
                location={this.props.location}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

LandingPage.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingPage);
