import React from 'react';
import Table from "../../common/table"
import { connect } from 'react-redux';
import { onGetTesters } from "../../../actions/userActions"
import{validateTester} from '../../../services/userServices'
import ShowMore from "../../../assets/insightdata_consulter.svg"
import moment from 'moment';
import UserDetails from './userDetails'
import Modals from '../../common/modal'
import { toast } from 'react-toastify';

class TesterValidation extends React.Component {
    state = {
        data: [],
        details: {},
        show: false
    }

    componentDidMount() {
        if (!this.props.user.testers[0])
            this.props.onGetTesters()
        else {
            let testers = []
            this.props.user.testers.map(tester => {
                testers = [...testers, {
                    createdAt: moment(tester.createdAt).format('L'),
                    lastname: tester.lastname,
                    name: tester.name,
                    email: tester.email,
                    state: tester.state,
                    color: (moment(new Date()).format('L') > moment(tester.createdAt).format('L') + "02") || (moment(new Date()).isAfter(tester.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: testers })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.user.testers !== nextProps.user.testers) {
            let testers = []
            nextProps.user.testers.map(tester => {
                testers = [...testers, {
                    createdAt: moment(tester.createdAt).format('L'),
                    lastname: tester.lastname,
                    id: tester.id,
                    name: tester.name,
                    email: tester.email,
                    state: tester.state,
                    color: (moment(new Date()).format('L') > moment(tester.createdAt).format('L') + "02") || (moment(new Date()).isAfter(tester.createdAt, 'year')) ? "red" : ""
                }]
            })
            this.setState({ data: testers })

        }
    }
    showDetails = (data) => {
        this.setState({ show: !this.state.show, details: !this.state.show && data })
    }
    validate = () => {
        validateTester(this.state.details.id).then(res => {
            console.log(res);
            this.props.onGetTesters()
            toast.success('Testeur validé avec succés')
        })
    }
    renderTesterDetails() {

        return (
            <Modals
                modalSize="modal-xl"
                show={this.state.show}
                toggleShow={this.showDetails}
                header='Détail Testeur'
            >
                <UserDetails
                    data={this.state.details}
                    role="ROLE_TESTER"
                    validate={this.validate}
                />

            </Modals>
        )
    }
    renderTable = () => {
        const columns = [
            { title: "Date", field: 'createdAt' },
            { title: "Nom", field: 'lastname' },
            { title: "Prémon", field: 'name' },
            { title: "Mail", field: 'email' },
            { title: "Etat", field: 'state', render: rowData => <span style={{ color: `${rowData.color}` }}>{rowData.state === "to_contact" ? "A contacter" : "Ok"}</span> },
        ]
        const actions = [{
            icon: () => <img style={{ width: "30px" }} src={ShowMore} alt="" />,
            tooltip: 'Détails',
            onClick: (event, rowData) => this.showDetails(rowData),
        },

        ]

        return (
            <Table
                title='Liste des testeurs'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (<div >
            {
                this.renderTable()}
            {this.renderTesterDetails()}
        </div>);
    }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

export default connect(mapStateToProps, { onGetTesters })(TesterValidation);
